import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { mapKeys, map } from "lodash";

export const getTeacherAvailability = createAsyncThunk(
  "GET_TEACHER_AVAILABILITY",
  async ({ userId, teacherId }) => {
    try {
      let { data } = await axios.get(
        `/api/users/${userId}/students/teachers/${teacherId}/availablility`
      );
      data = mapKeys(data, "id");
      const ids = map(data, "id");
      data = { list: data, ids };
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getAvailabilityTimeslot = createAsyncThunk(
  "AVAILABILITY_TIME_SLOT",
  async ({ userId, teacherId, startTime }) => {
    try {
      let { data } = await axios.post(
        `/api/users/${userId}/students/teachers/${teacherId}/timeSlots`,
        { startTime }
      );
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

const initialState = {
  teacherAvailability: {
    loading: false,
    error: null,
    teacherAvailabilityData: null,
  },
  availabilityTimeslot: {
    loading: false,
    error: null,
    availabilityTimeslotData: null,
  },
};

export const teacherAvailabilitySlice = createSlice({
  name: "teacherAvailabilitySlice",
  initialState,
  reducers: {
    clearTeacherAvailability: (state) => {
      state.availabilityTimeslot.availabilityTimeslotData = null;
    },
  },
  extraReducers: {
    [getTeacherAvailability.pending]: (state) => {
      state.teacherAvailability.loading = true;
      state.teacherAvailability.error = null;
    },
    [getTeacherAvailability.fulfilled]: (state, { payload }) => {
      state.teacherAvailability.teacherAvailabilityData = payload;
      state.teacherAvailability.loading = false;
      state.teacherAvailability.error = null;
    },
    [getTeacherAvailability.rejected]: (state, { error }) => {
      state.teacherAvailability.loading = false;
      state.teacherAvailability.error = error.message;
    },
    [getAvailabilityTimeslot.pending]: (state) => {
      state.availabilityTimeslot.loading = true;
      state.availabilityTimeslot.error = null;
    },
    [getAvailabilityTimeslot.fulfilled]: (state, { payload }) => {
      state.availabilityTimeslot.availabilityTimeslotData = payload;
      state.availabilityTimeslot.loading = false;
      state.availabilityTimeslot.error = null;
    },
    [getAvailabilityTimeslot.rejected]: (state, { error }) => {
      state.availabilityTimeslot.loading = false;
      state.availabilityTimeslot.error = error.message;
    },
  },
});

export const { clearTeacherAvailability } = teacherAvailabilitySlice.actions;

export const selectAvailability = (state) => state.teacherAvailability;

export default teacherAvailabilitySlice.reducer;
