import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getNotificationsApi,
  updateNotificationsApi,
  updateAllNotificationsApi,
} from "../../services/api/apiServices/notifications";

export const getNotifications = createAsyncThunk(
  "GET_NOTIFICATIONS",
  async ({ userId, sweetAlert, page, limit }) => {
    try {
      const { data } = await getNotificationsApi({ userId, page, limit });
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const updateAllNotifications = createAsyncThunk(
  "UPDATE_ALL_NOTIFICATIONS",
  async (userId, { dispatch }) => {
    try {
      const { data } = await updateAllNotificationsApi(userId);
      dispatch(getNotifications({ userId }));
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const updateNotification = createAsyncThunk(
  "UPDATE_NOTIFICATION",
  async ({ userId, id }, { dispatch }) => {
    try {
      const { data } = await updateNotificationsApi({ userId, id });
      dispatch(getNotifications({ userId }));
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  notifications: [],
  notificationsCount: null,
  totalNotificationsCount: null,
};

export const notificationsSlice = createSlice({
  name: "NOTIFICATIONS",
  initialState,
  reducers: {},
  extraReducers: {
    [getNotifications.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.notifications = payload.notifications;
      state.notificationsCount = payload.notificationsCount;
      state.totalNotificationsCount = payload.totalNotificationsCount;
      state.loading = false;
      state.error = null;
    },
    [getNotifications.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const {} = notificationsSlice.actions;

export const selectNotifications = (state) => state.notifications;

export default notificationsSlice.reducer;
