import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPostsApi,
  createPostsApi,
  getSinglePostApi,
  getPublicPostsApi,
  getSinglePublicPostApi,
  insertReportPostApi,
} from "../../services/api/apiServices/posts";

export const getPosts = createAsyncThunk(
  "GET_POSTS",
  async ({ userId, clubId, navigate, sweetAlert }) => {
    try {
      const { data } = await getPostsApi({ userId, clubId });
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong while getting posts",
        icon: "error",
        timer: 3000,
      });
      setTimeout(() => {
        navigate(-1);
      }, 3000);
      throw error?.response?.data;
    }
  }
);

export const getSinglePost = createAsyncThunk(
  "GET_POST_POST",
  async ({ userId, postId }) => {
    try {
      const { data } = await getSinglePostApi({ userId, postId });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const createPost = createAsyncThunk(
  "CREATE_POSTS",
  async ({ userId, clubId, desc, sweetAlert }, { dispatch }) => {
    try {
      const { data } = await createPostsApi({
        userId,
        clubId,
        body: { desc },
      });
      sweetAlert({
        title: "Post Created!",
      });
      dispatch(getPosts({ userId, clubId, sweetAlert }));
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong while creating post!",
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const getPublicPosts = createAsyncThunk(
  "GET_PUBLIC_POSTS",
  async ({ clubId, navigate, sweetAlert }) => {
    try {
      const { data } = await getPublicPostsApi(clubId);
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong while getting groups",
        icon: "error",
      });
      setTimeout(() => {
        navigate(-1);
      }, 3000);
      throw error?.response?.data;
    }
  }
);

export const getSinglePublicPost = createAsyncThunk(
  "GET_SINGLE_PUBLIC_POST",
  async ({ clubId, postId }) => {
    try {
      const { data } = await getSinglePublicPostApi({ clubId, postId });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const insertReport = createAsyncThunk(
  "INSERT_REPORT",
  async ({ userId, clubId, postId, sweetAlert, setShowReportModal }) => {
    try {
      const { data } = await insertReportPostApi({ userId, clubId, postId });
      sweetAlert({
        title: "Post reported!",
      });
      setShowReportModal(false);
      return data;
    } catch (error) {
      setShowReportModal(false);
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  posts: null,
  post: null,
  createPost: {
    loading: false,
    error: null,
    successMessage: null,
  },
  reportPost: {
    loading: false,
    error: null,
    response: null,
  },
};

export const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    clearSuccessMessage: (state) => {
      state.createPost.successMessage = null;
    },
    clearReportError: (state) => {
      state.reportPost.error = null;
    },
  },
  extraReducers: {
    [getPosts.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPosts.fulfilled]: (state, { payload }) => {
      state.posts = payload;
      state.loading = false;
      state.error = null;
    },
    [getPosts.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // get public posts
    [getPublicPosts.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPublicPosts.fulfilled]: (state, { payload }) => {
      state.posts = payload;
      state.loading = false;
      state.error = null;
    },
    [getPublicPosts.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //get single post
    [getSinglePost.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSinglePost.fulfilled]: (state, { payload }) => {
      state.post = payload;
      state.loading = false;
      state.error = null;
    },
    [getSinglePost.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //get single post
    [getSinglePublicPost.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSinglePublicPost.fulfilled]: (state, { payload }) => {
      state.post = payload;
      state.loading = false;
      state.error = null;
    },
    [getSinglePublicPost.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //createPosts
    [createPost.pending]: (state) => {
      state.createPost.loading = true;
      state.createPost.error = null;
    },
    [createPost.fulfilled]: (state, { payload }) => {
      state.createPost.successMessage = payload;
      state.createPost.loading = false;
      state.createPost.error = null;
    },
    [createPost.rejected]: (state, { error }) => {
      state.createPost.loading = false;
      state.createPost.error = error.message;
    },
    //insert report
    [insertReport.pending]: (state) => {
      state.reportPost.loading = true;
      state.reportPost.error = null;
    },
    [insertReport.fulfilled]: (state, { payload }) => {
      state.reportPost.response = payload;
      state.reportPost.loading = false;
      state.reportPost.error = null;
    },
    [insertReport.rejected]: (state, { error }) => {
      state.reportPost.loading = false;
      state.reportPost.error = error.message;
    },
  },
});

export const { clearSuccessMessage, clearReportError } = postsSlice.actions;

export const selectPosts = (state) => state.posts;
export const selectCreatePost = (state) => state.posts.createPost;
export const selectReport = (state) => state.posts.reportPost;

export default postsSlice.reducer;
