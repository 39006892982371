import React, { Suspense, lazy } from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";

//loader
import FullScreenLoader from "../Components/FullScreenLoader";

//Redux-toolkit
import { useSelector } from "react-redux";
import { selectTeacherStatus } from "../Store/Features/userSlice";

//Constants
import { STATUS } from "../constants/Constants";

//components
const TeachersDashboard = lazy(() => import("../pages/TeachersDashboard"));
const LessonManagementList = lazy(() =>
  import("../pages/lessonManagement/LessonManagmentList")
);
const TeacherAvailability = lazy(() => import("../pages/TeacherAvailability"));
const TeachersLessons = lazy(() => import("../pages/TeachersLessons"));
const CancelledLessons = lazy(() =>
  import("../pages/TeachersLessons/CancelledLessons")
);
const NeedActionLesson = lazy(() =>
  import("../pages/TeachersLessons/NeedActionLesson")
);
const CompletedLesson = lazy(() =>
  import("../pages/TeachersLessons/CompletedLesson")
);
const UpcommingLesson = lazy(() =>
  import("../pages/TeachersLessons/UpcommingLesson")
);
const WaitingLesson = lazy(() =>
  import("../pages/TeachersLessons/WaitingLesson")
);
const Settings = lazy(() => import("../pages/Settings/TeacherSettings"));
const Disputedetail = lazy(() =>
  import("../pages/disputeCenter/Disputedetail")
);
const Wallet = lazy(() => import("../pages/Wallet"));
const TutorialVideos = lazy(() => import("../pages/TutorialVideos"));
const InstantLessonDetails = lazy(() =>
  import("../pages/TeachersLessons/InstantLessonDetails")
);
const Error = lazy(() => import("../pages/Error"));

export default function Main() {
  const teacherStatus = useSelector(selectTeacherStatus);

  const TeacherStatusRoutes = () => {
    return teacherStatus === STATUS.accepted ? (
      <Outlet />
    ) : (
      <Navigate to={"dashboard"} />
    );
  };

  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        <Route path="dashboard" element={<TeachersDashboard />} />
        <Route path="*" element={<Error />} />
        <Route element={<TeacherStatusRoutes />}>
          <Route path="settings" element={<Settings />} />
          <Route path="lessonsmanagement" element={<LessonManagementList />} />
          <Route path="lessons">
            <Route path=":filter" element={<TeachersLessons />} />
            <Route path="" element={<TeachersLessons />} />
          </Route>
          <Route path="/availability" element={<TeacherAvailability />} />
          <Route
            path="/cancelledlessons/:lessonId"
            element={<CancelledLessons />}
          />
          <Route
            path="/needactionlesson/:lessonId"
            element={<NeedActionLesson />}
          />
          <Route
            path="/completedlesson/:lessonId"
            element={<CompletedLesson />}
          />
          <Route
            path="/upcomminglesson/:lessonId"
            element={<UpcommingLesson />}
          />
          <Route path="/waitinglesson/:lessonId" element={<WaitingLesson />} />
          <Route
            path="/instantLesson/:lessonId"
            element={<InstantLessonDetails />}
          />
          <Route
            path="disputeReceived/:disputeId"
            element={<Disputedetail />}
          />
          <Route path="wallet" element={<Wallet />} />
          <Route path="tutorialvideos" element={<TutorialVideos />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
