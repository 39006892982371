import axios from "axios";

export const getDisputesApi = ({ userId, disputeId }) => {
  return axios.get(`/api/users/${userId}/insertdispute/${disputeId}`);
};

export const startDisputesApi = ({ formData, userId }) => {
  return axios.post(`/api/users/${userId}/insertdispute`, formData);
};

export const updateDisputeApi = ({ formData, disputeId, userId }) => {
  return axios.put(`/api/users/${userId}/insertdispute/${disputeId}`, formData);
};
