import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getStudentClassesApi,
  getStudentClassApi,
  completeStatusClassApi,
  getPendingClassApi,
  getCurrentClassApi,
  getStudentImportantUpdatesApi,
} from "../../services/api/apiServices/classes";
import { mapKeys, map } from "lodash";

// GET ALL LESSONS
export const getLessons = createAsyncThunk(
  "GET_LESSONS",
  async ({ userId, status, upcomming, page = 1, limit = 100 }) => {
    try {
      let { data } = await getStudentClassesApi({
        userId,
        status,
        upcomming,
        page,
        limit,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getAllLessons = createAsyncThunk(
  "GET_ALL_LESSONS",
  async ({ userId, page = 1, limit = 50, sweetAlert }) => {
    try {
      let { data } = await getStudentClassesApi({
        userId,
        page,
        limit,
      });
      data = mapKeys(data, "id");
      const ids = map(data, "id");
      data = { list: data, ids };
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

// GET ONE LESSON
export const getOneLesson = createAsyncThunk(
  "GET_ONE_LESSON",
  async ({ userId, classId, sweetAlert, navigate }) => {
    try {
      let { data } = await getStudentClassApi({ userId, classId });
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      navigate(-1);
      throw error?.response?.data;
    }
  }
);

// MARK STATUS AS COMPLETE
export const completeStatus = createAsyncThunk(
  "UPDATE_LESSON_COMPLETE",
  async ({ userId, classId, sweetAlert, navigate }) => {
    try {
      let { data } = await completeStatusClassApi({ userId, classId });
      navigate(`/user/${userId}/student/${classId}/completedLessons`);
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

// GET PENDING LESSONS
export const getPendingLessons = createAsyncThunk(
  "GET_PENDING_LESSONS",
  async ({ userId, page, limit, sweetAlert }) => {
    try {
      let { data } = await getPendingClassApi({ userId, page, limit });
      data = mapKeys(data, "id");
      const ids = map(data, "id");
      data = { list: data, ids };
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

// GET CURRENT LESSONS
export const getCurrentLessons = createAsyncThunk(
  "GET_CURRENT_LESSONS",
  async ({ userId, page, limit, sweetAlert }) => {
    try {
      let { data } = await getCurrentClassApi({ userId, page, limit });
      data = mapKeys(data, "id");
      const ids = map(data, "id");
      data = { list: data, ids };
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

// GET STUDENTS IMPORTANT UPDATES
export const getStudentImportantUpdates = createAsyncThunk(
  "GET_STUDENTS_IMPORTANT_UPDATES",
  async ({ userId, limit, sweetAlert }) => {
    try {
      const { data } = await getStudentImportantUpdatesApi({ userId, limit });
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  lessons: [],
  oneLesson: null,
  allLessons: null,
  updateLesson: {
    loading: false,
    error: null,
    lesson: null,
  },
  pendingLesson: {
    loading: false,
    error: null,
    lesson: null,
  },
  currentLesson: {
    loading: false,
    error: null,
    lesson: null,
  },
  importantUpdates: {
    loading: false,
    error: null,
    updates: null,
  },
};

export const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {
    clearLessons: (state) => {
      state.error = null;
      state.oneLesson = null;
    },
    clearUpdatedLessons: (state) => {
      state.updateLesson.lesson = null;
    },
    clearAllLessons: (state) => {
      state.lessons = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: {
    //All Lessons
    [getAllLessons.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllLessons.fulfilled]: (state, { payload }) => {
      state.allLessons = payload;
      state.loading = false;
      state.error = null;
    },
    [getAllLessons.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    [getLessons.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getLessons.fulfilled]: (state, { payload }) => {
      state.lessons = payload;
      state.loading = false;
      state.error = null;
    },
    [getLessons.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [getOneLesson.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOneLesson.fulfilled]: (state, { payload }) => {
      state.oneLesson = payload;
      state.loading = false;
      state.error = null;
    },
    [getOneLesson.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    //on complete
    [completeStatus.pending]: (state) => {
      state.updateLesson.loading = true;
      state.updateLesson.error = null;
    },
    [completeStatus.fulfilled]: (state, { payload }) => {
      state.updateLesson.lesson = payload;
      state.updateLesson.loading = false;
      state.updateLesson.error = null;
    },
    [completeStatus.rejected]: (state, { error }) => {
      state.updateLesson.loading = false;
      state.updateLesson.error = error.message;
    },

    //pending lessons
    [getPendingLessons.pending]: (state) => {
      state.pendingLesson.loading = true;
      state.pendingLesson.error = null;
    },
    [getPendingLessons.fulfilled]: (state, { payload }) => {
      state.pendingLesson.lesson = payload;
      state.pendingLesson.loading = false;
      state.pendingLesson.error = null;
    },
    [getPendingLessons.rejected]: (state, { error }) => {
      state.pendingLesson.loading = false;
      state.pendingLesson.error = error.message;
    },

    //current lessons
    [getCurrentLessons.pending]: (state) => {
      state.currentLesson.loading = true;
      state.currentLesson.error = null;
    },
    [getCurrentLessons.fulfilled]: (state, { payload }) => {
      state.currentLesson.lesson = payload;
      state.currentLesson.loading = false;
      state.currentLesson.error = null;
    },
    [getCurrentLessons.rejected]: (state, { error }) => {
      state.currentLesson.loading = false;
      state.currentLesson.error = error.message;
    },
    //important updates
    [getStudentImportantUpdates.pending]: (state) => {
      state.importantUpdates.loading = true;
      state.importantUpdates.error = null;
    },
    [getStudentImportantUpdates.fulfilled]: (state, { payload }) => {
      state.importantUpdates.updates = payload;
      state.importantUpdates.loading = false;
      state.importantUpdates.error = null;
    },
    [getStudentImportantUpdates.rejected]: (state, { error }) => {
      state.importantUpdates.loading = false;
      state.importantUpdates.error = error.message;
    },
  },
});

export const { clearLessons, clearUpdatedLessons, clearAllLessons } =
  lessonsSlice.actions;

export const selectLessons = (state) => state.lessons;
export const pendingLessons = (state) => state.lessons.pendingLesson;
export const currentLessons = (state) => state.lessons.currentLesson;
export const importantUpdates = (state) => state.lessons.importantUpdates;

export default lessonsSlice.reducer;
