import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPackagesApi,
  getBookedPackageApi,
  getStudentBookedPackageApi,
} from "../../services/api/apiServices/packages";

export const getPackages = createAsyncThunk(
  "GET_PACKAGES",
  async ({ userId, lessonId }) => {
    try {
      let { data } = await getPackagesApi({ userId, lessonId });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getBookedPackage = createAsyncThunk(
  "GET_BOOKED_PACKAGES",
  async ({ userId, lessonId }) => {
    try {
      let { data } = await getBookedPackageApi({ userId, lessonId });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
export const getStudentBookedPackage = createAsyncThunk(
  "GET_STUDENT_PACKAGES",
  async ({ userId, sweetAlert }) => {
    try {
      let { data } = await getStudentBookedPackageApi(userId);
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  packages: null,
};

export const packagesSlice = createSlice({
  name: "classesSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getPackages.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPackages.fulfilled]: (state, { payload }) => {
      state.packages = payload;
      state.loading = false;
      state.error = null;
    },
    [getPackages.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //GET BOOKED PACKAGE
    [getBookedPackage.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBookedPackage.fulfilled]: (state, { payload }) => {
      state.packages = payload;
      state.loading = false;
      state.error = null;
    },
    [getBookedPackage.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //GET STUDENT BOOKED PACKAGE
    [getStudentBookedPackage.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getStudentBookedPackage.fulfilled]: (state, { payload }) => {
      state.packages = payload;
      state.loading = false;
      state.error = null;
    },
    [getStudentBookedPackage.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const {} = packagesSlice.actions;

export const selectPackages = (state) => state.packages;
export default packagesSlice.reducer;
