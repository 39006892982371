import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTeachersSubjectsApi } from "../../services/api/apiServices/subjects";
import { mapKeys, map } from "lodash";

export const getSubjects = createAsyncThunk(
  "GET_SUBJECTS",
  async ({ userId, teacherId }) => {
    try {
      let { data } = await getTeachersSubjectsApi({ userId, teacherId });
      data = mapKeys(data, "id");
      const ids = map(data, "id");
      data = { list: data, ids };

      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  subjects: {
    list: null,
    ids: null,
  },
};

export const subjectsSlice = createSlice({
  name: "subjectSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getSubjects.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSubjects.fulfilled]: (state, { payload }) => {
      state.subjects = payload;
      state.loading = false;
      state.error = null;
    },
    [getSubjects.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const selectSubjects = (state) => state.subjects;

export default subjectsSlice.reducer;
