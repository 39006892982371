import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTeacherClassesApi,
  getTeacheOneClassApi,
  updateClassesApi,
  addTeacherlessonApi,
  getTeacherUpdatesApi,
  updateTeacherLessonApi,
  deleteTeacherLessonApi,
  updateLessonStatusApi,
  classesWithoutReviewApi,
  getTeachersInstantClassesApi,
  getTeachersOneInstantClassApi,
  updateTeachersOneInstantClassApi,
} from "../../services/api/apiServices/classes";
import { mapKeys, map } from "lodash";
import axios from "axios";

export const addTeacherLesson = createAsyncThunk(
  "ADD_TEACHER_LESSONS",
  async ({ userId, lessonInfo, teacherId, sweetAlert }) => {
    try {
      let { data } = await addTeacherlessonApi({
        userId,
        teacherId,
        lessonInfo,
      });
      sweetAlert({
        title: "Lesson Added Successfully.",
      });
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const getTeacherLessons = createAsyncThunk(
  "GET_TEACHER_LESSONS",
  async ({ userId, teacherId, page = 1, limit = 100, status, upcomming }) => {
    try {
      let { data } = await getTeacherClassesApi({
        userId,
        teacherId,
        page,
        limit,
        status,
        upcomming,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getTeacherLesson = createAsyncThunk(
  "GET_TEACHER_LESSON",
  async ({ userId, teacherId, lessonId, sweetAlert, navigate }) => {
    try {
      let { data } = await getTeacheOneClassApi({
        userId,
        teacherId,
        lessonId,
      });
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      navigate(-1);
      throw error?.response?.data;
    }
  }
);

export const updateClasses = createAsyncThunk(
  "UPDATE_LESSON",
  async ({
    userId,
    teacherId,
    lessonId,
    action,
    completed,
    navigate,
    sweetAlert,
  }) => {
    try {
      const { data } = await updateClassesApi({
        userId,
        teacherId,
        lessonId,
        action,
        completed,
      });
      navigate(-1);
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const getActionsLessons = createAsyncThunk(
  "GET_PENDING_LESSONS",
  async ({ userId, teacherId, page = 1, limit = 10 }) => {
    try {
      let { data } = await axios.get(
        `/api/users/${userId}/teachers/${teacherId}/lessons?status=Pending&page=${page}&limit=${limit}&action=${true}`
      );
      data = mapKeys(data, "id");
      const ids = map(data, "id");
      data = { list: data, ids };
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getTeacherFeedback = createAsyncThunk(
  "GET_TEACHER_FEEDBACK",
  async ({ userId, teacherId }) => {
    try {
      let { data } = await axios.get(
        `/api/users/${userId}/teachers/${teacherId}/feedback`
      );
      data = mapKeys(data, "id");
      const ids = map(data, "id");
      data = { list: data, ids };
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getTeacherUpdates = createAsyncThunk(
  "GET_TEACHER_UPDATES",
  async ({ userId, teacherId, page = 1, limit = 3 }) => {
    try {
      let { data } = await getTeacherUpdatesApi({
        userId,
        teacherId,
        page,
        limit,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const updateTeacherLesson = createAsyncThunk(
  "UPDATE_TEACHER_LESSON",
  async ({
    userId,
    teacherId,
    lessonId,
    formData,
    sweetAlert,
    handleSubmitForm,
  }) => {
    try {
      let { data } = await updateTeacherLessonApi({
        userId,
        teacherId,
        lessonId,
        formData,
      });
      sweetAlert({
        title: "Lesson Updated!",
      });
      handleSubmitForm();
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const deleteTeacherLesson = createAsyncThunk(
  "DELETE_TEACHER_LESSON",
  async ({ userId, teacherId, lessonId, sweetAlert, handleSubmitForm }) => {
    try {
      let { data } = await deleteTeacherLessonApi({
        userId,
        teacherId,
        lessonId,
      });
      sweetAlert({
        title: "Lesson Deleted!",
        icon: "success",
      });
      handleSubmitForm();
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const updateLessonStatus = createAsyncThunk(
  "UPDATE_LESSON_STATUS",
  async ({
    userId,
    teacherId,
    lessonId,
    status,
    sweetAlert,
    handleSubmitForm,
  }) => {
    try {
      let { data } = await updateLessonStatusApi({
        userId,
        teacherId,
        lessonId,
        status,
      });
      sweetAlert({
        title: "Lesson Status Changed!",
      });
      handleSubmitForm();
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const classesWithoutReview = createAsyncThunk(
  "CLASSES_WITHOUT_REVIEWS",
  async ({ userId }) => {
    try {
      let { data } = await classesWithoutReviewApi(userId);
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getTeachersInstantLessons = createAsyncThunk(
  "GET_TEACHER_INSTANT_LESSONS",
  async ({ userId, teacherId, sweetAlert }) => {
    try {
      let { data } = await getTeachersInstantClassesApi({ userId, teacherId });
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong!",
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const getTeachersOneInstantLesson = createAsyncThunk(
  "GET_TEACHER_ONE_INSTANT_LESSON",
  async ({ userId, teacherId, lessonId, sweetAlert, navigate }) => {
    try {
      let { data } = await getTeachersOneInstantClassApi({
        userId,
        teacherId,
        lessonId,
      });
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong!",
        icon: "error",
      });
      navigate(-1);
      throw error?.response?.data;
    }
  }
);

export const updateTeachersOneInstantLesson = createAsyncThunk(
  "UPDATE_TEACHER_ONE_INSTANT_LESSON",
  async ({ userId, teacherId, lessonId, action, sweetAlert, navigate }) => {
    try {
      let { data } = await updateTeachersOneInstantClassApi({
        userId,
        teacherId,
        lessonId,
        action,
      });
      sweetAlert({
        title: `Lesson ${action} successfully!`,
      });
      navigate(-1);
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message || "Something went wrong!",
        icon: "error",
      });
      navigate(-1);
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  lessons: [],
  teacherLesson: null,
  updatedLesson: {
    loading: false,
    error: null,
    sucesssMessage: null,
  },
  lessonAdded: {
    loading: false,
    error: null,
    sucesssMessage: null,
  },
  pendingLesson: {
    loading: false,
    error: null,
    lesson: null,
  },
  teacherFeedback: {
    loading: false,
    error: null,
    feedbacks: {
      list: null,
      ids: null,
    },
  },
  teacherLessonUpdates: {
    loading: false,
    error: null,
    updates: null,
  },
  updateTeacherLesson: {
    loading: false,
    error: null,
    response: null,
  },
  classesWithoutReviews: {
    loading: false,
    error: null,
    response: [],
  },
  teacherInstantLessons: {
    loading: false,
    error: null,
    instantLessons: [],
  },
  teacherOneInstantLesson: {
    loading: false,
    error: null,
    response: null,
  },
  teacherOneInstantLessonLoader: false,
};

export const TeacherLessonsSlice = createSlice({
  name: "teacherLessons",
  initialState,
  reducers: {
    clearSuccessMessage: (state) => {
      state.updatedLesson.sucesssMessage = null;
    },
    clearSuccessMessageLessons: (state) => {
      state.lessonAdded.sucesssMessage = null;
    },
    clearTeacherLesson: (state) => {
      state.teacherLesson = null;
    },
    clearAllLessons: (state) => {
      state.loading = false;
      state.error = null;
      state.lessons = [];
    },
  },
  extraReducers: {
    [getTeacherLessons.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTeacherLessons.fulfilled]: (state, { payload }) => {
      state.lessons = payload;
      state.loading = false;
      state.error = null;
    },
    [getTeacherLessons.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [getTeacherLesson.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTeacherLesson.fulfilled]: (state, { payload }) => {
      state.teacherLesson = payload;
      state.loading = false;
      state.error = null;
    },
    [getTeacherLesson.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //update lessons
    [updateClasses.pending]: (state) => {
      state.updatedLesson.loading = true;
      state.updatedLesson.error = null;
    },
    [updateClasses.fulfilled]: (state) => {
      state.updatedLesson.sucesssMessage = "Lesson updated Successfully";
      state.updatedLesson.loading = false;
      state.updatedLesson.error = null;
    },
    [updateClasses.rejected]: (state, { error }) => {
      state.updatedLesson.loading = false;
      state.updatedLesson.error = error.message;
    },
    //pending lessons
    [getActionsLessons.pending]: (state) => {
      state.pendingLesson.loading = true;
      state.pendingLesson.error = null;
    },
    [getActionsLessons.fulfilled]: (state, { payload }) => {
      state.pendingLesson.lesson = payload;
      state.pendingLesson.loading = false;
      state.pendingLesson.error = null;
    },
    [getActionsLessons.rejected]: (state, { error }) => {
      state.pendingLesson.loading = false;
      state.pendingLesson.error = error.message;
    },

    //lesson added
    [addTeacherLesson.pending]: (state) => {
      state.lessonAdded.loading = true;
      state.lessonAdded.error = null;
    },
    [addTeacherLesson.fulfilled]: (state, { payload }) => {
      state.lessonAdded.sucesssMessage = payload;
      state.lessonAdded.loading = false;
      state.lessonAdded.error = null;
    },
    [addTeacherLesson.rejected]: (state, { error }) => {
      state.lessonAdded.loading = false;
      state.lessonAdded.error = error.message;
    },

    //feedbacks
    [getTeacherFeedback.pending]: (state) => {
      state.teacherFeedback.loading = true;
      state.teacherFeedback.error = null;
    },
    [getTeacherFeedback.fulfilled]: (state, { payload }) => {
      state.teacherFeedback.feedbacks = payload;
      state.teacherFeedback.loading = false;
      state.teacherFeedback.error = null;
    },
    [getTeacherFeedback.rejected]: (state, { error }) => {
      state.teacherFeedback.loading = false;
      state.teacherFeedback.error = error.message;
    },
    //important updates
    [getTeacherUpdates.pending]: (state) => {
      state.teacherLessonUpdates.loading = true;
      state.teacherLessonUpdates.error = null;
    },
    [getTeacherUpdates.fulfilled]: (state, { payload }) => {
      state.teacherLessonUpdates.updates = payload;
      state.teacherLessonUpdates.loading = false;
      state.teacherLessonUpdates.error = null;
    },
    [getTeacherUpdates.rejected]: (state, { error }) => {
      state.teacherLessonUpdates.loading = false;
      state.teacherLessonUpdates.error = error.message;
    },
    // update teacher lessons
    [updateTeacherLesson.pending]: (state) => {
      state.updateTeacherLesson.loading = true;
      state.updateTeacherLesson.error = null;
    },
    [updateTeacherLesson.fulfilled]: (state, { payload }) => {
      state.updateTeacherLesson.response = payload;
      state.updateTeacherLesson.loading = false;
      state.updateTeacherLesson.error = null;
    },
    [updateTeacherLesson.rejected]: (state, { error }) => {
      state.updateTeacherLesson.loading = false;
      state.updateTeacherLesson.error = error.message;
    },
    // classes without reviews
    [classesWithoutReview.pending]: (state) => {
      state.classesWithoutReviews.loading = true;
      state.classesWithoutReviews.error = null;
    },
    [classesWithoutReview.fulfilled]: (state, { payload }) => {
      state.classesWithoutReviews.response = payload;
      state.classesWithoutReviews.loading = false;
      state.classesWithoutReviews.error = null;
    },
    [classesWithoutReview.rejected]: (state, { error }) => {
      state.classesWithoutReviews.loading = false;
      state.classesWithoutReviews.error = error.message;
    },
    // teacher instant lessons
    [getTeachersInstantLessons.pending]: (state) => {
      state.teacherInstantLessons.loading = true;
      state.teacherInstantLessons.error = null;
    },
    [getTeachersInstantLessons.fulfilled]: (state, { payload }) => {
      state.teacherInstantLessons.instantLessons = payload;
      state.teacherInstantLessons.loading = false;
      state.teacherInstantLessons.error = null;
    },
    [getTeachersInstantLessons.rejected]: (state, { error }) => {
      state.teacherInstantLessons.loading = false;
      state.teacherInstantLessons.error = error.message;
    },
    // teacher one instant lessons
    [getTeachersOneInstantLesson.pending]: (state) => {
      state.teacherOneInstantLesson.loading = true;
      state.teacherOneInstantLesson.error = null;
    },
    [getTeachersOneInstantLesson.fulfilled]: (state, { payload }) => {
      state.teacherOneInstantLesson.response = payload;
      state.teacherOneInstantLesson.loading = false;
      state.teacherOneInstantLesson.error = null;
    },
    [getTeachersOneInstantLesson.rejected]: (state, { error }) => {
      state.teacherOneInstantLesson.loading = false;
      state.teacherOneInstantLesson.error = error.message;
    },
    // teacher update one instant lessons
    [updateTeachersOneInstantLesson.pending]: (state) => {
      state.teacherOneInstantLessonLoader = true;
    },
    [updateTeachersOneInstantLesson.fulfilled]: (state) => {
      state.teacherOneInstantLessonLoader = false;
    },
    [updateTeachersOneInstantLesson.rejected]: (state) => {
      state.teacherOneInstantLessonLoader = false;
    },
  },
});

export const {
  clearSuccessMessage,
  clearSuccessMessageLessons,
  clearTeacherLesson,
  clearAllLessons,
} = TeacherLessonsSlice.actions;

export const selectLesson = (state) => state.teacherLessons;
export const pendingLessons = (state) => state.teacherLessons.pendingLesson;
export const feedbacks = (state) => state.teacherLessons.teacherFeedback;
export const addedLesson = (state) => state.teacherLessons.lessonAdded;
export const LessonsUpdate = (state) =>
  state.teacherLessons.teacherLessonUpdates;
export const selectUpdateLesson = (state) =>
  state.teacherLessons.updateTeacherLesson;
export const selectClassesWithoutReview = (state) =>
  state.teacherLessons.classesWithoutReviews;
export const selectTeacherInstantLessons = (state) =>
  state.teacherLessons.teacherInstantLessons;
export const selectTeacherOneInstantLesson = (state) =>
  state.teacherLessons.teacherOneInstantLesson;
export const selectTeacherOneLessonLoader = (state) =>
  state.teacherLessons.teacherOneInstantLessonLoader;

export default TeacherLessonsSlice.reducer;
