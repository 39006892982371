import React, { Suspense, lazy } from "react";

//React-router-dom
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";

//Navbar
import Navbar from "../Components/Navbar";

//Navigators
import StudentNavigator from "./studentNavigator";
import TeacherNavigator from "./teacherNavigator";

//loader
import FullScreenLoader from "../Components/FullScreenLoader";

//Redux-toolkit
import { useSelector } from "react-redux";
import {
  selectActiveRole,
  selectUser,
  selectUserId,
} from "../Store/Features/userSlice";

//Constants
import { USER_ROLES } from "../constants/Constants";
import { isNull } from "lodash";

//Components
const Home = lazy(() => import("../pages/Home"));
const SocialLoginCallBack = lazy(() => import("../pages/SocialCallBack"));
const Signup = lazy(() => import("../pages/Signup"));
const SignupOnboarding = lazy(() => import("../pages/Signup/SignupOnboarding"));
const SignupInterestedSubjects = lazy(() =>
  import("../pages/Signup/SignupInterestedSubjects")
);
const SignupProfileImage = lazy(() =>
  import("../pages/Signup/SignupProfileImage")
);
const SignupAccountType = lazy(() =>
  import("../pages/Signup/SignupAccountType")
);
const Login = lazy(() => import("../pages/Login"));
const FindTeacher = lazy(() => import("../pages/FindTeacher"));
const BecomeTeacher = lazy(() => import("../pages/BecomeTeacher"));
const TeacherType = lazy(() => import("../pages/BecomeTeacher/TeacherType"));
const BecomeTeacherForm = lazy(() =>
  import("../pages/BecomeTeacher/BecomeTeacherFrom")
);
const TeachingProfileForm = lazy(() =>
  import("../pages/BecomeTeacher/TeachingProfileForm")
);
const Settings = lazy(() => import("../pages/Settings/index"));
const Messages = lazy(() => import("../pages/Messages"));
const Community = lazy(() => import("../pages/Community"));
const CommunityCardsDetail = lazy(() =>
  import("../pages/Community/CommunityClubDetail")
);
const Posts = lazy(() => import("../pages/Community/Post"));
const Error = lazy(() => import("../pages/Error"));
const AboutUs = lazy(() => import("../pages/footerNavigations/AboutUs"));
const FAQs = lazy(() => import("../pages/faqs"));
const TermsCondition = lazy(() =>
  import("../pages/footerNavigations/TermsCondition")
);

const ResubmitTeacherApplicationStep1 = lazy(() =>
  import("../pages/ResubmitTeacherApplication/FormStep1")
);
const ResubmitTeacherApplicationStep2 = lazy(() =>
  import("../pages/ResubmitTeacherApplication/FormStep2")
);
const ForgetPassword = lazy(() => import("../pages/ForgotPassword"));
const StudentsFAQs = lazy(() => import("../pages/faqs/Students"));
const TeachersFAQs = lazy(() => import("../pages/faqs/Teachers"));
const BecomeTeacherFAQs = lazy(() => import("../pages/faqs/BecomeTeacher"));
const GettingPaidFAQs = lazy(() => import("../pages/faqs/GettingPaid"));
const LessonManagementFAQs = lazy(() =>
  import("../pages/faqs/LessonManagement")
);
const CommunityFAQs = lazy(() => import("../pages/faqs/Community"));
const TeacherProfile = lazy(() => import("../pages/TeacherProfile"));
const Notifications = lazy(() => import("../pages/Notifications"));
const PaypalSuccess = lazy(() => import("../pages/PaypalSuccess"));

const ProtectedRoutes = () => {
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login?msg=0" replace />;
  } else {
    return <Outlet />;
  }
};

const PublicRoutes = () => {
  const userId = useSelector(selectUserId);
  const user = useSelector(selectUser);
  if (localStorage.getItem("token") && !isNull(user.user)) {
    return <Navigate to={`/user/${userId}/student/dashboard`} replace />;
  } else if (localStorage.getItem("token") && isNull(user.user)) {
    return <Navigate to={`/signupOnboarding`} replace />;
  } else {
    return <Outlet />;
  }
};

const Navigation = () => {
  let location = useLocation();
  const activeRole = useSelector(selectActiveRole);

  const StudentProtectedRoutes = () => {
    return activeRole === USER_ROLES.student ? (
      <Outlet />
    ) : (
      <Navigate to={"teacher/dashboard"} />
    );
  };

  const TeacherProtectedRoutes = () => {
    return activeRole === USER_ROLES.teacher ? (
      <Outlet />
    ) : (
      <Navigate to={"student/dashboard"} />
    );
  };

  return (
    <>
      <Navbar />
      <Suspense fallback={<FullScreenLoader />}>
        <Routes location={location} key={location.pathname}>
          {/* Public routes */}
          <Route path="/" element={<Home />} />
          <Route path="/paypalSuccess" element={<PaypalSuccess />} />
          <Route path="/findteachers" element={<FindTeacher />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/faqs/*">
            <Route path="" element={<FAQs />} />
            <Route path="students" element={<StudentsFAQs />} />
            <Route path="teachers" element={<TeachersFAQs />} />
            <Route path="becomeTeacher" element={<BecomeTeacherFAQs />} />
            <Route path="gettingpaid" element={<GettingPaidFAQs />} />
            <Route path="lessonManagement" element={<LessonManagementFAQs />} />
            <Route path="community" element={<CommunityFAQs />} />
          </Route>
          <Route path="/termsCondition" element={<TermsCondition />} />
          <Route path="/becometeacher" element={<BecomeTeacher />} />
          <Route
            path="/teacher/:teacherId/profile"
            element={<TeacherProfile />}
          />

          <Route element={<PublicRoutes />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="socialcallback" element={<SocialLoginCallBack />} />
            <Route path="forgotpassword" element={<ForgetPassword />} />
          </Route>

          {/* protected routes */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/user/:userId">
              <Route element={<StudentProtectedRoutes />}>
                <Route path="student/*" element={<StudentNavigator />} />
              </Route>

              <Route element={<TeacherProtectedRoutes />}>
                <Route path="teacher/*" element={<TeacherNavigator />} />
              </Route>

              <Route path="settings" element={<Settings />} />
              <Route path="messages" element={<Messages />} />
              <Route path="notifications" element={<Notifications />} />
            </Route>

            <Route path="/signupOnboarding" element={<SignupOnboarding />} />
            <Route
              path="/signupInterestedSubjects"
              element={<SignupInterestedSubjects />}
            />
            <Route
              path="/signupProfileImage"
              element={<SignupProfileImage />}
            />
            <Route path="/signupAccountType" element={<SignupAccountType />} />
            <Route path="/teachertype" element={<TeacherType />} />
            <Route path="/becometeacherform" element={<BecomeTeacherForm />} />
            <Route
              path="/teachingprofileform"
              element={<TeachingProfileForm />}
            />

            <Route
              path="/resubmitapplicationstepone/:teacherId"
              element={<ResubmitTeacherApplicationStep1 />}
            />
            <Route
              path="/resubmitapplicationsteptwo/:teacherId"
              element={<ResubmitTeacherApplicationStep2 />}
            />
          </Route>

          <Route path="/community">
            <Route path="" element={<Community />} />
            <Route path="club/:clubId" element={<CommunityCardsDetail />} />
            <Route path="club/:clubId/post/:postId" element={<Posts />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Navigation;
