import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userProfile } from "../../services/api/apiServices/auth";
import { USER_ROLES } from "../../constants/Constants";

export const getUser = createAsyncThunk("GET_USER", async (id) => {
  try {
    const { data } = await userProfile(id);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
});

const initialState = {
  loading: false,
  error: null,
  userDetails: {
    teacher: null,
    user: null,
  },
  activeRole: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateActiveRole: (state, action) => {
      state.activeRole = action.payload;
    },
    updateRole: (state, action) => {
      state.userDetails.user.role = action.payload;
    },
    clearUserDetails: (state) => {
      state = initialState;
    },
  },
  extraReducers: {
    [getUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.userDetails.user = payload.user;
      state.userDetails.teacher = payload.teacher;
      state.loading = false;
      state.error = null;
      state.activeRole = payload.user.role;
    },
    [getUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const {
  addUserDetails,
  clearUserDetails,
  updateActiveRole,
  updateRole,
} = userSlice.actions;

export const selectUser = (state) => state.user.userDetails;
export const selectTeacher = (state) => state.user.userDetails.teacher;
export const userLoading = (state) => state.user.loading;
export const selectRole = (state) =>
  state.user.userDetails.user?.role || USER_ROLES.student;
export const selectActiveRole = (state) => state.user.activeRole;
export const selectTeacherStatus = (state) =>
  state.user?.userDetails?.teacher?.status;
export const selectUserId = (state) => state.user.userDetails.user?.id || null;

export default userSlice.reducer;
