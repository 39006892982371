import axios from "axios";

export const getStudentWalletApi = (userId) => {
  return axios.get(`/api/users/${userId}/students/wallet`);
};

export const getTeachertWalletApi = ({ userId, teacherId }) => {
  return axios.get(`/api/users/${userId}/teachers/${teacherId}/wallet`);
};

export const createPaymentRequestApi = ({
  userId,
  teacherId,
  walletId,
  amount,
  paypal,
}) => {
  return axios.post(
    `/api/users/${userId}/teachers/${teacherId}/wallet/${walletId}/paymentrequest`,
    {
      amount,
      paypal,
    }
  );
};

export const getPaymentRequestApi = ({ userId, teacherId, walletId }) => {
  return axios.get(
    `/api/users/${userId}/teachers/${teacherId}/wallet/${walletId}/paymentrequest`
  );
};

export const deletePaymentRequestApi = ({
  userId,
  teacherId,
  walletId,
  requestId,
}) => {
  return axios.delete(
    `/api/users/${userId}/teachers/${teacherId}/wallet/${walletId}/paymentrequest/${requestId}`
  );
};

export const getStudentsPaymentRequestApi = ({ userId, walletId }) => {
  return axios.get(
    `/api/users/${userId}/students/paymentRequest/${walletId}/getStudentPaymentRequest`
  );
};

export const addStudentsPaymentRequestApi = ({
  userId,
  walletId,
  amount,
  paypal,
}) => {
  return axios.post(
    `/api/users/${userId}/students/paymentRequest/${walletId}/getStudentPaymentRequest`,
    {
      amount,
      paypal,
    }
  );
};

export const deleteStudentPaymentRequestApi = ({
  userId,
  walletId,
  requestId,
}) => {
  return axios.delete(
    `/api/users/${userId}/students/paymentRequest/${walletId}/deletePaymentrequest/${requestId}`
  );
};

export const getTeacherClassesHistoryApi = ({
  userId,
  teacherId,
  page,
  limit,
}) => {
  return axios.get(
    `/api/users/${userId}/teachers/${teacherId}/wallet/classesHistory`,
    {
      params: {
        page,
        limit,
      },
    }
  );
};
export const getTeacherClassesHistoryDownloadableApi = ({
  userId,
  teacherId,
}) => {
  return axios.get(
    `/api/users/${userId}/teachers/${teacherId}/wallet/downloadClassesHistory`
  );
};
