import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendCode } from "../../services/api/apiServices/auth";

export const forgotPassword = createAsyncThunk(
  "FORGOT_PASSWORD",
  async ({ email, sweetAlert }) => {
    try {
      let { data } = await sendCode(email);
      data.email = email;
      sweetAlert({
        title: "Verification code sent!",
      });
      return data;
    } catch (error) {
      sweetAlert({
        title: "Error While getting code!",
        text: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  response: null,
};

export const forgotPasswordSlice = createSlice({
  name: "forgot_password",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearResponse: (state) => {
      state.response = null;
    },
  },
  extraReducers: {
    [forgotPassword.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = null;
    },
    [forgotPassword.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const { clearError, clearResponse } = forgotPasswordSlice.actions;

export const selectCodeResponse = (state) => state.forgotPassword;

export default forgotPasswordSlice.reducer;
