import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: null,
};

export const signUpOnboardingSlice = createSlice({
  name: "SIGNUP_ONBOARDING",
  initialState,
  reducers: {
    addOnboardingData: (state, action) => {
      state.formData = action.payload;
    },
    clearOnboardingData: (state) => {
      state.formData = null;
    },
  },
});

export const { addOnboardingData, clearOnboardingData } =
  signUpOnboardingSlice.actions;

export const selectOnboardingData = (state) => state.signupOnboarding.formData;

export default signUpOnboardingSlice.reducer;
