import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../assets/locales/en.json";
import ar from "../assets/locales/ar.json";

i18n.use(initReactI18next).init({
  resources: {
    en,
    ar,
  },
  lng: localStorage.getItem("languageCode"),
  fallbackLng: "en",
});
