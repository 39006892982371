import moment from "moment";
import values from "lodash/values";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";

import {
  PAYPAL_FEE_PERCENTAGE,
  PAYPAL_FIXED_FEE_USD,
  TIME_FORMAT,
  TIME_FORMAT_UNIX,
} from "../constants/Constants";
import { ceil, round } from "lodash";

export const HOURS = () => {
  let timeStamps = [];

  let startRange = moment().startOf("day");
  let endRange = moment().endOf("day");

  while (startRange.isBefore(endRange)) {
    let time = startRange.add(60, "minute");

    timeStamps = [...timeStamps, time.format(TIME_FORMAT)];
  }

  return timeStamps;
};

export const getList = (normalizedArray) =>
  normalizedArray ? values(normalizedArray.list) : [];

export const dateFormat = (date, format = TIME_FORMAT_UNIX) => {
  return moment.unix(date).format(format);
};

export const dateFormatTimeAgo = (date, format = TIME_FORMAT_UNIX) => {
  return moment.unix(date).fromNow();
};

export const getPercentage = ({ loaded, total }) => {
  return ceil((loaded / total) * 100);
};

export const stringToLiteral = (value) => {
  if (value === "undefined") return undefined;
  else if (value === "null") return null;
  else return value;
};

export const getPaypalFee = (amount) => {
  return (
    round((amount / 100) * PAYPAL_FEE_PERCENTAGE, 2) + PAYPAL_FIXED_FEE_USD
  );
};

export const exportToPdf = ({ data, headersList, title }) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);
  const headers = [headersList];

  let content = {
    startY: 50,
    head: headers,
    body: data,
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${title}.pdf`);
};

export const sweetAlert = ({ title, text, icon, timer }) => {
  return Swal.fire({
    title,
    text,
    icon: icon || "success",
    timer: timer || 5000,
  });
};

export const deleteSweetAlert = ({ title, text, icon, confirmButtonText }) => {
  return Swal.fire({
    title,
    text: text || "You won't be able to revert this!",
    icon: icon || "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmButtonText || "Yes, delete it!",
  });
};

const groupedDays = (messages) => {
  return messages.reduce((acc, el, i) => {
    const messageDay = moment(el.createdAt).format("YYYY-MM-DD");
    if (acc[messageDay]) {
      return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
    }
    return { ...acc, [messageDay]: [el] };
  }, {});
};

export const segregateMessages = (messages) => {
  const days = groupedDays(messages);
  const sortedDays = Object.keys(days).sort(
    (x, y) => moment(y, "YYYY-MM-DD").unix() - moment(x, "YYYY-MM-DD").unix()
  );
  const items = sortedDays.reduce((acc, date) => {
    const sortedMessages = days[date].sort(
      (x, y) => new Date(y.createdAt) - new Date(x.createdAt)
    );
    return acc.concat([...sortedMessages, { type: "day", date, id: date }]);
  }, []);
  return items.reverse();
};
