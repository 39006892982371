import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./Features/authSlice";
import userReducer from "./Features/userSlice";
import becomeTeacherReducer from "./Features/becomeTeacherSlice";
import signupOnboardingReducer from "./Features/signUpOnboardingSlice";
import teacherLessonReducer from "./Features/TeacherLessonsSlice";
import teacherSubjectReducer from "./Features/TeacherSubjectsSlice";
import lessonsReducer from "./Features/lessonsSlice";

import teachersReducer from "./Features/teachersSlice";
import subjectsReducer from "./Features/subjectsSlice";
import teacherAvailabilityReducer from "./Features/teachersAvailabilitySlice";
import classesReducer from "./Features/classesSlice";
import disputeReducer from "./Features/DisputeSlice";
import feedbacksReducer from "./Features/feedbacksSlice";
import conversationReducer from "./Features/conversations";
import walletReducer from "./Features/wallet";
import forgotPasswordReducer from "./Features/forgotPasswordSlice";
import packagesReducer from "./Features/packagesSlice";
import groupsReducer from "./Features/groupSlice";
import postsReducer from "./Features/postsSlice";
import commentsReducer from "./Features/commentsSlice";
import studyMaterialsReducer from "./Features/studyMaterials";
import findTeachersReducer from "./Features/findTeachersSlice";
import userProblemsReducer from "./Features/userProblems";
import resendCodeReducer from "./Features/resendCodeSlice";
import notificationsReducer from "./Features/notifications";
import instantLessonsReducer from "./Features/instantLessonSlice";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [
    "auth",
    "user",
    "becomeTeacher",
    "signupOnboarding",
    "forgotPassword",
  ],
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  becomeTeacher: becomeTeacherReducer,
  signupOnboarding: signupOnboardingReducer,
  teacherLessons: teacherLessonReducer,
  teacherSubjects: teacherSubjectReducer,
  lessons: lessonsReducer,
  teachers: teachersReducer,
  subjects: subjectsReducer,
  teacherAvailability: teacherAvailabilityReducer,
  classes: classesReducer,
  dispute: disputeReducer,
  feedbacks: feedbacksReducer,
  conversations: conversationReducer,
  wallet: walletReducer,
  forgotPassword: forgotPasswordReducer,
  packages: packagesReducer,
  groups: groupsReducer,
  posts: postsReducer,
  comments: commentsReducer,
  studyMaterials: studyMaterialsReducer,
  findTeachers: findTeachersReducer,
  userProblems: userProblemsReducer,
  resendCode: resendCodeReducer,
  notifications: notificationsReducer,
  instantLessons: instantLessonsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const finalReducer = (state, action) => {
  if (action.type === "LOGOUT/fulfilled" || action.type === "auth/logout") {
    state = {
      ...state,
      user: undefined,
      auth: undefined,
      becomeTeacher: undefined,
      signupOnboarding: undefined,
    };
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  }
  return persistedReducer(state, action);
};

export const store = configureStore({
  reducer: finalReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
