import axios from "axios";

export const get_chat_participants = (userId) => {
  return axios.get(`/api/users/${userId}/conversation/getParticipants`);
};

export const get_conversation_token = ({ userId, teacherId }) => {
  return axios.get(
    `/api/users/${userId}/conversation/${teacherId}/getConversation`
  );
};
export const get_admin_conversation_token = ({ userId, adminId }) => {
  return axios.get(
    `/api/users/${userId}/conversation/${adminId}/getAdminConversation`
  );
};
