import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createUserProblemsApi } from "../../services/api/apiServices/auth";

export const createUserProblems = createAsyncThunk(
  "CREATE_USER_PROBLEMS",
  async ({
    userId,
    description,
    file,
    sweetAlert,
    setToggleModal,
    clearForm,
  }) => {
    try {
      const { data } = await createUserProblemsApi({
        userId,
        description,
        file,
      });
      clearForm();
      sweetAlert({
        title: "Report Registered!",
      });
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      setToggleModal(false);
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  userProblems: null,
};

export const userProblemsSlice = createSlice({
  name: "user_problems",
  initialState,
  reducers: {
    clearUserProblems: (state) => {
      state.userProblems = null;
    },
  },
  extraReducers: {
    [createUserProblems.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createUserProblems.fulfilled]: (state, { payload }) => {
      state.userProblems = payload;
      state.loading = false;
      state.error = null;
    },
    [createUserProblems.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const { clearUserProblems } = userProblemsSlice.actions;

export const selectProblems = (state) => state.userProblems;

export default userProblemsSlice.reducer;
