import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getStudentWalletApi,
  getTeachertWalletApi,
  createPaymentRequestApi,
  getPaymentRequestApi,
  deletePaymentRequestApi,
  getStudentsPaymentRequestApi,
  deleteStudentPaymentRequestApi,
  addStudentsPaymentRequestApi,
  getTeacherClassesHistoryApi,
  getTeacherClassesHistoryDownloadableApi,
} from "../../services/api/apiServices/wallet";
import { exportToPdf } from "../../utils";

export const getStudentWallet = createAsyncThunk(
  "GET_STUDENT_WALLET",
  async ({ userId, sweetAlert }) => {
    try {
      const { data } = await getStudentWalletApi(userId);
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

export const getTeacherWallet = createAsyncThunk(
  "GET_TEACHER_WALLET",
  async ({ userId, teacherId, sweetAlert }) => {
    try {
      const { data } = await getTeachertWalletApi({ userId, teacherId });
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

export const createPaymentRequest = createAsyncThunk(
  "CREATE_PAYMENT_REQUEST",
  async ({ userId, teacherId, walletId, amount, paypal, sweetAlert }) => {
    try {
      const { data } = await createPaymentRequestApi({
        userId,
        teacherId,
        walletId,
        amount,
        paypal,
      });
      sweetAlert({
        title: "Payment Request!",
        text: "Payment Request Added Successfully!",
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getPaymentRequest = createAsyncThunk(
  "GET_PAYMENT_REQUEST",
  async ({ userId, teacherId, walletId, sweetAlert }) => {
    try {
      const { data } = await getPaymentRequestApi({
        userId,
        teacherId,
        walletId,
      });
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);
export const deletePaymentRequest = createAsyncThunk(
  "DELETE_PAYMENT_REQUEST",
  async ({ userId, teacherId, walletId, requestId, sweetAlert }) => {
    try {
      const { data } = await deletePaymentRequestApi({
        userId,
        teacherId,
        walletId,
        requestId,
      });
      sweetAlert({
        title: "Payment Request!",
        text: "Payment Request Deleted Successfully!",
        icon: "success",
        timer: 5000,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getStudentPaymentRequest = createAsyncThunk(
  "GET_STUDENTS_PAYMENT_REQUEST",
  async ({ userId, walletId, sweetAlert }) => {
    try {
      const { data } = await getStudentsPaymentRequestApi({
        userId,
        walletId,
      });
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const deleteStudentPaymentRequest = createAsyncThunk(
  "DELETE_STUDENT_PAYMENT_REQUEST",
  async (
    { userId, walletId, requestId, sweetAlert, setShowDeleteModal },
    { dispatch }
  ) => {
    try {
      const { data } = await deleteStudentPaymentRequestApi({
        userId,
        walletId,
        requestId,
      });
      sweetAlert({
        title: "Payment Request Deleted!",
        icon: "success",
      });
      setShowDeleteModal(false);
      dispatch(getStudentPaymentRequest({ userId, walletId }));
      dispatch(getStudentWallet({ userId }));
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const addStudentsPaymentRequest = createAsyncThunk(
  "ADD_STUDENT_PAYMENT_REQUEST",
  async (
    { userId, walletId, amount, paypal, sweetAlert, setShowModal },
    { dispatch }
  ) => {
    try {
      const { data } = await addStudentsPaymentRequestApi({
        userId,
        walletId,
        amount,
        paypal,
      });
      setShowModal(false);
      dispatch(getStudentPaymentRequest({ userId, walletId }));
      dispatch(getStudentWallet({ userId }));
      sweetAlert({
        title: "Payment Request Added!",
      });
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const getTeacherClassesHistory = createAsyncThunk(
  "GET_TEACHER_CLASSES_HISTORY",
  async ({ userId, teacherId, page, limit }) => {
    try {
      const { data } = await getTeacherClassesHistoryApi({
        userId,
        teacherId,
        page,
        limit,
      });
      return data.data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getTeacherClassesHistoryDownload = createAsyncThunk(
  "GET_TEACHER_CLASSES_HISTORY_DOWNLOAD",
  async ({ userId, teacherId, sweetAlert }) => {
    try {
      const { data } = await getTeacherClassesHistoryDownloadableApi({
        userId,
        teacherId,
      });

      const title = "Class History";
      const headers = ["S.no", "Date", "Lesson", "Student", "Amount", "Status"];

      const processedData = data.data.map((elt) => [
        elt.sNo,
        elt.date,
        elt.lessonName,
        elt.studentName,
        elt.price,
        elt.status,
      ]);
      exportToPdf({
        data: processedData,
        headersList: headers,
        title,
      });
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  wallet: null,
  risePercentage: 0,
  paymentRequestData: {
    loading: false,
    error: null,
    paymentRequests: null,
  },
  studentPaymentRequestData: {
    loading: false,
    error: null,
    paymentRequests: null,
  },
  paymentRequest: {
    loading: false,
    error: null,
    response: null,
  },
  deletePaymentRequest: {
    loading: false,
    error: null,
    response: null,
  },
  deleteStudentPaymentRequest: {
    loading: false,
    error: null,
    response: null,
  },
  addStudentPaymentRequest: {
    loading: false,
    error: null,
    response: null,
  },
  teacherClassesHistory: {
    loading: false,
    error: null,
    data: null,
    totalCount: null,
  },
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    clearWalletState: (state) => {
      state.paymentRequest.error = null;
      state.paymentRequest.response = null;
      state.deletePaymentRequest.error = null;
      state.deletePaymentRequest.response = null;
    },
  },
  extraReducers: {
    //get student wallet
    [getStudentWallet.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getStudentWallet.fulfilled]: (state, { payload }) => {
      state.wallet = payload;
      state.loading = false;
      state.error = null;
    },
    [getStudentWallet.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // get teacher wallet
    [getTeacherWallet.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTeacherWallet.fulfilled]: (state, { payload }) => {
      state.wallet = payload.wallet;
      state.risePercentage = payload.risePercentage;
      state.loading = false;
      state.error = null;
    },
    [getTeacherWallet.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // create payment request
    [createPaymentRequest.pending]: (state) => {
      state.paymentRequest.loading = true;
      state.paymentRequest.error = null;
    },
    [createPaymentRequest.fulfilled]: (state, { payload }) => {
      state.paymentRequest.response = payload;
      state.paymentRequest.loading = false;
      state.paymentRequest.error = null;
    },
    [createPaymentRequest.rejected]: (state, { error }) => {
      state.paymentRequest.loading = false;
      state.paymentRequest.error = error.message;
    },
    // get payment request
    [getPaymentRequest.pending]: (state) => {
      state.paymentRequestData.loading = true;
      state.paymentRequestData.error = null;
    },
    [getPaymentRequest.fulfilled]: (state, { payload }) => {
      state.paymentRequestData.paymentRequests = payload;
      state.paymentRequestData.loading = false;
      state.paymentRequestData.error = null;
    },
    [getPaymentRequest.rejected]: (state, { error }) => {
      state.paymentRequestData.loading = false;
      state.paymentRequestData.error = error.message;
    },
    // delete payment request
    [deletePaymentRequest.pending]: (state) => {
      state.deletePaymentRequest.loading = true;
      state.deletePaymentRequest.error = null;
    },
    [deletePaymentRequest.fulfilled]: (state, { payload }) => {
      state.deletePaymentRequest.response = payload;
      state.deletePaymentRequest.loading = false;
      state.deletePaymentRequest.error = null;
    },
    [deletePaymentRequest.rejected]: (state, { error }) => {
      state.deletePaymentRequest.loading = false;
      state.deletePaymentRequest.error = error.message;
    },
    // get Students payment request
    [getStudentPaymentRequest.pending]: (state) => {
      state.studentPaymentRequestData.loading = true;
      state.studentPaymentRequestData.error = null;
    },
    [getStudentPaymentRequest.fulfilled]: (state, { payload }) => {
      state.studentPaymentRequestData.paymentRequests = payload;
      state.studentPaymentRequestData.loading = false;
      state.studentPaymentRequestData.error = null;
    },
    [getStudentPaymentRequest.rejected]: (state, { error }) => {
      state.studentPaymentRequestData.loading = false;
      state.studentPaymentRequestData.error = error.message;
    },
    // delete Students payment request
    [deleteStudentPaymentRequest.pending]: (state) => {
      state.deleteStudentPaymentRequest.loading = true;
      state.deleteStudentPaymentRequest.error = null;
    },
    [deleteStudentPaymentRequest.fulfilled]: (state, { payload }) => {
      state.deleteStudentPaymentRequest.response = payload;
      state.deleteStudentPaymentRequest.loading = false;
      state.deleteStudentPaymentRequest.error = null;
    },
    [deleteStudentPaymentRequest.rejected]: (state, { error }) => {
      state.deleteStudentPaymentRequest.loading = false;
      state.deleteStudentPaymentRequest.error = error.message;
    },
    // add Students payment request
    [addStudentsPaymentRequest.pending]: (state) => {
      state.addStudentPaymentRequest.loading = true;
      state.addStudentPaymentRequest.error = null;
    },
    [addStudentsPaymentRequest.fulfilled]: (state, { payload }) => {
      state.addStudentPaymentRequest.response = payload;
      state.addStudentPaymentRequest.loading = false;
      state.addStudentPaymentRequest.error = null;
    },
    [addStudentsPaymentRequest.rejected]: (state, { error }) => {
      state.addStudentPaymentRequest.loading = false;
      state.addStudentPaymentRequest.error = error.message;
    },
    // get teacher classes history
    [getTeacherClassesHistory.pending]: (state) => {
      state.teacherClassesHistory.loading = true;
      state.teacherClassesHistory.error = null;
    },
    [getTeacherClassesHistory.fulfilled]: (state, { payload }) => {
      state.teacherClassesHistory.data = payload.classes;
      state.teacherClassesHistory.totalCount = payload.totalCount;
      state.teacherClassesHistory.loading = false;
      state.teacherClassesHistory.error = null;
    },
    [getTeacherClassesHistory.rejected]: (state, { error }) => {
      state.teacherClassesHistory.loading = false;
      state.teacherClassesHistory.error = error.message;
    },
  },
});

export const selectWallet = (state) => state.wallet;
export const selectPaymentRequest = (state) => state.wallet.paymentRequest;
export const selectPaymentRequestData = (state) =>
  state.wallet.paymentRequestData;
export const selectDeletePaymentRequest = (state) =>
  state.wallet.deletePaymentRequest;
export const selectStudentPaymentRequests = (state) =>
  state.wallet.studentPaymentRequestData;
export const selectStudentDeletePaymentRequests = (state) =>
  state.wallet.deleteStudentPaymentRequest;
export const selectAddStudentPaymentRequests = (state) =>
  state.wallet.addStudentPaymentRequest;
export const selectTeacherClassesHistory = (state) =>
  state.wallet.teacherClassesHistory;

export const { clearWalletState } = walletSlice.actions;

export default walletSlice.reducer;
