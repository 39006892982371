import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTeachersApi,
  getRecommendedTeachersApi,
  getSubjectsApi,
} from "../../services/api/apiServices/teachers";

export const getTeachers = createAsyncThunk(
  "GET_TEACHERS",
  async ({
    userId,
    nameSearch,
    type,
    country,
    language,
    topRating,
    rating,
    maxPrice,
    minPrice,
    subjectSearch,
    isActive,
    page = 1,
    limit = 10,
  }) => {
    try {
      const { data } = await getTeachersApi({
        userId,
        nameSearch,
        type,
        country,
        language,
        topRating,
        rating,
        maxPrice,
        minPrice,
        subjectSearch,
        isActive,
        page,
        limit,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getRecommended = createAsyncThunk(
  "GET_RECOMMENDED_TEACHER",
  async (userId) => {
    try {
      const { data } = await getRecommendedTeachersApi(userId);
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getSubjects = createAsyncThunk("GET_ALL_SUBJECTS", async () => {
  try {
    const { data } = await getSubjectsApi();
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
});

const initialState = {
  loading: false,
  error: null,
  teachers: null,
  recommendedTeachers: {
    loading: false,
    error: null,
    teachers: null,
  },
  subjects: {
    loading: false,
    error: null,
    subjects: null,
  },
};

export const findTeacherSlice = createSlice({
  name: "findteachers",
  initialState,
  reducers: {},
  extraReducers: {
    [getTeachers.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTeachers.fulfilled]: (state, { payload }) => {
      state.teachers = payload;
      state.loading = false;
      state.error = null;
    },
    [getTeachers.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //recommended teachers
    [getRecommended.pending]: (state) => {
      state.recommendedTeachers.loading = true;
      state.recommendedTeachers.error = null;
    },
    [getRecommended.fulfilled]: (state, { payload }) => {
      state.recommendedTeachers.teachers = payload;
      state.recommendedTeachers.loading = false;
      state.recommendedTeachers.error = null;
    },
    [getRecommended.rejected]: (state, { error }) => {
      state.recommendedTeachers.loading = false;
      state.recommendedTeachers.error = error.message;
    },
    //get subjects
    [getSubjects.pending]: (state) => {
      state.subjects.loading = true;
      state.subjects.error = null;
    },
    [getSubjects.fulfilled]: (state, { payload }) => {
      state.subjects.subjects = payload;
      state.subjects.loading = false;
      state.subjects.error = null;
    },
    [getSubjects.rejected]: (state, { error }) => {
      state.subjects.loading = false;
      state.subjects.error = error.message;
    },
  },
});

export const {} = findTeacherSlice.actions;

export const selectTeachers = (state) => state.findTeachers;
export const selectRecommendedTeachers = (state) =>
  state.findTeachers.recommendedTeachers;
export const selectAllSubjects = (state) => state.findTeachers.subjects;

export default findTeacherSlice.reducer;
