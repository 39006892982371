import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDisputesApi,
  startDisputesApi,
  updateDisputeApi,
} from "../../services/api/apiServices/disputes";

export const startDispute = createAsyncThunk(
  "START_DISPUTE",
  async ({ formData, userId }) => {
    try {
      const { data } = await startDisputesApi({ formData, userId });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getDispute = createAsyncThunk(
  "GET_DISPUTE",
  async ({ userId, disputeId }) => {
    try {
      const { data } = await getDisputesApi({ userId, disputeId });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const updateDispute = createAsyncThunk(
  "UPDATE_DISPUTE",
  async ({ userId, disputeId, formData }) => {
    try {
      const { data } = await updateDisputeApi({ userId, disputeId, formData });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  dispute: null,
  updateDispute: {
    loading: false,
    error: null,
    response: null,
  },
};

export const disputeSlice = createSlice({
  name: "dispute",
  initialState,
  reducers: {
    clearDisputes: (state) => {
      state.dispute = null;
      state.error = null;
    },
    clearDisputeResponse: (state) => {
      state.updateDispute.response = null;
      state.updateDispute.error = null;
    },
  },
  extraReducers: {
    [startDispute.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [startDispute.fulfilled]: (state, { payload }) => {
      state.dispute = payload;
      state.loading = false;
      state.error = null;
    },
    [startDispute.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //get disputes
    [getDispute.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getDispute.fulfilled]: (state, { payload }) => {
      state.dispute = payload;
      state.loading = false;
      state.error = null;
    },
    [getDispute.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //update disputes
    [updateDispute.pending]: (state) => {
      state.updateDispute.loading = true;
      state.updateDispute.error = null;
    },
    [updateDispute.fulfilled]: (state, { payload }) => {
      state.updateDispute.response = payload;
      state.updateDispute.loading = false;
      state.updateDispute.error = null;
    },
    [updateDispute.rejected]: (state, { error }) => {
      state.updateDispute.loading = false;
      state.updateDispute.error = error.message;
    },
  },
});

export const selectDispute = (state) => state.dispute;
export const selectUpdateDispute = (state) => state.dispute.updateDispute;

export const { clearDisputes, clearDisputeResponse } = disputeSlice.actions;

export default disputeSlice.reducer;
