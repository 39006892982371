import Select from "react-select";
import { COLORS } from "../constants/Constants";

const SelectComponent = ({
  onChange,
  defaultValue,
  loading,
  options,
  value,
  placeholder,
  isDisabled,
  className,
  height,
  background,
  childKey,
  valueKey,
  emptyError,
  success,
  defaultLabel,
  placeholderStyle,
  borderRadius,
  isClearable,
}) => {
  const mappedOptions = options.map((items, idx) => {
    return {
      idx: idx,
      value: items,
      label: items,
    };
  });
  const mappedObjectsOptions = options.map((items) => {
    return {
      value: items[valueKey || childKey],
      label: items[childKey],
    };
  });
  const selectStyles = {
    control: (base) => ({
      ...base,
      height: height || 43,
      minHeight: 35,
      background: background,
      borderRadius: borderRadius,
      border:
        (emptyError && `1.5px solid ${COLORS.DARKSECONDARY}`) ||
        (success && `1.5px solid ${COLORS.DARKPRIMARY}`),
      boxShadow:
        (emptyError && `1.5px solid ${COLORS.DARKSECONDARY}`) ||
        (success && `1.5px solid ${COLORS.DARKPRIMARY}`),
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        ...placeholderStyle,
      };
    },
  };
  return (
    <Select
      options={childKey ? mappedObjectsOptions : mappedOptions}
      isSearchable={true}
      isClearable={isClearable || false}
      isLoading={loading}
      onChange={onChange}
      defaultValue={
        defaultValue
          ? { label: defaultLabel || defaultValue, value: defaultValue }
          : null
      }
      value={value}
      placeholder={placeholder}
      isDisabled={isDisabled}
      className={className}
      styles={selectStyles}
    />
  );
};

export default SelectComponent;
