import axios from "axios";

export const getNotificationsApi = ({ userId, page = 1, limit = 10 }) => {
  return axios.get(
    `/api/users/${userId}/notifications?page=${page}&limit=${limit}`
  );
};

export const updateAllNotificationsApi = (userId) => {
  return axios.put(`/api/users/${userId}/notifications`);
};

export const updateNotificationsApi = ({ userId, id }) => {
  return axios.put(`/api/users/${userId}/notifications/${id}/update`);
};
