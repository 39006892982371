import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  get_chat_participants,
  get_conversation_token,
  get_admin_conversation_token,
} from "../../services/api/apiServices/conversations";

export const getChatParticipants = createAsyncThunk(
  "GET_CHAT_PARTICIPANTS",
  async (userId) => {
    try {
      const { data } = await get_chat_participants(userId);
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getConversationToken = createAsyncThunk(
  "GET_CONVERSATION_TOKEN",
  async ({ userId, teacherId, navigate, sweetAlert, seeMsg }) => {
    try {
      const { data } = await get_conversation_token({ userId, teacherId });
      if (seeMsg) {
        navigate(`/user/${userId}/messages`);
      }
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

export const getAdminConversationToken = createAsyncThunk(
  "GET_ADMIN_CONVERSATION_TOKEN",
  async ({ userId, adminId, sweetAlert }) => {
    try {
      const { data } = await get_admin_conversation_token({
        userId,
        adminId,
      });

      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

const initialState = {
  chatParticipants: { loading: false, error: null, participants: null },
  conversationToken: {
    loading: false,
    error: null,
    conversation: {
      token: null,
      sid: null,
    },
  },
};

export const conversationSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    clearConversations: (state) => {
      state.conversationToken.conversation = { token: null, sid: null };
    },
  },
  extraReducers: {
    [getChatParticipants.pending]: (state) => {
      state.chatParticipants.loading = true;
      state.chatParticipants.error = null;
    },
    [getChatParticipants.fulfilled]: (state, { payload }) => {
      state.chatParticipants.participants = payload;
      state.chatParticipants.loading = false;
      state.chatParticipants.error = null;
    },
    [getChatParticipants.rejected]: (state, { error }) => {
      state.chatParticipants.loading = false;
      state.chatParticipants.error = error.message;
    },
    // user chat token
    [getConversationToken.pending]: (state) => {
      state.conversationToken.loading = true;
      state.conversationToken.error = null;
    },
    [getConversationToken.fulfilled]: (state, { payload }) => {
      state.conversationToken.conversation.token = payload?.accessToken;
      state.conversationToken.conversation.sid = payload?.conversation?.sid;
      state.conversationToken.loading = false;
      state.conversationToken.error = null;
    },
    [getConversationToken.rejected]: (state, { error }) => {
      state.conversationToken.loading = false;
      state.conversationToken.error = error.message;
    },
    // admin chat token
    [getAdminConversationToken.pending]: (state) => {
      state.conversationToken.loading = true;
      state.conversationToken.error = null;
    },
    [getAdminConversationToken.fulfilled]: (state, { payload }) => {
      state.conversationToken.conversation.token = payload?.accessToken;
      state.conversationToken.conversation.sid = payload?.conversation?.sid;
      state.conversationToken.loading = false;
      state.conversationToken.error = null;
    },
    [getAdminConversationToken.rejected]: (state, { error }) => {
      state.conversationToken.loading = false;
      state.conversationToken.error = error.message;
    },
  },
});

export const { clearConversations } = conversationSlice.actions;

export const selectParticipants = (state) =>
  state.conversations.chatParticipants;
export const selectConversations = (state) =>
  state.conversations.conversationToken;

export default conversationSlice.reducer;
