import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getGroupsApi,
  getSingleGroupApi,
  createGroupsApi,
  getRecommendedGroupsApi,
  addUserToGroupApi,
  removeUserFromGroupApi,
  getPublicGroupsApi,
  getSinglePublicGroupsApi,
  deleteGroupApi,
  updateGroupApi,
} from "../../services/api/apiServices/groups";

export const getGroups = createAsyncThunk(
  "GET_USER_GROUPS",
  async ({ userId, search }) => {
    try {
      const { data } = await getGroupsApi({ userId, search });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getSingleGroup = createAsyncThunk(
  "GET_SINGLE_GROUPS",
  async ({ userId, clubId, navigate, sweetAlert }) => {
    try {
      const { data } = await getSingleGroupApi({ userId, clubId });
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong while getting group",
      });
      setTimeout(() => {
        navigate(-1);
      }, 3000);
      throw error?.response?.data;
    }
  }
);

export const createGroup = createAsyncThunk(
  "CREATE_GROUP",
  async ({ userId, formData }) => {
    try {
      const { data } = await createGroupsApi({ userId, formData });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getRecommendedGroup = createAsyncThunk(
  "GET_RECOMMENDED_GROUP",
  async ({ userId, search }) => {
    try {
      const { data } = await getRecommendedGroupsApi({ userId, search });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const addUserToGroup = createAsyncThunk(
  "ADD_USER_TO_GROUP",
  async ({ userId, clubId, sweetAlert, getGroupsData }) => {
    try {
      const { data } = await addUserToGroupApi({ userId, clubId });
      sweetAlert({
        title: "Group joined!",
      });
      getGroupsData();
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong while joining group!",
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const removeUserFromGroup = createAsyncThunk(
  "REMOVE_USER_FROM_GROUP",
  async ({ userId, clubId, sweetAlert, getGroupsData }) => {
    try {
      const { data } = await removeUserFromGroupApi({ userId, clubId });
      sweetAlert({
        title: "Group left!",
      });
      getGroupsData();
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong while leaving group!",
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const getPublicGroups = createAsyncThunk(
  "GET_PUBLIC_GROUPS",
  async () => {
    try {
      const { data } = await getPublicGroupsApi();
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getSinglePublicGroup = createAsyncThunk(
  "GET_SINGLE_PUBLIC_GROUPS",
  async ({ clubId, navigate, sweetAlert }) => {
    try {
      const { data } = await getSinglePublicGroupsApi(clubId);
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong while getting group",
        icon: "error",
      });
      setTimeout(() => {
        navigate(-1);
      }, 3000);
      throw error?.response?.data;
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "DELETE_GROUPS",
  async ({ userId, clubId, sweetAlert, navigate }) => {
    try {
      const { data } = await deleteGroupApi({ userId, clubId });
      sweetAlert({
        title: "Group deleted!",
      });
      navigate(-1);
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong while deleting group!",
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const updateGroup = createAsyncThunk(
  "UPDATE_GROUPS",
  async ({
    userId,
    clubId,
    formData,
    sweetAlert,
    setShowModal,
    getGroupsData,
  }) => {
    try {
      const { data } = await updateGroupApi({ userId, clubId, formData });
      sweetAlert({
        title: "Group updated!",
      });
      setShowModal(false);
      getGroupsData();
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong while updating group!",
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  groups: null,
  group: null,
  createGroup: {
    loading: false,
    error: null,
    response: null,
  },
  recommendedGroup: {
    loading: false,
    error: null,
    data: null,
  },
  addOrRemoveUser: {
    loading: false,
    error: null,
    response: null,
  },
  updateGroup: {
    loading: false,
    error: null,
    response: null,
  },
};

export const userSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    clearCreateGroup: (state) => {
      state.createGroup = {
        loading: false,
        error: null,
        response: null,
      };
    },
    clearAddOrRemoveUser: (state) => {
      state.addOrRemoveUser.response = null;
      state.updateGroup.response = null;
    },
  },
  extraReducers: {
    [getGroups.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getGroups.fulfilled]: (state, { payload }) => {
      state.groups = payload;
      state.loading = false;
      state.error = null;
    },
    [getGroups.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // get public groups
    [getPublicGroups.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPublicGroups.fulfilled]: (state, { payload }) => {
      state.groups = payload;
      state.loading = false;
      state.error = null;
    },
    [getPublicGroups.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //get single group
    [getSingleGroup.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSingleGroup.fulfilled]: (state, { payload }) => {
      state.group = payload;
      state.loading = false;
      state.error = null;
    },
    [getSingleGroup.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //get single public group
    [getSinglePublicGroup.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSinglePublicGroup.fulfilled]: (state, { payload }) => {
      state.group = payload;
      state.loading = false;
      state.error = null;
    },
    [getSinglePublicGroup.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //create group
    [createGroup.pending]: (state) => {
      state.createGroup.loading = true;
      state.createGroup.error = null;
    },
    [createGroup.fulfilled]: (state, { payload }) => {
      state.createGroup.response = payload;
      state.createGroup.loading = false;
      state.createGroup.error = null;
    },
    [createGroup.rejected]: (state, { error }) => {
      state.createGroup.loading = false;
      state.createGroup.error = error.message;
    },
    //recommended groups
    [getRecommendedGroup.pending]: (state) => {
      state.recommendedGroup.loading = true;
      state.recommendedGroup.error = null;
    },
    [getRecommendedGroup.fulfilled]: (state, { payload }) => {
      state.recommendedGroup.data = payload;
      state.recommendedGroup.loading = false;
      state.recommendedGroup.error = null;
    },
    [getRecommendedGroup.rejected]: (state, { error }) => {
      state.recommendedGroup.loading = false;
      state.recommendedGroup.error = error.message;
    },
    //addUsers groups
    [addUserToGroup.pending]: (state) => {
      state.addOrRemoveUser.loading = true;
      state.addOrRemoveUser.error = null;
    },
    [addUserToGroup.fulfilled]: (state, { payload }) => {
      state.addOrRemoveUser.response = payload;
      state.addOrRemoveUser.loading = false;
      state.addOrRemoveUser.error = null;
    },
    [addUserToGroup.rejected]: (state, { error }) => {
      state.addOrRemoveUser.loading = false;
      state.addOrRemoveUser.error = error.message;
    },
    //remove users groups
    [removeUserFromGroup.pending]: (state) => {
      state.addOrRemoveUser.loading = true;
      state.addOrRemoveUser.error = null;
    },
    [removeUserFromGroup.fulfilled]: (state, { payload }) => {
      state.addOrRemoveUser.response = payload;
      state.addOrRemoveUser.loading = false;
      state.addOrRemoveUser.error = null;
    },
    [removeUserFromGroup.rejected]: (state, { error }) => {
      state.addOrRemoveUser.loading = false;
      state.addOrRemoveUser.error = error.message;
    },
    //Delete group
    [deleteGroup.pending]: (state) => {
      state.addOrRemoveUser.loading = true;
      state.addOrRemoveUser.error = null;
    },
    [deleteGroup.fulfilled]: (state, { payload }) => {
      state.addOrRemoveUser.response = payload;
      state.addOrRemoveUser.loading = false;
      state.addOrRemoveUser.error = null;
    },
    [deleteGroup.rejected]: (state, { error }) => {
      state.addOrRemoveUser.loading = false;
      state.addOrRemoveUser.error = error.message;
    },
    //Update group
    [updateGroup.pending]: (state) => {
      state.updateGroup.loading = true;
      state.updateGroup.error = null;
    },
    [updateGroup.fulfilled]: (state, { payload }) => {
      state.updateGroup.response = payload;
      state.updateGroup.loading = false;
      state.updateGroup.error = null;
    },
    [updateGroup.rejected]: (state, { error }) => {
      state.updateGroup.loading = false;
      state.updateGroup.error = error.message;
    },
  },
});

export const { clearCreateGroup, clearAddOrRemoveUser } = userSlice.actions;

export const selectGroups = (state) => state.groups;
export const selectCreateGroup = (state) => state.groups.createGroup;
export const selectRecommendedGroup = (state) => state.groups.recommendedGroup;
export const selectAddOrRemoveUser = (state) => state.groups.addOrRemoveUser;
export const selectUpdateGroup = (state) => state.groups.updateGroup;

export default userSlice.reducer;
