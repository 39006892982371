import React from "react";
import RiseIcon from "../assets/card4.svg";

const FullScreenLoader = () => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center`}
      style={{ width: "100%", height: "75vh" }}
    >
      <div>
        <img src={RiseIcon} alt="Rise logo" className="loading-logo" />
      </div>
    </div>
  );
};

export default FullScreenLoader;
