import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getOneLesson } from "./lessonsSlice";

export const addFeedback = createAsyncThunk(
  "ADD_FEEDBACK",
  async ({ formData, userId, classId, resetForm }, { dispatch }) => {
    try {
      const { data } = await axios.post(
        `/api/users/${userId}/students/feedbacks/${classId}`,
        formData
      );
      dispatch(getOneLesson({ userId, classId }));
      resetForm();
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  successMessage: null,
  feedbacks: null,
};

export const feedbacksSlice = createSlice({
  name: "feedbacks",
  initialState,
  reducers: {
    clearSuccessMessage: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers: {
    [addFeedback.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addFeedback.fulfilled]: (state, { payload }) => {
      state.successMessage = payload;
      state.loading = false;
      state.error = null;
    },
    [addFeedback.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const { clearSuccessMessage } = feedbacksSlice.actions;

export const selectFeedbacks = (state) => state.feedbacks;

export default feedbacksSlice.reducer;
