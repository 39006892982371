import axios from "axios";
import { LESSONSTATUSHIDDEN } from "../../../constants/Constants";

export const addClassesApi = ({
  userId,
  teacherId,
  slotId,
  lessonId,
  paypalId,
  paymentStatus,
  price,
  packages,
  paidBy,
  packageId,
  paypalFee,
}) => {
  return axios.post(`/api/users/${userId}/students/lessons`, {
    fkStudentId: userId,
    fkTeacherId: teacherId,
    fkTeacherTimeSlotId: slotId,
    fkSubjectLessonId: lessonId,
    paypalId,
    paymentStatus,
    price,
    packages,
    paidBy,
    packageId,
    paypalFee,
  });
};

export const getTeacherClassesApi = ({
  userId,
  teacherId,
  page,
  limit,
  status,
  upcomming,
}) => {
  return axios.get(
    `/api/users/${userId}/teachers/${teacherId}/lessons?page=${page}&limit=${limit}&status=${status}&upcomming=${upcomming}`
  );
};

export const getTeacherUpdatesApi = ({ userId, teacherId, page, limit }) => {
  return axios.get(
    `/api/users/${userId}/teachers/${teacherId}/updates?page=${page}&limit=${limit}`
  );
};

export const getTeacheOneClassApi = ({ userId, teacherId, lessonId }) => {
  return axios.get(
    `/api/users/${userId}/teachers/${teacherId}/lesson/${lessonId}`
  );
};

export const updateClassesApi = ({
  userId,
  teacherId,
  lessonId,
  action,
  completed,
}) => {
  return axios.put(
    `/api/users/${userId}/teachers/${teacherId}/lesson/${lessonId}?action=${action}&completed=${completed}`
  );
};

export const getStudentClassesApi = ({
  userId,
  status,
  upcomming,
  page,
  limit,
}) => {
  return axios.get(
    `/api/users/${userId}/students/lessons?status=${status}&upcomming=${upcomming}&page=${page}&limit=${limit}`
  );
};

export const getStudentClassApi = ({ userId, classId }) => {
  return axios.get(`/api/users/${userId}/students/lessons/${classId}`);
};

export const completeStatusClassApi = ({ userId, classId }) => {
  return axios.get(
    `/api/users/${userId}/students/lessons/${classId}/completeClass`
  );
};

export const getPendingClassApi = ({ userId, page, limit }) => {
  return axios.get(
    `/api/users/${userId}/students/lessons?status=${
      LESSONSTATUSHIDDEN.pending
    }&upcomming=${false}&page=${page}&limit=${limit}`
  );
};

export const getCurrentClassApi = ({ userId, page, limit }) => {
  return axios.get(
    `/api/users/${userId}/students/lessons?current=${true}&upcomming=${false}&page=${page}&limit=${limit}`
  );
};

export const addTeacherlessonApi = ({ userId, teacherId, lessonInfo }) => {
  return axios.post(
    `/api/users/${userId}/teachers/${teacherId}/subjects/lessons`,
    lessonInfo
  );
};

export const cancelClassApi = ({ userId, lessonId, userRole }) => {
  return axios.get(
    `/api/users/${userId}/cancelclass/${lessonId}/insert?role=${userRole}`
  );
};

export const getTeacherCancelClassApi = ({
  userId,
  teacherId,
  page = 1,
  limit = 5,
}) => {
  return axios.get(
    `/api/users/${userId}/teachers/${teacherId}/cancelledClasses?page=${page}&limit=${limit}`
  );
};

export const getStudentCancelClassApi = ({ userId, page, limit }) => {
  return axios.get(
    `/api/users/${userId}/students/cancelledClasses?page=${page}&limit=${limit}`
  );
};

export const getStudentImportantUpdatesApi = ({ userId, limit }) => {
  return axios.get(
    `/api/users/${userId}/students/lessons/studentImportantUpdates?limit=${limit}`
  );
};

export const getStudentOneCancelClassApi = ({ userId, cancelledClassId }) => {
  return axios.get(
    `/api/users/${userId}/students/lessons/${cancelledClassId}/cancelledClass`
  );
};

export const getTeacherOneCancelClassApi = ({
  userId,
  teacherId,
  cancelledClassId,
}) => {
  return axios.get(
    `/api/users/${userId}/teachers/${teacherId}/lesson/${cancelledClassId}/cancelledClass`
  );
};

export const rescheduleCancelledClass = ({
  userId,
  cancelledClassId,
  teacherId,
  subjectLessonId,
  teacherTimeSlotId,
}) => {
  return axios.post(
    `/api/users/${userId}/students/lessons/${cancelledClassId}/rescheduleCancelledClass`,
    { teacherId, subjectLessonId, teacherTimeSlotId }
  );
};

export const getTeacherCompleteClassCountApi = (userId, teacherId) => {
  return axios.get(
    `/api/users/${userId}/teachers/${teacherId}/completeClassesCount`
  );
};

export const updateTeacherLessonApi = ({
  userId,
  teacherId,
  lessonId,
  formData,
}) => {
  return axios.put(
    `/api/users/${userId}/teachers/${teacherId}/subjects/lessons/${lessonId}`,
    formData
  );
};

export const deleteTeacherLessonApi = ({ userId, teacherId, lessonId }) => {
  return axios.delete(
    `/api/users/${userId}/teachers/${teacherId}/subjects/lessons/${lessonId}`
  );
};

export const updateLessonStatusApi = ({
  userId,
  teacherId,
  lessonId,
  status,
}) => {
  return axios.patch(
    `/api/users/${userId}/teachers/${teacherId}/subjects/lessons/${lessonId}`,
    { action: status }
  );
};

export const classesWithoutReviewApi = (userId) => {
  return axios.get(
    `/api/users/${userId}/students/feedbacks/getClassesWithoutReviews`
  );
};

export const addReasonToTeacherCancelledClassApi = ({
  userId,
  lessonId,
  reason,
  userRole,
}) => {
  return axios.put(
    `/api/users/${userId}/cancelclass/${lessonId}/addReasonByTeacher`,
    {
      reason,
      role: userRole,
    }
  );
};

export const addInstantLessonsApi = ({
  userId,
  paidBy,
  lessonPrice,
  teacherId,
  lessonId,
  processingFee,
}) => {
  return axios.post(`/api/users/${userId}/students/lessons/bookInstantLesson`, {
    paidBy,
    lessonPrice,
    teacherId,
    lessonId,
    processingFee,
  });
};

export const updateInstantLessonsApi = ({
  userId,
  classId,
  payerId,
  paymentId,
}) => {
  return axios.put(`/api/users/${userId}/students/lessons/bookInstantLesson`, {
    classId,
    payerId,
    paymentId,
  });
};

export const getStudentInstantLessonsApi = ({ userId }) => {
  return axios.get(`/api/users/${userId}/students/lessons/bookInstantLesson`);
};

export const getStudentOneInstantLessonsApi = ({ userId, lessonId }) => {
  return axios.get(
    `/api/users/${userId}/students/lessons/instantlesson/${lessonId}`
  );
};

export const getTeachersInstantClassesApi = ({ userId, teacherId }) => {
  return axios.get(`/api/users/${userId}/teachers/${teacherId}/instantLessons`);
};

export const getTeachersOneInstantClassApi = ({
  userId,
  teacherId,
  lessonId,
}) => {
  return axios.get(
    `/api/users/${userId}/teachers/${teacherId}/instantLesson/${lessonId}`
  );
};

export const updateTeachersOneInstantClassApi = ({
  userId,
  teacherId,
  lessonId,
  action,
}) => {
  return axios.put(
    `/api/users/${userId}/teachers/${teacherId}/instantLesson/${lessonId}`,
    {
      action,
    }
  );
};
