import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mapKeys, map } from "lodash";
import {
  getMyTeachersApi,
  getTeacherApi,
  updateWatchVideoApi,
  updateActiveTeacherApi,
} from "../../services/api/apiServices/teachers";
import { getUser } from "./userSlice";

export const getMyTeachers = createAsyncThunk(
  "GET_MY_TEACHERS",
  async ({ userId, sweetAlert }) => {
    try {
      let { data } = await getMyTeachersApi(userId);
      data = mapKeys(data, "id");
      const ids = map(data, "id");
      data = { list: data, ids };
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const getTeacher = createAsyncThunk(
  "GET_TEACHER",
  async ({ userId, teacherId }) => {
    try {
      const { data } = await getTeacherApi({ userId, teacherId });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const updateActiveTeacher = createAsyncThunk(
  "UPDATE_ACTIVE_TEACHER",
  async ({ userId, teacherId, action }) => {
    try {
      const { data } = await updateActiveTeacherApi({
        userId,
        teacherId,
        action,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const updateWatchVideo = createAsyncThunk(
  "UPDATE_WATCH_VIDEO",
  async ({ userId, teacherId }, { dispatch }) => {
    try {
      const { data } = await updateWatchVideoApi({ userId, teacherId });
      dispatch(getUser(userId));
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  teachers: {
    list: null,
    ids: null,
  },
  updateWatchVideo: {
    loading: false,
    error: null,
    response: null,
  },
  recommendedTeachers: null,
  oneTeacher: null,
  isActive: {
    loading: false,
    error: null,
    isActive: false,
    successMessage: null,
  },
};

export const teachersSlice = createSlice({
  name: "teachers",
  initialState,
  reducers: {
    clearSuccessMessage: (state) => {
      state.isActive.successMessage = null;
    },
    clearErrorMessage: (state) => {
      state.isActive.error = null;
    },
  },
  extraReducers: {
    [getMyTeachers.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getMyTeachers.fulfilled]: (state, { payload }) => {
      state.teachers = payload;
      state.loading = false;
      state.error = null;
    },
    [getMyTeachers.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //get teachers
    [getTeacher.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTeacher.fulfilled]: (state, { payload }) => {
      state.oneTeacher = payload;
      state.loading = false;
      state.error = null;
    },
    [getTeacher.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // update watch video
    [updateWatchVideo.pending]: (state) => {
      state.updateWatchVideo.loading = true;
      state.updateWatchVideo.error = null;
    },
    [updateWatchVideo.fulfilled]: (state, { payload }) => {
      state.updateWatchVideo.response = payload;
      state.updateWatchVideo.loading = false;
      state.updateWatchVideo.error = null;
    },
    [updateWatchVideo.rejected]: (state, { error }) => {
      state.updateWatchVideo.loading = false;
      state.updateWatchVideo.error = error.message;
    },
    //UPDATE_ACTIVE_TEACHER
    [updateActiveTeacher.pending]: (state) => {
      state.isActive.loading = true;
      state.isActive.error = null;
    },
    [updateActiveTeacher.fulfilled]: (state, { payload }) => {
      state.isActive.successMessage = payload;
      state.isActive.loading = false;
      state.isActive.error = null;
    },
    [updateActiveTeacher.rejected]: (state, { error }) => {
      state.isActive.loading = false;
      state.isActive.error = error.message;
    },
  },
});

export const { clearSuccessMessage, clearErrorMessage } = teachersSlice.actions;

export const selectTeachers = (state) => state.teachers;
export const selectWatchVideo = (state) => state.teachers.updateWatchVideo;
export const selectIsActive = (state) => state.teachers.isActive;

export default teachersSlice.reducer;
