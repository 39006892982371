import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PAYMENT_METHODS, PAYMENT_WITH } from "../../constants/Constants";
import {
  addInstantLessonsApi,
  getStudentInstantLessonsApi,
  getStudentOneInstantLessonsApi,
  updateInstantLessonsApi,
} from "../../services/api/apiServices/classes";

export const addInstantLessons = createAsyncThunk(
  "ADD_INSTANT_LESSON",
  async ({
    userId,
    paidBy,
    lessonPrice,
    teacherId,
    lessonId,
    sweetAlert,
    navigate,
    subjectName,
    lessonName,
    paypalFee,
  }) => {
    try {
      const { data } = await addInstantLessonsApi({
        userId,
        paidBy,
        lessonPrice,
        teacherId,
        lessonId,
        processingFee: paypalFee,
      });
      if (data.redirectUrl && paidBy === PAYMENT_METHODS.card) {
        window.open(data.redirectUrl, "_blank")?.focus();
      } else {
        const classInfo = {
          subjectName,
          lessonName,
          paypalFee,
          lessonPrice,
          paidBy,
        };
        navigate("pending", { state: classInfo });
      }
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);
export const updateInstantLessons = createAsyncThunk(
  "UPDATE_INSTANT_LESSON",
  async ({ userId, classId, payerId, paymentId, navigate, sweetAlert }) => {
    try {
      const { data } = await updateInstantLessonsApi({
        userId,
        classId,
        payerId,
        paymentId,
      });

      const classInfo = {
        subjectName: data?.lessons?.subject?.title,
        lessonName: data?.lessons?.title,
        paypalFee: data?.processingFee,
        lessonPrice: data?.price,
        grandTotal: data?.price + data?.processingFee,
        paidBy: PAYMENT_WITH.paypal,
        selectedPayment: PAYMENT_WITH.paypal,
      };
      navigate(
        `/user/${data?.fk_student_id}/student/teacher/${data?.fk_teacher_id}/bookInstantLessons/pending`,
        {
          state: classInfo,
          replace: true,
        }
      );
      return data;
    } catch (error) {
      navigate(`/user/${userId}/student/dashboard`);
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

export const getStudentInstantLessons = createAsyncThunk(
  "GET_STUDENT_INSTANT_LESSON",
  async ({ userId, sweetAlert }) => {
    try {
      const { data } = await getStudentInstantLessonsApi({
        userId,
      });
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

export const getStudentOneInstantLessons = createAsyncThunk(
  "GET_STUDENT_ONE_INSTANT_LESSON",
  async ({ userId, lessonId, sweetAlert, navigate }) => {
    try {
      const { data } = await getStudentOneInstantLessonsApi({
        userId,
        lessonId,
      });
      return data;
    } catch (error) {
      navigate(-1);
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  response: null,
  instantLessons: {
    data: [],
    error: null,
    loading: false,
  },
  instantLessonDetails: {
    data: null,
    error: null,
    loading: false,
  },
};

export const instantLessonSlice = createSlice({
  name: "instant_lessons",
  initialState,
  reducers: {},
  extraReducers: {
    [addInstantLessons.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addInstantLessons.fulfilled]: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = null;
    },
    [addInstantLessons.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // get instant lessons
    [getStudentInstantLessons.pending]: (state) => {
      state.instantLessons.loading = true;
      state.instantLessons.error = null;
    },
    [getStudentInstantLessons.fulfilled]: (state, { payload }) => {
      state.instantLessons.data = payload;
      state.instantLessons.loading = false;
      state.instantLessons.error = null;
    },
    [getStudentInstantLessons.rejected]: (state, { error }) => {
      state.instantLessons.loading = false;
      state.instantLessons.error = error.message;
    },
    // get one instant lessons
    [getStudentOneInstantLessons.pending]: (state) => {
      state.instantLessonDetails.loading = true;
      state.instantLessonDetails.error = null;
    },
    [getStudentOneInstantLessons.fulfilled]: (state, { payload }) => {
      state.instantLessonDetails.data = payload;
      state.instantLessonDetails.loading = false;
      state.instantLessonDetails.error = null;
    },
    [getStudentOneInstantLessons.rejected]: (state, { error }) => {
      state.instantLessonDetails.loading = false;
      state.instantLessonDetails.error = error.message;
    },
  },
});

export const {} = instantLessonSlice.actions;

export const selectInstantClasses = (state) => state.instantLessons;
export const selectInstantClassesList = (state) =>
  state.instantLessons.instantLessons;
export const selectOneInstantClass = (state) =>
  state.instantLessons.instantLessonDetails;

export default instantLessonSlice.reducer;
