import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  insertStudyMaterial,
  getStudyMaterial,
} from "../../services/api/apiServices/studyMaterials";

export const studyMaterials = createAsyncThunk(
  "INSERT_STUDY_MATERIALS",
  async ({ userId, classId, teacherId, formData, sweetAlert }) => {
    try {
      const { data } = await insertStudyMaterial({
        userId,
        classId,
        teacherId,
        formData,
      });
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const getStudyMaterials = createAsyncThunk(
  "GET_STUDY_MATERIALS",
  async ({ userId, page, limit, sweetAlert }) => {
    try {
      const { data } = await getStudyMaterial({
        userId,
        page,
        limit,
      });
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  response: null,
  studyMaterials: null,
};

export const studyMaterialsSlice = createSlice({
  name: "studyMaterials",
  initialState,
  reducers: {
    clearResponse: (state) => {
      state.response = null;
    },
  },
  extraReducers: {
    [studyMaterials.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [studyMaterials.fulfilled]: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = null;
    },
    [studyMaterials.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // get study materials
    [getStudyMaterials.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getStudyMaterials.fulfilled]: (state, { payload }) => {
      state.studyMaterials = payload;
      state.loading = false;
      state.error = null;
    },
    [getStudyMaterials.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const { clearResponse } = studyMaterialsSlice.actions;

export const selectStudyMaterials = (state) => state.studyMaterials;
export default studyMaterialsSlice.reducer;
