import axios from "axios";

export const getGroupsApi = ({ userId, search }) => {
  return axios.get(`/api/users/${userId}/community?search=${search}`);
};

export const getSingleGroupApi = ({ userId, clubId }) => {
  return axios.get(`/api/users/${userId}/community/${clubId}/group`);
};

export const createGroupsApi = ({ userId, formData }) => {
  return axios.post(`/api/users/${userId}/community`, formData);
};

export const getRecommendedGroupsApi = ({ userId, search }) => {
  return axios.get(
    `/api/users/${userId}/community/getRecommendedGroup?search=${search}`
  );
};

export const addUserToGroupApi = ({ userId, clubId }) => {
  return axios.get(`/api/users/${userId}/community/${clubId}`);
};

export const removeUserFromGroupApi = ({ userId, clubId }) => {
  return axios.delete(`/api/users/${userId}/community/${clubId}`);
};

export const getPublicGroupsApi = () => {
  return axios.get("/api/publicCommunity");
};

export const getSinglePublicGroupsApi = (groupId) => {
  return axios.get(`/api/publicCommunity/${groupId}/group`);
};

export const deleteGroupApi = ({ userId, clubId }) => {
  return axios.delete(`/api/users/${userId}/community/${clubId}/group`);
};

export const updateGroupApi = ({ userId, clubId, formData }) => {
  return axios.put(`/api/users/${userId}/community/${clubId}/group`, formData);
};
