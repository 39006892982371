import { Button } from "react-bootstrap";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  console.log(error.message);
  return (
    <div role="alert" className="w-100 error-div">
      <div>
        <p className="error-heading">Oops!!</p>
        <p className="text-center h4">Something went wrong...</p>
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => window.location.reload()}
            variant="secondary"
            className="mx-auto my-2 px-5 py-2"
          >
            Refresh
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
