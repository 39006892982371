import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  deleteTeacherSubject,
  updateTeacherSubject,
} from "../../services/api/apiServices/subjects";

import axios from "axios";

export const getTeacherSubjects = createAsyncThunk(
  "GET_TEACHER_SUBJECTS",
  async ({ userId, teacherId }) => {
    try {
      const { data } = await axios.get(
        `/api/users/${userId}/teachers/${teacherId}/subjects`
      );
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const deleteSubject = createAsyncThunk(
  "DELETE_TEACHER_SUBJECTS",
  async ({ userId, teacherId, subjectId }) => {
    try {
      const { data } = await deleteTeacherSubject({
        userId,
        teacherId,
        subjectId,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const updateSubjects = createAsyncThunk(
  "UPDATE_TEACHER_SUBJECTS",
  async ({ userId, teacherId, subjectId, active, sweetAlert }) => {
    try {
      const { data } = await updateTeacherSubject({
        userId,
        teacherId,
        subjectId,
        active,
      });
      sweetAlert({
        title: "Subject Updated!",
      });
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);
const initialState = {
  loading: false,
  error: null,
  teacherData: {},
  deleteSubect: {
    loading: false,
    error: null,
    response: null,
  },
  updateSubject: {
    loading: false,
    error: null,
    response: null,
  },
};

export const TeacherSubjectSlice = createSlice({
  name: "teacherSubjects",
  initialState,
  reducers: {},
  extraReducers: {
    [getTeacherSubjects.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTeacherSubjects.fulfilled]: (state, { payload }) => {
      state.teacherData = payload;
      state.loading = false;
      state.error = null;
    },
    [getTeacherSubjects.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // delete subject
    [deleteSubject.pending]: (state) => {
      state.deleteSubect.loading = true;
      state.deleteSubect.error = null;
    },
    [deleteSubject.fulfilled]: (state, { payload }) => {
      state.deleteSubect.response = payload;
      state.deleteSubect.loading = false;
      state.deleteSubect.error = null;
    },
    [deleteSubject.rejected]: (state, { error }) => {
      state.deleteSubect.loading = false;
      state.deleteSubect.error = error.message;
    },

    // update subject
    [updateSubjects.pending]: (state) => {
      state.updateSubject.loading = true;
      state.updateSubject.error = null;
    },
    [updateSubjects.fulfilled]: (state, { payload }) => {
      state.updateSubject.response = payload;
      state.updateSubject.loading = false;
      state.updateSubject.error = null;
    },
    [updateSubjects.rejected]: (state, { error }) => {
      state.updateSubject.loading = false;
      state.updateSubject.error = error.message;
    },
  },
});

export const {} = TeacherSubjectSlice.actions;

export const selectSubjects = (state) => state.teacherSubjects;
export const selectDeleteSubject = (state) =>
  state.teacherSubjects.deleteSubect;

export const selectUpdateSubject = (state) =>
  state.teacherSubjects.updateSubject;

export default TeacherSubjectSlice.reducer;
