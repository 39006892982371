import axios from "axios";

export const getPostsApi = ({ userId, clubId }) => {
  return axios.get(`/api/users/${userId}/community/${clubId}/posts`);
};

export const createPostsApi = ({ userId, clubId, body }) => {
  return axios.post(`/api/users/${userId}/community/${clubId}/posts`, body);
};

export const getSinglePostApi = ({ userId, postId }) => {
  return axios.get(`/api/users/${userId}/community/${postId}/post`);
};

export const getPublicPostsApi = (postId) => {
  return axios.get(`/api/publicCommunity/${postId}/posts`);
};

export const getSinglePublicPostApi = ({ postId, clubId }) => {
  return axios.get(`/api/publicCommunity/${clubId}/post/${postId}`);
};

export const insertReportPostApi = ({ userId, postId, clubId }) => {
  return axios.get(
    `/api/users/${userId}/community/${clubId}/post/${postId}/report`
  );
};
