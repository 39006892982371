import axios from "axios";

export const login = (obj) => {
  return axios.post(`api/users/login`, obj);
};

export const signUp = ({ email, password }) => {
  return axios.post("/api/users/signup", {
    email,
    password,
  });
};

export const userProfile = (id) => {
  return axios.get(`/api/users/${id}/profile`);
};

export const socialLoginApi = (token) => {
  return axios.get(`api/users/loginWithSocials`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const sendCode = (email) => {
  return axios.post("/api/users/forgetpassword", {
    email,
  });
};

export const resetPassword = ({ email, code, password }) => {
  return axios.post("/api/users/resetpassword", {
    email,
    code,
    password,
  });
};

export const createUserProblemsApi = ({ userId, description, file }) => {
  return axios.post(`/api/users/${userId}/problems`, {
    description,
    file,
  });
};

export const resendCodeAPI = (email) => {
  return axios.post("/api/users/resendemail", {
    email,
  });
};

export const logoutApi = ({ userId, teacherId }) => {
  return axios.put(`/api/users/${userId}/teachers/${teacherId}/logout`);
};

export const refreshAccessTokenApi = (email) => {
  return axios.post(
    "/api/users/refreshtoken",
    {
      email,
    },
    {
      headers: {
        refreshToken: JSON.parse(localStorage.getItem("refreshToken")),
      },
    }
  );
};
