import axios from "axios";

export const getPackagesApi = ({ userId, lessonId }) => {
  return axios.get(`/api/users/${userId}/packages/${lessonId}`);
};

export const getBookedPackageApi = ({ userId, lessonId }) => {
  return axios.get(
    `/api/users/${userId}/packages/${lessonId}/getbookedpackage`
  );
};

export const getStudentBookedPackageApi = (userId) => {
  return axios.get(`/api/users/${userId}/packages`);
};
