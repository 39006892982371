import axios from "axios";

export const getTeacherApplication = ({ userId, teacherId }) => {
  return axios.get(
    `/api/users/${userId}/teachers/${teacherId}/teacherapplication`
  );
};

export const getAdminSubjectsApi = (userId) => {
  return axios.get(`/api/users/${userId}/students/subjects`);
};

export const getTeachersApi = ({
  userId,
  nameSearch,
  type,
  country,
  language,
  topRating,
  rating,
  maxPrice,
  minPrice,
  subjectSearch,
  isActive,
  page,
  limit,
}) => {
  return axios.get(`/api/getTeachers`, {
    params: {
      name: nameSearch,
      type,
      country,
      language,
      topRating,
      rating,
      maxPrice,
      minPrice,
      subject: subjectSearch,
      isActive,
      page,
      limit,
    },
  });
};

export const getRecommendedTeachersApi = (userId) => {
  return axios.get(`/api/${userId}/getRecommendedTeachers`);
};

export const getSubjectsApi = () => {
  return axios.get(`/api/getSubjects`);
};

export const getMyTeachersApi = (id) => {
  return axios.get(`/api/users/${id}/students/myteachers`);
};

export const getTeacherApi = ({ userId, teacherId }) => {
  return axios.get(`/api/users/${userId}/teachers/${teacherId}`);
};

export const updateWatchVideoApi = ({ userId, teacherId }) => {
  return axios.get(`/api/users/${userId}/teachers/${teacherId}/watched_video`);
};

export const updateActiveTeacherApi = ({ userId, teacherId, action }) => {
  return axios.put(`/api/users/${userId}/teachers/${teacherId}/updateTeacher`, {
    isActive: action,
  });
};

export const saveTeacherFormDataApi = ({ userId, formData }) => {
  return axios.post(`/api/users/${userId}/saveFormData`, {
    formData,
  });
};

export const getSaveTeacherFormDataApi = (userId) => {
  return axios.get(`/api/users/${userId}/saveFormData`);
};

export const saveW9FormApi = ({ userId, formData }) => {
  return axios.post(`/api/users/${userId}/w9form`, formData);
};

export const getW9FormApi = (userId) => {
  return axios.get(`/api/users/${userId}/w9form`);
};
