import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isNil } from "lodash";
import moment from "moment";
import {
  login,
  socialLoginApi,
  logoutApi,
  refreshAccessTokenApi,
} from "../../services/api/apiServices/auth";

export const loginUser = createAsyncThunk("LOGIN_USER", async (credentials) => {
  try {
    const { data } = await login(credentials);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
});

export const socialLogin = createAsyncThunk(
  "SOCIAL_LOGIN",
  async ({ token, navigate, sweetAlert }) => {
    try {
      const { data } = await socialLoginApi(token);
      return data;
    } catch (error) {
      navigate("/login");
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

export const logoutThunk = createAsyncThunk(
  "LOGOUT",
  async ({ userId, teacherId, sweetAlert, handleClose, navigate }) => {
    try {
      const { data } = await logoutApi({ userId, teacherId });
      handleClose();
      navigate("/login");
      return data;
    } catch (error) {
      sweetAlert({
        title: "Something went wrong!",
        icon: "error",
      });
      throw error?.response?.data;
    }
  }
);

export const refreshAccessToken = createAsyncThunk(
  "REFRESH_ACCESS_TOKEN",
  async (_, { getState, dispatch }) => {
    const expiryTime = getState().auth.refreshTokenApiTime;
    if (expiryTime < moment().unix() || isNil(expiryTime)) {
      dispatch(assignRefreshTokenExpiration());
      const { email } = getState().user.userDetails.user;
      try {
        const { data } = await refreshAccessTokenApi(email);
        localStorage.setItem("token", JSON.stringify(data.accessToken));
        window.location.reload();
        return data;
      } catch (error) {
        dispatch(logout());
        throw error?.response?.data;
      }
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  authDetails: null,
  refreshTokenApiTime: 0,
  redirectToFindTeachers: false,
  logoutLoader: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state = initialState;
    },
    clearAuth: (state) => {
      state.error = null;
    },
    assignRefreshTokenExpiration: (state) => {
      state.refreshTokenApiTime = moment().add(1, "hour").unix();
    },
    setRedirectToFindTeachers: (state) => {
      state.redirectToFindTeachers = true;
    },
    clearRedirectToFindTeachers: (state) => {
      state.redirectToFindTeachers = false;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.authDetails = {
        userId: payload?.id,
        token: payload?.token,
        refreshToken: payload?.refreshToken,
        onboarded: payload?.onboarded,
      };
      state.loading = false;
      state.error = null;
    },
    [loginUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //Social Login
    [socialLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [socialLogin.fulfilled]: (state, { payload }) => {
      state.authDetails = {
        userId: payload?.id,
        token: payload?.token,
        onboarded: payload?.onboarded,
      };
      state.loading = false;
      state.error = null;
    },
    [socialLogin.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // refresh access token
    [refreshAccessToken.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [refreshAccessToken.fulfilled]: (state, { payload }) => {
      state.authDetails = {
        ...state.authDetails,
        token: payload?.accessToken,
      };
      state.loading = false;
      state.error = null;
    },
    [refreshAccessToken.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // logout loader
    [logoutThunk.pending]: (state) => {
      state.logoutLoader = true;
    },
    [logoutThunk.fulfilled]: (state) => {
      state.logoutLoader = false;
    },
    [logoutThunk.rejected]: (state, { error }) => {
      state.logoutLoader = false;
    },
  },
});

export const {
  addAuth,
  clearAuth,
  logout,
  assignRefreshTokenExpiration,
  setRedirectToFindTeachers,
  clearRedirectToFindTeachers,
} = authSlice.actions;

export const selectAuth = (state) => state.auth;
export const selectRedirectToFindTeachers = (state) =>
  state.auth.redirectToFindTeachers;

export default authSlice.reducer;
