import axios from "axios";

export const getTeachersSubjectsApi = ({ userId, teacherId }) => {
  return axios.get(
    `/api/users/${userId}/students/teachers/${teacherId}/subjects`
  );
};

export const deleteTeacherSubject = ({ userId, teacherId, subjectId }) => {
  return axios.delete(
    `/api/users/${userId}/teachers/${teacherId}/subjects/${subjectId}`
  );
};

export const updateTeacherSubject = ({
  userId,
  teacherId,
  subjectId,
  active,
}) => {
  return axios.put(
    `/api/users/${userId}/teachers/${teacherId}/subjects/${subjectId}?active=${active}`
  );
};
