import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

//loader
import FullScreenLoader from "../Components/FullScreenLoader";

//components
const StudentsDashboard = lazy(() => import("../pages/StudentsDashboard"));
const StudentLessons = lazy(() => import("../pages/StudentsDashboard/Lessons"));
const StudentHomework = lazy(() =>
  import("../pages/StudentsDashboard/StudentHomework")
);
const BookLesson = lazy(() => import("../pages/BookLesson"));
const PaymentStatus = lazy(() =>
  import("../pages/BookLesson/Component/PaymentStatus")
);
const Payment = lazy(() => import("../pages/BookLesson/Component/PaymentCard"));
const PendingLessons = lazy(() => import("../pages/lessons/PendingLessons"));
const CancelledLessons = lazy(() =>
  import("../pages/lessons/CancelledLessons")
);
const UpcomingLessons = lazy(() => import("../pages/lessons/UpcomingLessons"));
const WaitingLessons = lazy(() => import("../pages/lessons/WaitingLessons"));
const CompletedLessons = lazy(() =>
  import("../pages/lessons/CompletedLessons")
);
const StartDispute = lazy(() => import("../pages/disputeCenter/StartDispute"));
const Disputedetail = lazy(() =>
  import("../pages/disputeCenter/Disputedetail")
);
const BookedPackages = lazy(() => import("../pages/StudentBookedPackages"));
const StudentWallet = lazy(() => import("../pages/StudentWallet"));
const InstantLessons = lazy(() => import("../pages/InstantLessons"));
const InstantLessonPaymentStatus = lazy(() =>
  import("../pages/InstantLessons/Components/PaymentStatus")
);
const InstantLessonDetails = lazy(() =>
  import("../pages/lessons/InstantLessonDetails")
);
const Error = lazy(() => import("../pages/Error"));

const StudentNavigator = () => {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        <Route path="dashboard" element={<StudentsDashboard />} />
        <Route path="wallet" element={<StudentWallet />} />

        <Route path="lessons">
          <Route path=":filter" element={<StudentLessons />} />
          <Route path="" element={<StudentLessons />} />
        </Route>

        <Route path="/homework" element={<StudentHomework />} />
        <Route path="/teacher/:teacherId/booklesson" element={<BookLesson />} />
        <Route
          path="/teacher/:teacherId/booklesson/status"
          element={<PaymentStatus />}
        />
        <Route
          path="/teacher/:teacherId/booklesson/paymentongoing"
          element={<Payment />}
        />
        <Route path=":classId/pendingLessons" element={<PendingLessons />} />
        <Route path=":classId/upcomingLessons" element={<UpcomingLessons />} />
        <Route path=":classId/waitingLessons" element={<WaitingLessons />} />
        <Route
          path=":classId/cancelledLessons"
          element={<CancelledLessons />}
        />
        <Route
          path=":classId/completedLessons"
          element={<CompletedLessons />}
        />

        <Route
          path=":classId/instantClass"
          element={<InstantLessonDetails />}
        />

        <Route path="disputes/:classId" element={<StartDispute />} />
        <Route path="disputeReceived/:disputeId" element={<Disputedetail />} />
        <Route path="bookedpackages" element={<BookedPackages />} />
        <Route path="*" element={<Error />} />
        <Route
          path="teacher/:teacherId/bookInstantLessons"
          element={<InstantLessons />}
        />
        <Route
          path="teacher/:teacherId/bookInstantLessons/pending"
          element={<InstantLessonPaymentStatus />}
        />
      </Routes>
    </Suspense>
  );
};

export default StudentNavigator;
