import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTeacherApplication,
  getAdminSubjectsApi,
  saveTeacherFormDataApi,
  getSaveTeacherFormDataApi,
  saveW9FormApi,
  getW9FormApi,
} from "../../services/api/apiServices/teachers";

export const getTeacherApplicationData = createAsyncThunk(
  "GET_TEACHER_APPLICATION",
  async ({ userId, teacherId }) => {
    try {
      const { data } = await getTeacherApplication({
        userId,
        teacherId,
      });
      const formData = {
        displayName: data?.displayName,
        fromCountry: data?.fromCountry,
        fromCity: data?.fromCity,
        livingCity: data?.livingCity,
        livingCountry: data?.livingCountry,
        meetingData: data?.user?.meetings,
        languages: data?.user?.languages,
        aboutMe: data?.aboutMe,
        aboutMeAsTeacher: data?.aboutMeAsTeacher,
        aboutTeachingStyle: data?.aboutTeachingStyle,
        userEducations: data?.user?.userEducations,
        userCertifications: data?.user?.userCertifications,
        userExperiences: data?.user?.userExperiences,
        subjects: data?.user?.subjects,
        introVideo: data?.intro_video,
        paypalEmail: data?.paypal_email,
        livingState: data?.fromState,
        fromState: data?.livingState,
      };
      return formData;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
export const getAdminSubjects = createAsyncThunk(
  "GET_ADMIN_SUBJECTS",
  async (userId) => {
    try {
      const { data } = await getAdminSubjectsApi(userId);
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const saveTeacherFormData = createAsyncThunk(
  "SAVE_TEACHER_FORM_DATA",
  async ({ userId, formData, sweetAlert }, { dispatch }) => {
    try {
      const { data } = await saveTeacherFormDataApi({ userId, formData });
      sweetAlert({
        title: "Form Data Saved",
        text: "",
      });
      dispatch(getSaveTeacherFormData({ userId }));
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        text: "",
      });
      throw error?.response?.data;
    }
  }
);

export const getSaveTeacherFormData = createAsyncThunk(
  "GET_SAVE_TEACHER_FORM_DATA",
  async ({ userId }) => {
    try {
      const { data } = await getSaveTeacherFormDataApi(userId);
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const saveW9Form = createAsyncThunk(
  "SAVE_W9FORM",
  async ({ userId, formData, sweetAlert, navigate }) => {
    try {
      const { data } = await saveW9FormApi({ userId, formData });
      return data;
    } catch (error) {
      sweetAlert({
        title: error?.response?.data?.message,
        icon: "error",
      });
      navigate(-1);
      throw error?.response?.data;
    }
  }
);

export const getW9Form = createAsyncThunk(
  "GET_W9FORM",
  async ({ userId, sweetAlert }) => {
    try {
      const { data } = await getW9FormApi(userId);
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

const initialState = {
  type: null,
  formData: null,
  adminSubjects: {
    error: null,
    loading: false,
    subjects: null,
  },
  saveFormData: {
    error: null,
    loading: false,
    response: null,
  },
  w9Form: {
    error: null,
    loading: false,
    response: null,
  },
  w9FormData: {
    error: null,
    loading: false,
    w9Form: null,
  },
  loading: false,
  error: null,
};

export const becomeTeacherSlice = createSlice({
  name: "becomeTeacher",
  initialState,
  reducers: {
    setTeacherType: (state, action) => {
      state.type = action.payload;
    },
    setForm: (state, action) => {
      state.formData = action.payload;
    },
    clearFormData: (state) => {
      state = initialState;
    },
  },
  extraReducers: {
    [getTeacherApplicationData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTeacherApplicationData.fulfilled]: (state, { payload }) => {
      state.formData = payload;
      state.loading = false;
      state.error = null;
    },
    [getTeacherApplicationData.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // get admin subjects
    [getAdminSubjects.pending]: (state) => {
      state.adminSubjects.loading = true;
      state.adminSubjects.error = null;
    },
    [getAdminSubjects.fulfilled]: (state, { payload }) => {
      state.adminSubjects.subjects = payload;
      state.adminSubjects.loading = false;
      state.adminSubjects.error = null;
    },
    [getAdminSubjects.rejected]: (state, { error }) => {
      state.adminSubjects.loading = false;
      state.adminSubjects.error = error.message;
    },
    // save Form Data
    [saveTeacherFormData.pending]: (state) => {
      state.saveFormData.loading = true;
      state.saveFormData.error = null;
    },
    [saveTeacherFormData.fulfilled]: (state, { payload }) => {
      state.saveFormData.response = payload;
      state.saveFormData.loading = false;
      state.saveFormData.error = null;
    },
    [saveTeacherFormData.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // get saved Form Data
    [getSaveTeacherFormData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSaveTeacherFormData.fulfilled]: (state, { payload }) => {
      state.formData = payload;
      state.loading = false;
      state.error = null;
    },
    [getSaveTeacherFormData.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // get Form Data
    [getW9Form.pending]: (state) => {
      state.w9FormData.loading = true;
      state.w9FormData.error = null;
    },
    [getW9Form.fulfilled]: (state, { payload }) => {
      state.w9FormData.w9Form = payload.data;
      state.w9FormData.loading = false;
      state.w9FormData.error = null;
    },
    [getW9Form.rejected]: (state, { error }) => {
      state.w9FormData.loading = false;
      state.w9FormData.error = error.message;
    },
  },
});

export const { setForm, setTeacherType, clearFormData } =
  becomeTeacherSlice.actions;

export const selectTeacherForm = (state) => state.becomeTeacher.formData;
export const selectTeacherFormLoader = (state) => state.becomeTeacher.loading;
export const selectTeacherType = (state) => state.becomeTeacher.type;
export const selectAdminSubjects = (state) => state.becomeTeacher.adminSubjects;
export const selectSaveForm = (state) => state.becomeTeacher.saveFormData;
export const selectw9Form = (state) => state.becomeTeacher.w9Form;
export const selectw9FormData = (state) => state.becomeTeacher.w9FormData;

export default becomeTeacherSlice.reducer;
