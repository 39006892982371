import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { resendCodeAPI } from "../../services/api/apiServices/auth";

export const resendCode = createAsyncThunk("RESEND_CODE", async (email) => {
  try {
    let { data } = await resendCodeAPI(email);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
});

const initialState = {
  loading: false,
  error: null,
  response: null,
};

export const resendCodeSlice = createSlice({
  name: "resend_code",
  initialState,
  reducers: {
    clearResponse: (state) => {
      state.response = null;
    },
  },
  extraReducers: {
    [resendCode.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [resendCode.fulfilled]: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = null;
    },
    [resendCode.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const { clearResponse } = resendCodeSlice.actions;

export const selectCodeResponse = (state) => state.resendCode;

export default resendCodeSlice.reducer;
