import { store } from "../../Store/store";
import { logout, refreshAccessToken } from "../../Store/Features/authSlice";

const errorResponseHandler = (error) => {
  if (error.response.data.message === "jwt expired") {
    store.dispatch(refreshAccessToken());
  } else if (
    error.response.data.message === "Not a valid JWT token" ||
    error.response.status === 401 ||
    error.response.data.message === "Unauthorized"
  ) {
    store.dispatch(logout());
  }
  return Promise.reject(error);
};

export default errorResponseHandler;
