import axios from "axios";

export const insertStudyMaterial = ({
  userId,
  classId,
  teacherId,
  formData,
}) => {
  return axios.post(
    `/api/users/${userId}/teachers/${teacherId}/studyMaterials/${classId}`,
    formData
  );
};

export const getStudyMaterial = ({ userId, page, limit }) => {
  return axios.get(
    `/api/users/${userId}/students/studyMaterials?page=${page}&limit=${limit}`
  );
};
