import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addClassesApi,
  cancelClassApi,
  getTeacherCancelClassApi,
  getStudentCancelClassApi,
  getStudentOneCancelClassApi,
  getTeacherOneCancelClassApi,
  rescheduleCancelledClass,
  getTeacherCompleteClassCountApi,
  addReasonToTeacherCancelledClassApi,
} from "../../services/api/apiServices/classes";
import { getStudentBookedPackage } from "./packagesSlice";

export const addClasses = createAsyncThunk(
  "ADD_CLASSES",
  async (
    {
      userId,
      teacherId,
      slotId,
      lessonId,
      paypalId,
      paymentStatus,
      price,
      packages,
      paidBy,
      packageId,
      sweetAlert,
      paypalFee,
      navigate,
      classInfo,
      paymentData,
    },
    { dispatch }
  ) => {
    try {
      const { data } = await addClassesApi({
        userId,
        teacherId,
        slotId,
        lessonId,
        paypalId,
        paymentStatus,
        price,
        packages,
        paidBy,
        packageId,
        paypalFee,
      });
      dispatch(getStudentBookedPackage({ userId }));
      navigate(
        `/user/${classInfo?.userId}/student/teacher/${classInfo?.teacherId}/booklesson/status`,
        {
          replace: true,
          state: { classInfo, paymentData },
        }
      );
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

export const cancelClass = createAsyncThunk(
  "ADD_CANCELLED_CLASSES",
  async ({ userId, lessonId, userRole, sweetAlert, navigate }) => {
    try {
      const { data } = await cancelClassApi({
        userId,
        lessonId,
        userRole,
      });
      navigate(`/user/${userId}/teacher/cancelledlessons/${data?.data?.id}`);
      sweetAlert({ title: "Class cancelled successfully!" });
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data, icon: "error" });
      throw error?.response?.data;
    }
  }
);

export const getTeacherCancelledClasses = createAsyncThunk(
  "GET_CANCELLED_CLASSES",
  async ({ userId, teacherId, page, limit }) => {
    try {
      let { data } = await getTeacherCancelClassApi({
        userId,
        teacherId,
        page,
        limit,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getStudentCancelledClasses = createAsyncThunk(
  "GET_CANCELLED_CLASSES",
  async ({ userId, sweetAlert, page = 1, limit = 100 }) => {
    try {
      let { data } = await getStudentCancelClassApi({ userId, page, limit });
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

export const getStudentOneCancelledClasses = createAsyncThunk(
  "GET_ONE_CANCELLED_CLASSES",
  async ({ userId, cancelledClassId, sweetAlert }) => {
    try {
      const { data } = await getStudentOneCancelClassApi({
        userId,
        cancelledClassId,
      });
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

export const getTeacherOneCancelledClasses = createAsyncThunk(
  "GET_TEACHER_CANCELLED_CLASS",
  async ({ userId, teacherId, cancelledClassId }) => {
    try {
      const { data } = await getTeacherOneCancelClassApi({
        userId,
        teacherId,
        cancelledClassId,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const rescheduleCancelledClassThunk = createAsyncThunk(
  "RESCHEDULED_CLASS",
  async ({
    userId,
    cancelledClassId,
    teacherId,
    subjectLessonId,
    teacherTimeSlotId,
  }) => {
    try {
      const { data } = await rescheduleCancelledClass({
        userId,
        cancelledClassId,
        teacherId,
        subjectLessonId,
        teacherTimeSlotId,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getCompletedClaasesCount = createAsyncThunk(
  "GET_COMPLETED_CLASSES",
  async ({ userId, teacherId }) => {
    try {
      const { data } = await getTeacherCompleteClassCountApi(userId, teacherId);
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const addReasonToTeacherCancelledClass = createAsyncThunk(
  "ADD_REASON_TO_CANCELLED_CLASS",
  async (
    {
      userId,
      lessonId,
      reason,
      userRole,
      teacherId,
      setToggleModal,
      sweetAlert,
    },
    { dispatch }
  ) => {
    try {
      const { data } = await addReasonToTeacherCancelledClassApi({
        userId,
        lessonId,
        reason,
        userRole,
      });
      setToggleModal(false);
      dispatch(
        getTeacherOneCancelledClasses({
          userId,
          teacherId,
          cancelledClassId: lessonId,
        })
      );
      return data;
    } catch (error) {
      sweetAlert({ title: error?.response?.data?.message, icon: "error" });
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  sucesssMessage: null,
  cancelledClasses: {
    loading: false,
    error: null,
    data: [],
  },
  oneCancelledClasses: {
    loading: false,
    error: null,
    data: null,
  },
  completeClassesCount: {
    loading: false,
    error: null,
    data: null,
  },
  cancelledClassReason: {
    loading: false,
  },
};

export const classesSlice = createSlice({
  name: "classesSlice",
  initialState,
  reducers: {
    clearSuccessMessage: (state) => {
      state.sucesssMessage = null;
    },
    clearCancelledClasses: (state) => {
      state.cancelledClasses = {
        loading: false,
        error: null,
        data: [],
      };
    },
  },
  extraReducers: {
    [addClasses.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addClasses.fulfilled]: (state, { payload }) => {
      state.sucesssMessage = payload;
      state.loading = false;
      state.error = null;
    },
    [addClasses.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //Cancel class
    [cancelClass.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [cancelClass.fulfilled]: (state, { payload }) => {
      state.sucesssMessage = payload;
      state.loading = false;
      state.error = null;
    },
    [cancelClass.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //Get Teacher Cancel class
    [getTeacherCancelledClasses.pending]: (state) => {
      state.cancelledClasses.loading = true;
      state.cancelledClasses.error = null;
    },
    [getTeacherCancelledClasses.fulfilled]: (state, { payload }) => {
      state.cancelledClasses.data = payload;
      state.cancelledClasses.loading = false;
      state.cancelledClasses.error = null;
    },
    [getTeacherCancelledClasses.rejected]: (state, { error }) => {
      state.cancelledClasses.loading = false;
      state.cancelledClasses.error = error.message;
    },
    //Get Student Cancel class
    [getStudentCancelledClasses.pending]: (state) => {
      state.cancelledClasses.loading = true;
      state.cancelledClasses.error = null;
    },
    [getStudentCancelledClasses.fulfilled]: (state, { payload }) => {
      state.cancelledClasses.data = payload;
      state.cancelledClasses.loading = false;
      state.cancelledClasses.error = null;
    },
    [getStudentCancelledClasses.rejected]: (state, { error }) => {
      state.cancelledClasses.loading = false;
      state.cancelledClasses.error = error.message;
    },
    //Get Student One Cancel class
    [getStudentOneCancelledClasses.pending]: (state) => {
      state.oneCancelledClasses.loading = true;
      state.oneCancelledClasses.error = null;
    },
    [getStudentOneCancelledClasses.fulfilled]: (state, { payload }) => {
      state.oneCancelledClasses.data = payload;
      state.oneCancelledClasses.loading = false;
      state.oneCancelledClasses.error = null;
    },
    [getStudentOneCancelledClasses.rejected]: (state, { error }) => {
      state.oneCancelledClasses.loading = false;
      state.oneCancelledClasses.error = error.message;
    },
    //Get Teacher One Cancel class
    [getTeacherOneCancelledClasses.pending]: (state) => {
      state.oneCancelledClasses.loading = true;
      state.oneCancelledClasses.error = null;
    },
    [getTeacherOneCancelledClasses.fulfilled]: (state, { payload }) => {
      state.oneCancelledClasses.data = payload;
      state.oneCancelledClasses.loading = false;
      state.oneCancelledClasses.error = null;
    },
    [getTeacherOneCancelledClasses.rejected]: (state, { error }) => {
      state.oneCancelledClasses.loading = false;
      state.oneCancelledClasses.error = error.message;
    },
    //Rescheduled class
    [rescheduleCancelledClassThunk.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [rescheduleCancelledClassThunk.fulfilled]: (state, { payload }) => {
      state.sucesssMessage = payload;
      state.loading = false;
      state.error = null;
    },
    [rescheduleCancelledClassThunk.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    //Get teacher complete classes count
    [getCompletedClaasesCount.pending]: (state) => {
      state.completeClassesCount.loading = true;
      state.completeClassesCount.error = null;
    },
    [getCompletedClaasesCount.fulfilled]: (state, { payload }) => {
      state.completeClassesCount.data = payload;
      state.completeClassesCount.loading = false;
      state.completeClassesCount.error = null;
    },
    [getCompletedClaasesCount.rejected]: (state, { error }) => {
      state.completeClassesCount.loading = false;
      state.completeClassesCount.error = error.message;
    },
    //add teacher cancelled classes
    [addReasonToTeacherCancelledClass.pending]: (state) => {
      state.cancelledClassReason.loading = true;
    },
    [addReasonToTeacherCancelledClass.fulfilled]: (state) => {
      state.cancelledClassReason.loading = false;
    },
    [addReasonToTeacherCancelledClass.rejected]: (state, { error }) => {
      state.cancelledClassReason.loading = false;
    },
  },
});

export const { clearSuccessMessage, clearCancelledClasses } =
  classesSlice.actions;

export const selectClasses = (state) => state.classes;
export const selectCancelledClasses = (state) => state.classes.cancelledClasses;
export const selectOneCancelledClasses = (state) =>
  state.classes.oneCancelledClasses;
export const selectCompleteClassesCount = (state) =>
  state.classes.completeClassesCount;
export const selectAddCancelledClassLoader = (state) =>
  state.classes.cancelledClassReason.loading;

export default classesSlice.reducer;
