import { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { styles } from "../styles/styles";
import "../styles/styles.css";
import {
  LANGUAGES,
  USER_ROLES,
  STATUS,
  COLORS,
  NOTIFICATION_TYPE,
} from "../constants/Constants";
import NavDropdown from "react-bootstrap/NavDropdown";
import DownIcon from "../assets/icons/keyboard_arrow_down_black_24dp.svg";
import SelectComponent from "./Select";

//React-router
import { useNavigate } from "react-router-dom";

//SVG
import Dp from "../assets/dp2.svg";
import FemaleProfile from "../assets/femaleProfile.png";
import MaleProfile from "../assets/maleProfile.png";
import Laptop from "../assets/icons/laptop.svg";
import Calender from "../assets/icons/calender.svg";
import Money from "../assets/icons/money.svg";
import Book from "../assets/icons/Book.svg";
import Settings from "../assets/icons/settings.svg";
import Switch from "../assets/Book.png";
import Logout from "../assets/icons/logout.svg";
import Bell from "../assets/icons/bell.png";
import RiseLogo from "../assets/riselogo.svg";

//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  selectActiveRole,
  selectRole,
  updateActiveRole,
  selectUserId,
  selectTeacherStatus,
  selectTeacher,
} from "../Store/Features/userSlice";
import { logout, logoutThunk, selectAuth } from "../Store/Features/authSlice";
import { isEmpty } from "lodash";

//i18next
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { stringToLiteral, sweetAlert } from "../utils";
import {
  getNotifications,
  selectNotifications,
  updateNotification,
} from "../Store/Features/notifications";
import moment from "moment";

const NavigationBar = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { user } = useSelector(selectUser);
  const teacher = useSelector(selectTeacher);
  const userId = useSelector(selectUserId);
  const activeRole = useSelector(selectActiveRole);
  const teacherStatus = useSelector(selectTeacherStatus);
  const role = useSelector(selectRole);
  const { logoutLoader } = useSelector(selectAuth);
  const { notifications, notificationsCount } =
    useSelector(selectNotifications);

  const [showModal, setShowModal] = useState(false);
  const [student, setStudent] = useState(null);

  let navigate = useNavigate();

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || LANGUAGES[0].lang
  );

  const selectedLanguage = (e) => {
    const langCode = e.value;
    const lang = e.label;
    setLanguage(lang);
    i18next.changeLanguage(langCode);
    localStorage.setItem("languageCode", langCode);
    localStorage.setItem("language", lang);
  };

  const teacherDropdownItems = {
    dashboard: {
      icon: Laptop,
      title: "dashboard",
      isVisible: true,
    },
    profile: {
      icon: Dp,
      title: "profile",
      isVisible: true,
    },
    lesson: { icon: Book, title: "lessons_management", isVisible: true },
    availability: { icon: Calender, title: "availability", isVisible: true },
    wallet: { icon: Money, title: "wallet", isVisible: true },
    switchProfile: {
      icon: Switch,
      title: "switch_to_student",
      isVisible: true,
    },
    settings: { icon: Settings, title: "settings", isVisible: true },
    logOut: { icon: Logout, title: "log_out", isVisible: true },
  };

  const PendingTeacherDropdown = {
    profile: {
      icon: Laptop,
      title: "dashboard",
      isVisible: true,
    },
    switchProfile: {
      icon: Switch,
      title: "switch_to_student",
      isVisible: true,
    },
    logOut: { icon: Logout, title: "log_out", isVisible: true },
  };

  const studentDropDownItems = {
    profile: {
      icon: Laptop,
      title: "dashboard",
      isVisible: true,
    },
    lessons: { icon: Book, title: "lessons", isVisible: true },
    wallet: { icon: Money, title: "wallet", isVisible: true },
    switchProfile: {
      icon: Switch,
      title: "switch_to_teacher",
      isVisible: role === USER_ROLES.teacher,
    },
    settings: { icon: Settings, title: "settings", isVisible: true },
    logOut: { icon: Logout, title: "log_out", isVisible: true },
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    setStudent(activeRole);
  }, [activeRole]);

  const navigateStudent = (path) => {
    navigate(`/user/${userId}/student${path}`);
  };
  const navigateTeacher = (path) => {
    navigate(`/user/${userId}/teacher${path}`);
  };

  const isLogin = !isEmpty(stringToLiteral(localStorage.getItem("token")));

  const teacherDispatchAndNavigate = () => {
    dispatch(updateActiveRole(USER_ROLES.student));
    navigateStudent("/dashboard");
  };
  const studentDispatchAndNavigate = () => {
    dispatch(updateActiveRole(USER_ROLES.teacher));
    navigateTeacher("/dashboard");
  };

  const navigationTeacherEvent = (title) => {
    const {
      switchProfile,
      lesson,
      availability,
      settings,
      logOut,
      profile,
      wallet,
      dashboard,
    } = teacherDropdownItems;
    // eslint-disable-next-line default-case
    switch (title) {
      case switchProfile.title:
        teacherDispatchAndNavigate();
        break;
      case profile.title:
        navigate(`/teacher/${teacher?.id}/profile`);
        break;
      case dashboard.title:
        navigateTeacher("/dashboard");
        break;
      case lesson.title:
        navigateTeacher("/lessonsmanagement");
        break;
      case availability.title:
        navigateTeacher("/availability");
        break;
      case settings.title:
        navigate(`/user/${userId}/teacher/settings`);
        break;
      case wallet.title:
        navigate(`/user/${userId}/teacher/wallet`);
        break;
      case logOut.title:
        handleShow();
        break;
    }
  };

  const navigationStudentEvent = (title) => {
    const { switchProfile, lessons, logOut, profile, settings, wallet } =
      studentDropDownItems;
    // eslint-disable-next-line default-case
    switch (title) {
      case switchProfile.title:
        studentDispatchAndNavigate();
        break;
      case profile.title:
        navigateStudent("/dashboard");
        break;
      case lessons.title:
        navigateStudent("/lessons");
        break;
      case wallet.title:
        navigateStudent("/wallet");
        break;
      case settings.title:
        navigate(`/user/${userId}/settings`);
        break;
      case logOut.title:
        handleShow();
        break;
    }
  };

  const logoutEvent = () => {
    if (isLogin && !isEmpty(user) && role === USER_ROLES.teacher) {
      dispatch(
        logoutThunk({
          userId,
          teacherId: teacher?.id,
          sweetAlert,
          handleClose,
          navigate,
        })
      );
    } else {
      dispatch(logout());
      handleClose();
      navigate("/login", { replace: true });
    }
  };

  const handleNavigateToHome = () => {
    if (isLogin && isEmpty(user)) {
      if (window.confirm("If you leave onboarding, you'll be logout!")) {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  const handleNavigateToFindTeacher = () => {
    if (isLogin && isEmpty(user)) {
      if (window.confirm("If you leave onboarding, you'll be logout!")) {
        navigate("/findteachers");
      }
    } else {
      navigate("/findteachers");
    }
  };

  useEffect(() => {
    if (isLogin && !isEmpty(user)) {
      dispatch(getNotifications({ userId }));
    }
  }, [dispatch, isLogin, user, userId]);

  useEffect(() => {
    if (isLogin && !isEmpty(user)) {
      setInterval(() => {
        dispatch(getNotifications({ userId }));
      }, 120000);
    }
    return () => {
      clearInterval();
    };
  }, [dispatch, isLogin, user, userId]);

  const handleClickNotification = (
    id,
    isRead,
    info,
    notificationRole,
    type
  ) => {
    const notificationInfo = JSON.parse(info);
    const currentTime = moment().unix();

    if (notificationRole === activeRole) {
      if (!isRead) {
        dispatch(updateNotification({ userId, id }));
      }
      if (type === NOTIFICATION_TYPE.classes) {
        if (notificationRole === USER_ROLES.student) {
          if (notificationInfo?.classStatus === STATUS.accepted) {
            if (notificationInfo?.classTiming > currentTime) {
              navigate(
                `/user/${userId}/student/${notificationInfo?.classId}/upcomingLessons`
              );
            } else {
              navigate(
                `/user/${userId}/student/${notificationInfo?.classId}/waitingLessons`
              );
            }
          } else if (notificationInfo?.classStatus === STATUS.cancelled) {
            navigate(
              `/user/${userId}/student/${notificationInfo?.classId}/cancelledLessons`
            );
          }
        } else {
          if (notificationInfo?.instantLesson) {
            navigate(
              `/user/${userId}/teacher/instantLesson/${notificationInfo?.requestId}`
            );
          }

          if (JSON.parse(info)?.classStatus === STATUS.pending) {
            navigate(
              `/user/${userId}/teacher/waitinglesson/${notificationInfo?.classId}`
            );
          } else if (notificationInfo?.classStatus === STATUS.cancelled) {
            navigate(
              `/user/${userId}/teacher/cancelledlessons/${notificationInfo?.classId}`
            );
          } else if (notificationInfo?.classStatus === STATUS.accepted) {
            navigate(
              `/user/${userId}/teacher/needactionlesson/${notificationInfo?.classId}`
            );
          } else if (notificationInfo?.classStatus === STATUS.cancelled) {
            navigate(
              `/user/${userId}/teacher/cancelledlessons/${notificationInfo?.classId}`
            );
          }
        }
      } else if (type === NOTIFICATION_TYPE.dispute) {
        navigate(
          `/user/${userId}/teacher/disputeReceived/${notificationInfo?.disputeId}`
        );
      }
    } else {
      sweetAlert({
        title: "Switch the role to read the notification",
        icon: "info",
      });
    }
  };

  const handleNavigateToNotifications = () => {
    navigate(`/user/${userId}/notifications`);
  };

  const handleBecomeTeacherNavigation = () => {
    if (isLogin) {
      if (!isEmpty(user)) {
        navigate("/becometeacher");
      } else {
        sweetAlert({
          title: "Fill the signup onboarding",
          icon: "warning",
        });
      }
    } else {
      navigate("/becometeacher");
    }
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" style={styles.NavbarStyle}>
        <Container fluid>
          <Navbar.Brand onClick={handleNavigateToHome}>
            <img
              src={RiseLogo}
              style={styles.NavbarLogo}
              className="d-inline-block align-top"
              alt="Rise logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto align-items-center CollapsedNavbar">
              <NavDropdown
                title={
                  <>
                    <span style={styles.navbarFont}>{language}</span>
                    <img
                      src={DownIcon}
                      alt="downIcon"
                      style={styles.DownIconStyle}
                    />
                  </>
                }
                id="collasible-nav-dropdown"
                className="mx-3"
              >
                <div style={styles.dropdownMenu}>
                  <label style={styles.dropdownLabel}>Language</label>
                  <SelectComponent
                    onChange={selectedLanguage}
                    options={LANGUAGES}
                    childKey={"lang"}
                    valueKey={"code"}
                    value={{ value: language, label: language }}
                    height={20}
                    className="mt-1"
                  />
                </div>
              </NavDropdown>
              <Nav.Link className="mx-3" onClick={handleNavigateToFindTeacher}>
                <span style={styles.navbarFont}>{t("find_a_teacher")}</span>
              </Nav.Link>
              {isLogin && !user ? null : (
                <Nav.Link className="mx-3">
                  <span
                    style={styles.navbarFont}
                    onClick={() => navigate("/community")}
                  >
                    {t("community")}
                  </span>
                </Nav.Link>
              )}

              {isLogin && user ? (
                <Nav.Link className="mx-3">
                  <span
                    style={styles.navbarFont}
                    onClick={() => navigate(`/user/${userId}/messages`)}
                  >
                    {t("message")}
                  </span>
                </Nav.Link>
              ) : null}

              {role !== USER_ROLES.teacher && (
                <Nav.Link className="mx-3">
                  <span
                    style={styles.navbarFont}
                    onClick={handleBecomeTeacherNavigation}
                  >
                    {t("become_a_teacher")}
                  </span>
                </Nav.Link>
              )}

              {isLogin && user && (
                <Nav.Link className="mx-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="inherit"
                      id="dropdown-autoclose-outside"
                      style={styles.profileDropDownToogle}
                      className="p-0"
                    >
                      <div className="notification" style={styles.navbarFont}>
                        <img
                          src={Bell}
                          alt="bell"
                          style={styles.notificationIcon}
                        />
                        <span className="badge">{notificationsCount || 0}</span>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        marginLeft: "-100px",
                      }}
                    >
                      {isEmpty(notifications) ? (
                        <div className="text-center">
                          <small>No notifications</small>
                        </div>
                      ) : (
                        notifications.map(
                          (
                            {
                              createdAt,
                              info,
                              isRead,
                              id,
                              role: notificationRole,
                              type,
                            },
                            idx
                          ) => {
                            return (
                              <div key={idx}>
                                <Dropdown.Divider
                                  style={styles.dividerWidth}
                                  className={`mx-auto ${
                                    idx === 0 ? "d-none" : "d-block"
                                  }`}
                                />
                                <Dropdown.Item className="py-0">
                                  <div
                                    key={idx}
                                    onClick={() =>
                                      handleClickNotification(
                                        id,
                                        isRead,
                                        info,
                                        notificationRole,
                                        type
                                      )
                                    }
                                    className="d-flex"
                                  >
                                    <div
                                      style={{
                                        ...styles.notificationDot,
                                        background: !isRead
                                          ? COLORS.DARKPRIMARY
                                          : COLORS.TRANSPARENT,
                                      }}
                                    />

                                    <div className="p-0">
                                      <small className="ms-2 font-12">
                                        {JSON.parse(info).message}
                                      </small>
                                      <p
                                        className="ms-2"
                                        style={styles.notificationTimeAgo}
                                      >
                                        {moment.unix(createdAt).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              </div>
                            );
                          }
                        )
                      )}
                      <Dropdown.Divider
                        style={styles.dividerWidth}
                        className={"mx-auto"}
                      />
                      <Dropdown.Item onClick={handleNavigateToNotifications}>
                        <b className="ms-3">See All</b>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Link>
              )}

              {isLogin && user ? (
                <>
                  <Nav.Link>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="inherit"
                        id="dropdown-autoclose-outside"
                        style={styles.profileDropDownToogle}
                      >
                        <div className="d-flex align-items-center">
                          {!isEmpty(user?.profilePicture) ? (
                            <img
                              src={user?.profilePicture}
                              alt="Profile Pic"
                              style={styles.profileImage}
                            />
                          ) : (
                            <img
                              src={
                                user?.gender === 1 ? FemaleProfile : MaleProfile
                              }
                              alt="Profile Pic"
                              style={styles.profileImage}
                            />
                          )}
                        </div>
                      </Dropdown.Toggle>

                      {student === USER_ROLES.teacher && (
                        <Dropdown.Menu style={{ marginLeft: "-150px" }}>
                          {Object.values(
                            teacherStatus !== STATUS.accepted ||
                              !teacher.video_watched
                              ? PendingTeacherDropdown
                              : teacherDropdownItems
                          ).map(({ icon, title, isVisible }, idx) => {
                            return !isVisible ? null : (
                              <div key={idx}>
                                <Dropdown.Divider
                                  style={{
                                    ...styles.dividerWidth,
                                    display: idx === 0 ? "none" : "block",
                                  }}
                                  className="mx-auto"
                                />
                                <Dropdown.Item>
                                  <div
                                    style={styles.profileDropdownMenu}
                                    key={idx}
                                    onClick={() =>
                                      navigationTeacherEvent(title)
                                    }
                                  >
                                    <img
                                      src={icon}
                                      alt={title}
                                      style={styles.profileDropDownIcon}
                                    />
                                    <p className="my-auto ms-2">{t(title)}</p>
                                  </div>
                                </Dropdown.Item>
                              </div>
                            );
                          })}
                        </Dropdown.Menu>
                      )}

                      {student === USER_ROLES.student && (
                        <Dropdown.Menu style={{ marginLeft: "-150px" }}>
                          {Object.values(studentDropDownItems).map(
                            ({ icon, title, isVisible }, idx) => {
                              return !isVisible ? null : (
                                <div key={idx}>
                                  <Dropdown.Divider
                                    style={{
                                      ...styles.dividerWidth,
                                      display: idx === 0 ? "none" : "block",
                                    }}
                                    className="mx-auto"
                                  />
                                  <Dropdown.Item>
                                    <div
                                      style={styles.profileDropdownMenu}
                                      key={idx}
                                      onClick={() =>
                                        navigationStudentEvent(title)
                                      }
                                    >
                                      <img
                                        src={icon}
                                        alt={title}
                                        style={styles.profileDropDownIcon}
                                      />
                                      <p className="my-auto ms-2">{t(title)}</p>
                                    </div>
                                  </Dropdown.Item>
                                </div>
                              );
                            }
                          )}
                        </Dropdown.Menu>
                      )}
                    </Dropdown>
                  </Nav.Link>
                </>
              ) : isLogin ? (
                <Nav.Link className="mx-3" onClick={() => setShowModal(true)}>
                  <span style={styles.navbarFont}>{t("log_out")}</span>
                </Nav.Link>
              ) : (
                <>
                  <Nav.Link className="mx-3" onClick={() => navigate("/login")}>
                    <span style={styles.navbarFont}>{t("login")}</span>
                  </Nav.Link>
                  <Nav.Link
                    className="mx-3"
                    onClick={() => navigate("/signup")}
                  >
                    <span style={styles.navbarFont}>{t("register")}</span>
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{t("log_out")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("do_you_want_to_logout")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={logoutEvent}
            disabled={logoutLoader}
          >
            {logoutLoader ? "Please wait..." : t("log_out")}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default NavigationBar;
