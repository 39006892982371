import Styled from "styled-components";
import { COLORS } from "../constants/Constants";

export const Background = Styled.div`  
        height: ${(props) => props.height || "auto"};
        width: auto;
        display: ${(props) => (props.flex ? "flex" : "block")};
        margin-top:3%;
        @media (min-width: 3500px) {
          background-size: inherit;
          height: 800px;
          margin-top:-100px;
        }
        @media (max-width: 1500px) {
          background-size: contain;
        }
        @media (max-width: 850px) {
          display:flex;
          flex-direction: ${(props) =>
            props.noReverse ? "column" : "column-reverse"};
          margin-top:10px;
        }
        @media (max-width: 600px) {
          background-size: contain;
          height: auto;
        }
`;
export const ImageDiv = Styled.div`
   height: auto; 
   width: ${(props) => props.width || "100%"}; 
   @media (max-width: 850px) {
    margin-left:${(props) => (props.mleft ? "20%" : "0%")};
  }
`;

export const TeacherDiv = Styled.div`
   @media (max-width: 995px) {
    margin-left: 50px;
    margin-right: 50px;
  }
`;

export const HeadingBox = Styled.div`  
          width: auto;
          height: 50%;
          margin-left: 7%;
          margin-top:10%;
          @media (min-width: 3500px) {
            margin-left: 3%;
            margin-top: 3%;
          }
          @media (max-width: 850px) {
            margin-left: 20%;
            margin-top: 3%;
          }
          @media (max-width: 600px) {
            width: 70%;
            margin-top:10%;
          }
`;

export const Teacher = Styled.div`  
width: 100%;
height: "auto";
box-shadow: 0px 2px 8px rgba(99, 99, 99, 0.2);
border-radius: 8px;
margin-top: 21px;
padding: 20px;
display: flex;
margin-left: -10px;
@media (max-width: 767px) {
  width: 105%;
}
`;
export const TeacherProfile = Styled.div`  
  width: 100%; 
  height: "auto";
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  margin-top: 21px;
  margin-left: -10px;
  display: flex;
  padding: 20px;
  @media (max-width: 767px) {
    width: 105%;
  }
`;
export const CategoryRow = Styled.div`  
          width: 100%;
          height: auto;
          position: relative;
          display: flex;
          align-items: center;
          margin-top: 2%;
          justify-content:center;
          @media (min-width: 4000px) {
            margin-left: 5%;
            margin-top: 1%;
          @media (min-width: 3500px) {
            margin-left: 3%;
            margin-top: -3%;

          }
`;

export const Title = Styled.h1`  
      font-size: 70px;
      font-style: normal;
      font-weight: 600;
      line-height: 95px;
      @media (max-width: 767px) {
        font-size: 50px;
        line-height: 75px;
      }
      @media (max-width: 500px) {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: -30px;
        width: 50px;
      }
`;

export const SubTitle = Styled.p`  
font-style: normal;
font-weight: 400;
font-size: 16px;
color: ${COLORS.BLACK};
margin-top: 10%;
@media (max-width: 500px) {
  font-size: 10px;
  width:200px;
}
`;

export const StartedButton = Styled.button`  
    background: ${(props) => props.background || COLORS.DARKSECONDARY};
    border-radius: 12px;
    width: 176px;
    height: 48px;
    border:none;
    &:hover {
      background: ${(props) => props.hoverColor || "#f77678"};
    }
`;

export const StartedButtonText = Styled.span`  
  color: ${COLORS.WHITE};
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
`;

export const Title2 = Styled.h1`  
      font-size: 60px;
      font-style: "normal";
      font-weight: 500;
      margin-top: ${(props) => props.margin || "0px"};
      @media (max-width: 1450px) {
        font-size: 50px;
      }
      @media (max-width: 1080px) {
        font-size: 50px;
      }
      @media (max-width: 400px) {
        font-size: 25px;
      @media (max-width: 750px) {
        font-size: 35px;
      }
      @media (max-width: 590px) {
        font-size: 30px;
      }
      @media (max-width: 480px) {
        font-size: 25px;
        margin-top:10px;
      }
`;

export const Text = Styled.span`  
font-style: normal;
font-weight: 400;
font-size: 25px;
align-self: center;
color: #fff;
text-align:center;
      @media (max-width: 767px) {
        font-size: 20px;
      }
      @media (max-width: 400px) {
        font-size: 15px;
      }
`;

export const CategoryBox = Styled.div`
    display: grid;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: center;
    @media (max-width: 575px) {
      height: 150px;
    }
`;

export const UL = Styled.ol`  
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    @media (max-width: 1450px) {
      font-size: 19px;
    }
      @media (max-width: 1080px) {
        font-size: 16px;
      }
      @media (max-width: 750px) {
        font-size: 13px;
      }
      @media (max-width: 590px) {
        font-size: 10px;
      }
      @media (max-width: 480px) {
        font-size: 8px;
      }
`;

export const CarouselContainer = Styled.div`  
    margin-top: 50px; 
    margin-bottom: 80px; 
    width: 100%; 
    @media (min-width: 3500px) {
      width: 20%;
    }
`;

export const CarouselDiv = Styled.div`  
width: 745px;
height: 296px;
border-radius: 12px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
margin: 20px;
overflow:hidden;
@media (max-width: 1400px) {
  width: 600px;
  height: 280px;
}
      @media (max-width: 570px) {
        width: 300px;
        height: 296px;
      }
`;

export const FooterLinks = Styled.span`  
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;
      overflow:hidden;
      width:90%;
      height:30px;
      display:flex;
      justify-content:center;
            @media (max-width: 1403px) {
              font-size: 12px;
            }
            
`;

export const LoginDiv = Styled.div`  
            width: ${(props) => props.width || "30%"};
            height: auto;
            margin-top: ${(props) => props.marginTop || "3%"};
            box-shadow: 0px 4px 16px #cac6c6;
            border-radius: 8px;
            alignSelf:center;
            padding-left:15px;
            padding-right:15px;
            @media (max-width: 1000px) {
              width: 70%;
            }
            @media (max-width: 823px) {
              width: 80%;
            }
            @media (max-width: 450px) {
              width: 100%;
              margin-top: ${(props) => props.smMargin || "2%"};
            }
            
`;

export const ClubDiv = Styled.div`  
            width: ${(props) => props.width || "50%"};
            height: auto;
            box-shadow:${(props) => props.noShadow || "0px 4px 16px #cac6c6"} ;
            @media (max-width: 1000px) {
              width: 70%;
            }
            @media (max-width: 823px) {
              width: 80%;
            }
            @media (max-width: 450px) {
              width: 100%;
              margin-top: ${(props) => props.smMargin || "2%"};
            }
            
`;

export const ModalText = Styled.p`  
    font-style: normal; 
    font-weight: ${(props) => props.weight || "500"}; 
    font-size: ${(props) => props.size || "18px"};
`;
export const PrimaryButton = Styled.button`  
              width: ${(props) => props.width || "100%"};
              height: ${(props) => props.height || "54px"};
              background: ${(props) => props.background || COLORS.PRIMARY};
              opacity: 0.9;
              border-radius: 10px;
              border:none;
              opacity: 0.9;
              box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
              margin-top: 12px;
              &:disabled{
            background-color:${(props) => props.disabledBg || "#F7C3C4"};
}
`;

export const PrimaryButtonText = Styled.span`  
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: ${(props) => props.color || "#0000008A"};
          @media (max-width: 710px) {
            font-size: 12px;
          }
          @media (max-width: 400px) {
            font-size: 12px;
          }
`;

export const InputLabel = Styled.p`  
            color: ${(props) => props.color || "#000000"};
            font-style: normal;
            font-weight: ${(props) => props.weight || "400"};
            font-size: ${(props) => props.size || "12px"};
            cursor: ${(props) => (props.cursor ? "pointer" : "text")}
`;

export const TeachersCard = Styled.div`
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      width:100%;
      height: 215px;
      border-radius:12px;
      margin-top:20px;
      over-flow:hidden;
      margin-Bottom:20px;
`;

export const HorizontalBox = Styled.div`  
      box-shadow:0px 0px 5px grey;
      border-radius:12px;
      margin: 7px;
      padding: 10px;
      margin-right: 20px;
      background: #ffffff;
`;

export const RecommendedTeachersCard = Styled.div`  
      width:100%;
      height: 180px;
      border-radius:12px;
      over-flow:hidden;
      margin-Bottom:20px;
      background: #ffffff;
      padding: 10px 20px;
`;
export const RecommendedTeachersCardMobile = Styled.div`  
      width:300px;
      height: 200px;
      border-radius:12px;
      over-flow:hidden;
      margin-Bottom:20px;
      background: #ffffff;
      padding: 10px 20px;
`;

export const FindTeacherHeaderText = Styled.span`  
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      width:180px;
      height:30px;
      overflow:hidden;
      text-overflow: ellipsis;
      @media (max-width: 1310px) {
        width:160px;
      }
      @media (max-width: 1230px) {
        font-size: 16px;
        overflow:hidden;
        width:150px;
        text-overflow: ellipsis;
      }
      @media (max-width: 420px) {
        font-size: 13px;
        overflow:hidden;
        text-overflow: ellipsis;
        width:100px;
      }
`;

export const RecommendedTeacherText = Styled.p`  
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
`;

export const FindTeacherHeaderLogo = Styled.div`  
     margin-left: 10%;
      @media (max-width: 1320px) {
        margin-left: 0px;
      }
`;

export const FindTeacherPriceHeading = Styled.p`  
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #ACACAC;
        @media (max-width: 1230px) {
          font-size: 10px;
        }
      @media (max-width: 992px) {
        font-size: 8px;
      }
`;

export const FindTeacherPriceText = Styled.p`  
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        margin-top: -15px;
        @media (max-width: 1230px) {
          font-size: 14px;
        }
      @media (max-width: 992px) {
        font-size: 12px;
      }
`;

export const FindTeacherExpertiseText = Styled.p`  
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #838383;
      margin-top: -5px;
      @media (max-width: 1230px) {
        font-size: 12px;
        margin-top: -10px;
      }
      @media (max-width: 420px) {
        font-size: 10px;
        margin-top: -15px;
      }
`;

export const DropDownContainer = Styled.div`  
      display:flex;
      @media (max-width: 760px) {
        display: grid;
        justify-content:center;
      }
`;

export const DropDownButton = Styled.div`  
      display:block;
      @media (max-width: 760px) {
        display: none;
      }
`;
export const FilterButton = Styled.div`  
      display:none;
      @media (max-width: 760px) {
        display: block;
      }
`;

export const BoxPicker = Styled.div`  
  border-bottom:1px solid ${(props) => (props.err ? "#FF0000" : "#84DCC6")};
  background: #F5F5F5;
  padding: 5px;
  `;
export const RecommendedTecherSubjects = Styled.p`  
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #ACACAC;
`;

export const RecommendedBookButton = Styled.button`  
      width: 87px;
      height: 35px,
      background-color: #FF686B,
      margin-top: 7px,
      border-radius: 25px;
      align-self: flex-end`;

export const TeachersHorizontalList = Styled.div`  
      display:none;
      @media (max-width: 600px) {
        display: block;
        background: #F6C644;
        padding-bottom: 25px;
        border-radius: 5px;
        padding: 10px 0px 25px 10px;
      }
`;

export const AccountType = Styled.div`  
height: 268px;
width: 268px;
background: #F9E0A7;
border-radius: 134px;
margin-inline: 30px;
cursor: pointer;
@media (max-width: 710px) {
  height: 200px;
width: 200px;
@media (max-width: 575px) {
  height: 168px;
width: 168px;
}
`;

export const BecomeTeacherDiv = Styled.div`  
            width: ${(props) => props.width || "40%"};
            height: auto;
            margin-top: ${(props) => props.marginTop || "3%"};
            margin-bottom:${(props) => props.marginBottom || "40px"};
            box-shadow: ${(props) =>
              props.noShadow || "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"};
            border-radius: 8px;
            alignSelf:center;
            padding-left:30px;
            padding-right:30px;
            cursor:${(props) => props.cursor || "default"};;
            display:${(props) => (props.block ? "block" : "grid")};
            justify-content: ${(props) => (props.center ? "center" : "null")};
            @media (min-width: 2560px) {
              width: 30%;
            }
            @media (max-width: 1900px) {
              width: 45%;
            }
            @media (max-width: 1750px) {
              width: 55%;
            }
            @media (max-width: 1580px) {
              width: 60%;
            }
            @media (max-width: 1320px) {
              width: 70%;
            }
            @media (max-width: 1135px) {
              width: 80%;
            }
            @media (max-width: 999px) {
              width: 100%;
            }
            @media (max-width: 767px) {
              width: 90%;
            }
            @media (max-width: 520px) {
              width: 95%;
            }
            
`;

export const TeacherDashboardCard = Styled.div`  
            width:${(props) => props.width || "50%"}; 
            height:auto;
            display: flex;
            justify-content: center;
            box-shadow: ${(props) =>
              props.shadow ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" : null};
            border-radius: 8px;
            @media (max-width: 1750px) {
              width: 50%;
            }
            @media (max-width: 1580px) {
              width: 60%;
            }
            @media (max-width: 1320px) {
              width: 70%;
            }
            @media (max-width: 1135px) {
              width: 80%;
            }
            @media (max-width: 999px) {
              width: 100%;
            }
            @media (max-width: 767px) {
              width: 70%;
            }
            @media (max-width: 520px) {
              width: 95%;
            }
            
`;

export const LessonsDiv = Styled.div` 
            box-shadow: 0px 4px 16px #cac6c6;
            border-radius: 8px;
            width: 50%;
            @media (max-width: 1750px) {
              width: 50%;
            }
            @media (max-width: 1440px) {
              width: 60%;
            }
            @media (max-width: 1250px) {
              width: 70%;
            }
            @media (max-width: 1050px) {
              width: 80%;
            }
            @media (max-width: 850px) {
              width: 100%;
            }
            @media (max-width: 767px) {
              width: 70%;
            }
            @media (max-width: 520px) {
              width: 95%;
            }
            
`;

export const SubjectButton = Styled.div`  
    background: ${(props) => props.background || COLORS.DARKSECONDARY};
    border-radius: 0px;
    height: 48px;
    border:none;
    border-radius:12px;
`;

export const TeacherTypeHeading = Styled.p`  
font-style: normal;
font-weight: 400;
font-size: 20px;
margin-top: 20px;
text-align: center;
`;

export const BecomeTeacherFormDiv = Styled.div`  
width: 700px;
margin-bottom: 20px;
@media (max-width: 991px) {
  width: ${(props) => props.modalWidth || "700px"};
}
@media (max-width: 950px) {
  width: ${(props) => props.Width || "600px"};;
}
@media (max-width: 770px) {
  width: 400px;
}
@media (max-width: 620px) {
  width: 400px;
}
@media (max-width: 490px) {
  width: 350px;
}
@media (max-width: 430px) {
  width: 300px;
}
@media (max-width: 390px) {
  width: 250px;
}
@media (max-width: 330px) {
  width: 200px;
}
`;

export const CardText = Styled.p`
    font-size: 60px;
    font-weight: 300;
    text-align: center;
    @media (max-width: 803px) {
      font-size: 45px;
    }
`;

export const CardText2 = Styled.p`
    font-size: 20px;
    text-align: center;
    @media (max-width: 803px) {
      font-size: 10px;
    }
`;

export const LessonCardStudentDashboard = Styled.div`
background: #FCFCFC;
 width: 520px;
  padding:5px;
   border-radius:8px;
    display:flex;
     justify-content:space-between;
      cursor:pointer;  
      @media (max-width: 1870px) {
        width: 500px;
      }
      @media (max-width: 1800px) {
        width: 450px;
      }
      @media (max-width: 839px) {
        width: 420px;
      }
      @media (max-width: 630px) {
        width: 300px;
      }
      @media (max-width: 370px) {
        width: 200px;
      }
    
`;
export const LessonTabText = Styled.div`
    font-size: 20px;
    font-weight: 500;
    color: grey;
    width: ${(props) => props.width || "70%"}; 
    display:flex;
      @media (max-width: 1450px) {
        width: ${(props) => props.largeWidth || "80%"}; 
      }
      @media (max-width: 990px) {
        width: 100%;
      }
      @media (max-width: 850px) {
        display: block;
      }
`;

export const LessonTabs = Styled.div`
font-size: 20px;
    font-weight: 500;
    color: grey;
    width: ${(props) => props.width || "100%"}; 
    display:flex;
      @media (max-width: 1450px) {
        width: 100%;
      }
      @media (max-width: 990px) {
        width: 100%;
      }
      @media (max-width: 850px) {
        display: block;
      }
`;

export const Community = Styled.div`
padding-left:40px;
@media (max-width: 1450px) {
  padding-left:40px;
      }
      @media (max-width: 990px) {
        padding-left:0px;
      }
      @media (max-width: 850px) {
        padding-left:0px;
      }
`;

export const DashboardCards = Styled.div`
width: 100%;
height: auto;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
border-radius: 8px;
margin-left: -10px;
      @media (max-width: 767px) {
        width:105%;
      }
`;
export const DashboardCards2 = Styled.div`
width: ${(props) => props.width || "105%"};
height: auto;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
border-radius: 8px;
margin-top: 21px;
margin-left: -10px;
display: ${(props) => props.display || "flex"};
padding: 20px;
      @media (max-width: 767px) {
        width:105%;
      }
`;
export const BookLessonDiv = Styled.div`  
            width:${(props) => props.width || "80%"}; 
            height:auto;
            display: ${(props) => props.display || "flex"};
            justify-content: center;
            box-shadow: ${(props) =>
              props.shadow ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" : null};
            border-radius: 8px;
            padding:5px;
            @media (min-width: 1750px) {
              width: 80%;
            }
            @media (max-width: 1580px) {
              width: 80%;
            }
            @media (max-width: 1320px) {
              width: 80%;
            }
            @media (max-width: 1135px) {
              width: 97%;
            }
            @media (max-width: 999px) {
              width: 97%;
            }
            @media (max-width: 767px) {
              width: 97%;
            }
            @media (max-width: 520px) {
              width: 100%;
            }
            
`;

export const styles = {
  homeMainDiv: { overflow: "hidden" },
  headingFontBox: {
    width: "423px",
    height: "338px",
  },
  categoryIcon: {
    height: "auto",
    width: "50px",
  },
  categoryBox: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  headingBox2: {
    width: "auto",
    height: "auto",
    marginLeft: "10%",
    position: "relative",
    alignItems: "center",
    marginTop: "5%",
  },
  categoryRowDiv: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  headingBox3: {
    width: "50%",
    height: "auto",
    marginLeft: "10%",
    position: "relative",
    marginTop: "10%",
  },
  landingScreenImage: { width: "100%", height: "auto" },
  carouselHeading: {
    maxWidth: "280px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "42px",
    marginLeft: "12%",
    marginBottom: "50px",
  },
  navbarFont: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    color: COLORS.BLACK,
    align: "center",
  },
  NavbarStyle: {
    position: "static",
    minHeight: "75px",
    width: "100%",
    left: "0px",
    right: "0px",
    top: "0px",
    background: "#FFFFFF",
    borderBottom: "1px solid #F1F1F1",
    zIndex: 1,
  },
  NavbarLogo: {
    position: "absolute",
    width: "auto",
    height: "51.14px",
    left: "22px",
    top: "12px",
    cursor: "pointer",
  },
  DownIconStyle: {
    position: "absolute",
    width: "20px",
    height: "20px",
    color: COLORS.BLACK,
    marginTop: "3px",
  },
  dropdownMenu: {
    padding: 10,
  },
  dropdownLabel: {
    fontStyle: "medium",
    fontWeight: "500",
    fontSize: "18px",
  },
  selectStyle: {
    background: "#F5F5F5",
    border: "0px",
    fontSize: 14,
    height: "40px",
    width: "100%",
  },
  selectStyleLng: {
    border: "1px solid #C9C9C9",
    fontSize: 14,
    margin: 0,
    paddingInline: 15,
    paddingBlock: 8,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  footer: {
    width: "100%",
    height: "40%",
    background: COLORS.PRIMARY,
    position: "static",
    display: "flex",
    justifyContent: "center",
  },
  footerList: {
    width: "100%",
    marginBottom: "2%",
  },
  iconsRow: { width: "40%", height: "auto" },
  iconsBox: {
    width: "10px",
    height: "auto",
  },
  footerIcons: {
    height: "40px",
    width: "40px",
    cursor: "pointer",
  },
  sliderContainer: { width: "100%", height: "90%" },
  sliderImage: { width: "100%", height: "100%", marginLeft: -12.5 },
  sliderBody: { marginLeft: 0 },
  sliderDate: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    marginTop: "16px",
    color: COLORS.BLACK,
  },
  sliderHeading: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    overflow: "hidden",
    height: "30px",
    marginTop: "-12px",
    color: "#525252",
  },
  sliderParagraph: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    overflow: "hidden",
    height: "141px",
    width: "auto",
    color: "#525252",
  },
  PrimaryButtonLogo: {
    width: "19.37",
    height: "23px",
    marginRight: "2%",
  },
  loginDivRow: { width: "auto", height: "95%", padding: "5%" },
  loginInputBox: {
    height: "54px",
    marginTop: "-8px",
    background: "#F9F9F9",
    border: "0.8px solid rgba(7, 12, 66, 0.1)",
  },
  loginInputIcon: {
    border: "none",
    background: "#F9F9F9",
    marginLeft: "2.33%",
    padding: "15px",
  },
  loginInput: {
    border: "none",
    background: "#F9F9F9",
    height: "auto",
    width: "full",
  },
  inputIcon: { color: "#828282" },
  teachersCardRow: { width: "97%" },
  teachersRowHeading: {
    fontWeight: 500,
    fontSize: "18px",
    marginTop: "15px",
  },
  teachersRowSubtitle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
  },
  randomTeacherRow: { width: "auto", height: "100%" },
  randomTeacherCol1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  recommendedTeacherCol1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#333333",
  },
  randomTeacherCol1Div: { marginLeft: "10px" },
  recommendTeacherCol1Div: { alignSelf: "center" },
  randomTeacherCol1Image: {
    height: "82px",
    width: "82px",
    borderRadius: "41px",
    marginTop: -10,
  },
  randomTeacherCol1Stars: {
    marginTop: "10%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  recommendTeacherCol1Stars: {
    marginTop: "10%",
    marginLeft: "10%",
    alignItems: "center",
    justifyContent: "center",
    background: "#000000",
  },
  randomTeacherCol1StarsText: {
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "12px",
    textAlign: "center",
  },
  randomTeacherButton: {
    width: "87px",
    height: "35px",
    background: "#FF686B",
    borderRadius: "25px",
    border: "none",
  },
  recommendTeacherButton: {
    width: "87px",
    height: "35px",
    background: "#FF686B",
    borderRadius: "25px",
    border: "none",
    alignSelf: "flex-end",
  },
  randomTeacherButtonText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    color: "#fff",
  },
  randomTeacherCol2: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  },
  randomTeacherCol2Div: { height: "35px", width: "100%", display: "flex" },
  randomTeacherCol2Flag: { width: "30px", height: "30px", marginLeft: "10px" },
  randomTeacherCol2SubjectHeading: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    color: "#ACACAC",
  },
  randomTeacherCol2SubjectDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "-10px",
  },

  recommendTeacherCol2SubjectDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "60%",
  },
  recommendTeacherIcons: {
    display: "flex",
    alignItems: "center",
  },
  randomTeacherCol2SubjectRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  randomTeacherCol2SubjectIcon: { width: "25px", height: "25px" },
  randomTeacherCol2SubjectName: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    width: "250px",
    height: "27px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  recommendedTeacherCol2SubjectName: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    width: "100px",
    height: "27px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  randomTeacherCol2PriceDiv: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  randomTeacherCol3: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  randomTeacherCol3Div: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  randomTeacherCol3Image: { maxWidth: "85%", height: "auto" },
  recommendedTeachersCol: {
    marginTop: "95px",
    background: "#F6C644",
    paddingTop: 15,
    marginBottom: 20,
    borderRadius: 20,
  },
  dropDownToogle: {
    background: "#F6F6F6",
    borderRadius: "16px",
    width: "100%",
    marginLeft: "15px",
  },
  dropDownItemToogle: { width: "200px" },

  dropDownMenu: {
    width: "240px",
    height: "auto",
    background: "#FFFFFF",
    border: "1px solid #F0F0F0",
    boxSizing: "border-box",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "12px",
    padding: "7px",
    flexWrap: "wrap",
  },
  dropDownToogleTitle: { width: "100%", display: "flex", paddingRight: "10px" },
  dropDownItemText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    padding: "10px 10px 10px 10px",
  },
  priceSlider: {
    width: "100%",
    height: "auto",
    display: "grid",
    justifyContent: "center",
  },
  priceSliderText: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
  },
  priceSliderWidth: { width: 190 },
  rightIconStyle: {
    position: "absolute",
    width: "12px",
    height: "12px",
    color: COLORS.BLACK,
    marginTop: "2%",
  },
  subjectDropDownToogle: {
    background: "#fff",
    borderRadius: "20px",
    width: "90%",
    marginLeft: "10px",
    border: "none",
  },
  subjectDropDownToogleTitle: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
  },
  subjectDropDownToogleText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    color: COLORS.BLACK,
    align: "center",
  },
  subjectSearchInput: { border: "none", background: "#F6F6F6" },
  LeftIconStyle: {
    position: "absolute",
    width: "20px",
    height: "20px",
    color: COLORS.BLACK,
    marginTop: "1%",
    transform: "scaleX(-1)",
  },
  searchInputBox: {
    background: "#F6F6F6",
    borderRadius: "20px",
    width: "200px",
    height: "43px",
    overflow: "hidden",
    marginRight: "45px",
  },
  searchInputBoxSm: {
    background: "#F6F6F6",
    borderRadius: "5px",
    width: "100%",
    height: "55px",
    overflow: "hidden",
  },
  teachersRating: { justifyContent: "center", display: "flex", marginTop: 20 },
  availabilityNow: {
    fontWeight: "600",
    borderBottom: "1px solid #D3D3D3",
    paddingBottom: 5,
    fontSize: 18,
  },
  dayTime: {
    fontWeight: "500",
    marginTop: -7,
    fontSize: 18,
  },
  timebox: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #D3D3D3",
    paddingBottom: 15,
    marginBottom: 15,
  },
  timeButton: {
    marginInline: 5,
    border: "0px",
    paddingInline: 12,
    paddingBlock: 7,
    borderRadius: 7,
    marginTop: 4,
  },
  dayButton: {
    height: 40,
    border: "0px",
    width: 40,
    borderRadius: 25,
  },
  onBoardingHeading: {
    fontSize: "20px",
    color: "#070C42",
    fontWeight: "500",
  },
  dateLabel: {
    fontSize: 12,
    color: "#84DCC6",
    fontWeight: "400",
    marginBottom: -2,
  },
  BoxPickerLabel: {
    marginBottom: 5,
  },
  bottom: { marginBottom: 7 },
  labelFont: { fontSize: 14 },
  profileImages: { height: "200px", width: "200px", borderRadius: "50%" },
  accountTypeBox: { height: "auto", display: "grid", justifyContent: "center" },
  accountTypeImg: { width: "100%", height: "100%" },
  subjectText: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
  },
  subjectTitle: {
    fontWeight: "400",
    fontSize: "16px",
  },

  becomeTeacherCard: {
    width: "100%",
    height: "500px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
    borderRadius: "12px",
    marginTop: "25px",
    display: "inline-block",
    justifyContent: "center",
  },
  becomeTeacherCardHeading: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    background: COLORS.WHITE,
    borderRadius: "6px",
    width: "270px",
    height: "30px",
    marginTop: "20px",
    textAlign: "center",
  },
  becomeTeacherCardHeaderText: {
    color: COLORS.WHITE,
    fontSize: "23px",
    fontWeight: "600",
    paddingInline: "5%",
    textAlign: "center",
    height: "70px",
  },
  becomeTeacherCardBodyText: {
    color: COLORS.WHITE,
    fontSize: "16px",
    fontWeight: "600",
    paddingInline: "12%",
    textAlign: "center",
    height: "50%",
  },
  becomeTeacherCardButton: {
    border: "none",
    width: "50%",
    height: "40px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    marginLeft: "25%",
  },
  becomeTeacherHeading: {
    fontSize: "32px",
    weight: 400,
  },
  becomeTeacherHeadingDiv: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "20px",
  },
  becomeTeacherSubHeading: {
    fontSize: "16px",
    weight: 400,
    textAlign: "center",
  },
  becomeTeacherSubHeading2: {
    color: COLORS.DARKPRIMARY,
    fontWeight: 600,
    fontSize: "19px",
    textAlign: "center",
  },
  anchorColor: { color: "blue", cursor: "pointer" },
  videoIntroModal: { fontWeight: 600, fontSize: "14px", marginTop: "8px" },
  videoIntroModalLine: {
    border: "0.5px solid #606060",
    marginTop: "-12px",
    marginBottom: "10px",
  },
  videoIntroModalUL: {
    width: "100%",
    fontWeight: 200,
    fontSize: "14px",
    minHeight: "50px",
  },
  teacherTypeDiv: { width: "80%", height: "auto", marginBottom: "20px" },
  teacherTypeImageDiv: {
    width: "150px",
    height: "150px",
    padding: "10px 10px 0px 30px",
    background: "#F9E0A7",
    borderRadius: "75px",
    overflow: "hidden",
  },
  teacherTypeImage: { width: "100%", height: "100%" },
  teacherTypeText: { width: "90%" },
  teacherTypeBodyBackgroundText: {
    width: "100%",
    fontWeight: 200,
    fontSize: "12px",
    minHeight: "70px",
  },
  teacherTypeBodyCredentialsText: {
    width: "100%",
    fontWeight: 200,
    fontSize: "12px",
    minHeight: "40px",
  },
  teacherTypeBodyIntroText: {
    width: "100%",
    fontWeight: 200,
    fontSize: "12px",
    minHeight: "20px",
  },
  teacherTypeBodyPayText: {
    width: "100%",
    fontWeight: 200,
    fontSize: "12px",
    minHeight: "20px",
  },
  becomeTeacherFormLabel: {
    fontWeight: 300,
    fontSize: "15px",
    marginTop: "20px",
  },
  timeLabel: {
    fontWeight: 500,
    fontSize: "18px",
    marginTop: "20px",
  },
  becomeTeacherAddButton: {
    fontSize: "14px",
    color: COLORS.DARKPRIMARY,
    cursor: "pointer",
    width: "180px",
  },
  becomeTeacherButtonDiv: { width: "50%" },
  becomeTeacherSelect: {
    height: "42px",
    fontSize: "15px",
    fontWeight: 400,
    width: "100%",
    border: "0.5px solid grey",
    borderRadius: "5px",
    paddingInline: "10px",
  },
  becomeTeacherSelectPlaceholder: {
    height: "42px",
    fontSize: "15px",
    fontWeight: 400,
    color: "grey",
  },
  accordionDiv: {
    cursor: "pointer",
    width: "140px",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  accordionDivItem: {
    width: "150px",
    marginLeft: "25px",
    marginTop: "20px",
  },
  accordionSubject: {
    fontSize: "12px",
    marginTop: "-14px",
  },
  teachingProfileSubtitle: { fontSize: "14px" },
  teachingProfileVideoDiv: {
    width: "200px",
    height: "auto",
    marginTop: "10px",
  },
  teachingProfileVideo: { width: "100%", height: "auto" },
  teachingProfileTextareaCounter: {
    fontSize: "12px",
    marginTop: "5px",
    float: "right",
  },
  awardModalTitle: { fontWeight: "400", fontSize: "20px", margin: 0 },
  checkLabel: { fontSize: "14px", fontWeight: 300 },
  uploadButtonText: { margin: 0, fontSize: "12px" },
  teacherModal: {
    paddingTop: 25,
    paddingInline: 45,
  },
  lessonsHeading: {
    fontSize: "24px",
    fontWeight: "300",
    marginBottom: 0,
  },
  subjectHeading: {
    fontSize: "22px",
    fontWeight: "400",
    marginBottom: 0,
  },
  lessons: {
    fontSize: 16,
    fontWeight: "400",
    cursor: "pointer",
  },
  subject: {
    fontSize: 18,
    fontWeight: "300",
  },
  lessonsFormLabels: {
    fontSize: 18,
    fontWeight: "500",
    marginTop: 0,
  },
  priceCol: {
    border: "1px solid #CED4DA",
    borderRadius: 5,
  },
  dollar: {
    color: "#666666",
    fontSize: 16,
    fontWeight: "400",
  },
  lessonVideos: { margin: 0, fontSize: "12px" },
  formats: {
    color: "#333333",
    fontSize: 12,
  },

  statsCardRow: {
    width: "auto",
    height: "auto",
    justifyContent: "center",
  },
  statsCardCol: { width: "auto", paddingInline: "30px" },
  teacherDashboardCard: {
    width: "100%",
    height: "auto",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    borderRadius: "8px",
    marginLeft: "-10px",
  },
  teacherDashboardCard2: {
    width: "100%",
    height: "auto",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    borderRadius: "8px",
    marginTop: "21px",
    marginLeft: "-10px",
    display: "flex",
    padding: "20px",
  },
  teacherDashboardCard3: {
    width: "105%",
    height: "auto",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    borderRadius: "8px",
    marginLeft: "-10px",
    padding: "15px",
  },
  teacherDashboardCardText: {
    fontSize: "11px",
    marginTop: "-16px",
    fontWeight: "300",
  },
  teacherDashboardCardClassesText: {
    height: "25px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  teacherDashboardCardClassesTitle: { fontWeight: "bold", cursor: "pointer" },
  teacherDashboardRow: { width: "100%", justifyContent: "space-between" },
  teacherDashboardCardDiv1: {
    display: "flex",
    padding: "20px",
  },
  teacherDashboardCardDp: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
  },
  teacherDashboardCardHeading: {
    fontSize: "21px",
    fontWeight: "bold",
    height: "33px",
    maxWidth: "180px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  teacherDashboardCardEditDiv: {
    height: "25px",
    marginLeft: "20px",
    cursor: "pointer",
  },
  teacherDashboardCardStarImage: {
    width: "23px",
    height: "23px",
    marginRight: "3px",
  },
  teacherDashboardCardButton: {
    width: "100%",
    border: "none",
    height: "60px",
    marginTop: "15px",
    borderRadius: "8px",
  },
  teacherDashboardWalletCard: {
    width: "15px",
    height: "15px",
    marginLeft: "20px",
    cursor: "pointer",
  },
  teacherDashboardWalletText: { marginLeft: "auto", marginTop: "auto" },
  teacherDashboardSubjectDp: {
    width: "50px",
    height: "50px",
    borderRadius: "25px",
  },
  teacherDashboardCardButtonImage: { width: "15px", height: "auto" },
  calenderLabelText: {
    fontSize: "15px",
    marginTop: "0px",
    marginLeft: "4px",
  },
  calender: { width: "90%", height: "75%", marginTop: "10px" },
  calenderLabelDiv: {
    height: "25px",
    overflow: "hidden",
    width: "130px",
    cursor: "pointer",
  },
  calenderLabelColor: { width: "12px", height: "12px" },

  catCol: { padding: 0, marginRight: "0px" },
  catText: {
    color: "black",
    fontSize: 14,
    alignSelf: "center",
    display: "d-flex",
  },
  labels: {
    color: "#ACACAC",
    fontSize: 12,
    padding: 0,
    margin: 0,
    fontWeight: "500",
  },
  relativeBox: { position: "relative", right: "-62px", top: "75px" },
  relativeBoxRec: { position: "relative", right: "-70px", top: "75px" },
  addElementCardDiv: {
    borderBottom: "1px solid #F0F0F0",
    height: "100px",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  addElementCardTitle: {
    fontWeight: "bold",
    maxWidth: "400px",
    height: "25px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  addElementCardSubtitle: {
    fontSize: "14px",
    marginTop: "-17px",
    height: "25px",
    maxWidth: "400px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  addElementCardDescription: {
    fontSize: "14px",
    marginTop: "-17px",
    height: "23px",
    maxWidth: "400px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  addElementCardDate: {
    fontSize: "14px",
    color: "#ACACAC",
    marginTop: "-17px",
  },
  addElementCardIcon: { width: "15px", height: "auto", cursor: "pointer" },

  profileImage: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    overflow: "hidden",
    objectFit: "cover",
  },
  userProfileImage: { height: "28px", width: "auto", borderRadius: "24px" },

  profileImageMessage: { height: "48px", width: "48px", borderRadius: "24px" },
  profileDropDownToogle: {
    background: "#fff",
    borderRadius: "16px",
    width: "100%",
  },
  profileDropdownMenu: {
    width: "200px",
    display: "flex",
    alignItems: "center",
    paddingTop: "8px",
    paddingBottom: "8px",
    marginLeft: "10px",
  },
  dividerWidth: { width: "180px" },
  profileDropDownIcon: { width: "25px", height: "25px" },
  applicationText: {
    fontWeight: "700",
  },
  teacherAvailabilityCalenderButton: {
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
    justifyContent: "center",
  },
  calenderViewButton: {
    background: "transparent",
    width: "80px",
    height: "auto",
    borderRadius: "10px",
    border: "2px solid",
    padding: "5px",
  },
  teacherAvailabilityCalenderText: { fontSize: "18px", fontWeight: "500" },
  teacherAvailabilityCalenderButtonImage: {
    width: "18px",
    height: "18px",
    marginInline: "30px",
    cursor: "pointer",
  },
  teacherAvailabilityHeading: {
    fontWeight: "400",
    fontSize: "30px",
    padding: "20px",
  },
  teacherAvailabilityCalenderDiv: { height: "600px", alignItems: "center" },
  heading: {
    borderBottom: "1px solid lightGrey",
    paddingBottom: 10,
    fontSize: 24,
  },
  borderHorizontal: {
    borderBottom: "1px solid lightGrey",
  },
  label: {
    fontSize: 18,
    fontWeight: "500",
  },
  addTimeSlotAddButton: {
    width: "140px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  addTimeSlotAddButtonImage: { height: "20px", width: "20px" },
  chatUsers: {
    background: COLORS.GREY,
  },
  chatHeading: {
    fontSize: 28,
    fontWeight: "400",
    margin: 25,
  },
  chatSearch: {
    flexDirection: "row",
    border: "1px solid grey",
    borderRadius: "20px",
    marginInline: 30,
    paddingInline: 15,
    alignItems: "center",
  },
  typeMessage: {
    flexDirection: "row",
    border: "1px solid #E8E8E8",
    borderRadius: "20px",
    marginInline: 20,
    paddingInline: 15,
    alignItems: "center",
  },
  chatBorder: {
    backgroundColor: COLORS.GREYWHITE,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    marginBottom: 20,
  },
  chatBorderUser: {
    backgroundColor: COLORS.PRIMARY,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    marginBottom: 20,
  },
  lessonStudentDashboardCardText: { fontSize: "15px", color: "#EEB13D" },
  learningCardStudentDashboardStatusText: {
    fontSize: "17px",
  },
  learningCardStudentDashboard: {
    width: "105%",
    marginTop: 0,
    padding: "10px",
    background: COLORS.LIGHTTERTIARY,
    marginBottom: "10px",
    cursor: "pointer",
  },
  homeworkDeadlineText: {
    fontWeight: "600",
    fontSize: "18px",
    textTransform: "uppercase",
  },
  homeworkDeadlineTitle: { fontSize: "27px", fontWeight: "300" },
  homeworkImage: { width: "70px", height: "70px", borderRadius: "35px" },
  homeworkUsername: { fontWeight: "400", fontSize: "18px" },
  homeworkUserType: { fontWeight: "300", fontSize: "14px", marginTop: "-15px" },
  homeworkButton: {
    width: "200px",
    height: "60px",
    border: "none",
    padding: "15px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  },
  homeworkButtonText: { fontSize: "13px", fontWeight: "500" },
  homeworkFileText: {
    fontWeight: "300",
    fontSize: "13px",
    marginLeft: "-20px",
    marginTop: "20px",
  },
  homeworkIdText: { textAlign: "right", fontSize: "12px", fontWeight: "400" },
  studentLessonsCardStatusbar: {
    width: "90px",
    height: "100px",
    justifyContent: "center",
    display: "grid",
    borderRadius: "5px",
  },
  studentLessonsCardStatusText: {
    textTransform: "uppercase",
    fontWeight: "300",
    fontSize: "14px",
  },
  studentLessonCardDivider: {
    border: "0.5px solid black",
    background: "black",
    width: "100%",
  },
  studentLessonCardDateText: {
    textTransform: "uppercase",
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "5px",
  },
  studentLessonCardImage: {
    width: "60px",
    height: "60px",
    borderRadius: "30px",
    cursor: "pointer",
  },
  datePicker: {
    border: "1px solid lightgrey",
    borderRadius: 3,
    justifyContent: "center",
  },
  slotComponent: {
    border: "1px solid lightgrey",
    paddingInline: 15,
    borderRadius: 5,
    paddingTop: 5,
    marginRight: 7,
    alignItems: "center",
    justifyContent: "center",
    display: "d-flex",
    cursor: "pointer",
  },
  primaryButton: {
    display: "d-flex",
    borderRadius: 7,
    paddingBlock: 5,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: COLORS.PRIMARY,
  },
  borderButton: {
    display: "d-flex",
    border: `1px solid #84DCC6`,
    borderRadius: 7,
    paddingBlock: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  seeAllButton: {
    fontWeight: "600",
    color: COLORS.DARKPRIMARY,
    width: "80px",
    cursor: "pointer",
  },
  upcommingLessonsCard: {
    borderBottom: "1px solid black",
    opacity: "0.75",
    cursor: "pointer",
    marginTop: "13px",
    userSelect: "none",
  },
  leftIcon: { height: "20px", width: "auto", marginTop: "17px" },
  upcommingLessonsRow: { marginTop: "-12px", marginBottom: "4px" },
  updatesCardDiv: {
    paddingBottom: "3px",
    marginTop: "20px",
    background: COLORS.SECONDARY,
  },
  myteachersCardDp: { width: "60px", height: "60px", borderRadius: "30px" },
  clubInput: {
    borderWidth: 0,
    backgroundColor: COLORS.LIGHTGREY,
    fontSize: 13,
    width: "99%",
  },
  cludInputCon: {
    backgroundColor: COLORS.LIGHTGREY,
    borderRadius: 10,
  },
  profileClubImage: {
    width: 160,
    height: 160,
    borderRadius: 90,
  },
  oneOnOne: {
    fontWeight: "400",
    fontSize: "25px",
  },
  clubRadius: {
    borderRadius: 7,
    paddingInline: 10,
    paddingBlock: 10,
  },
  postButton: {
    backgroundColor: COLORS.PRIMARY,
    paddingBlock: 5,
    marginTop: 10,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: 5,
    cursor: "pointer",
  },
  greyBorder: {
    border: "1px solid #D1D1D6",
  },
  userName: {
    fontSize: 16,
    fontWeight: 500,
  },
  timeAgo: {
    color: COLORS.DARKGREY,
    fontSize: 14,
  },
  seeAll: {
    color: COLORS.DARKPRIMARY,
    fontWeight: "900",
    fontSize: 14,
  },
  subHeading: {
    fontSize: 12,
  },
  teacherHeading: {
    fontSize: 30,
  },
  rating: {
    color: COLORS.TERTIARY,
    marginLeft: 5,
  },
  clubHeading: {
    fontWeight: "400",
    fontSize: "24px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  clubDiv: {
    display: "flex",
    height: "auto",
    width: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    overflow: "hidden",
    marginBottom: "20px",
    padding: "10px",
    cursor: "pointer",
  },
  clubUserIcon: { marginTop: "-15px", marginRight: "5px" },
  clubJoinButton: {
    border: "none",
    background: COLORS.PRIMARY,
    height: "32px",
    width: "85px",
    borderRadius: "8px",
  },
  clubJoinButtonIcon: { width: "35px", height: "35px" },
  clubCardHeading: {
    fontSize: "20px",
    height: "30px",
    width: "300px",
    fontWeight: "500",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tabItems: {
    borderBottom: "2px solid #F6F6F6",
    height: "auto",
  },
  teachersApprovalCard: {
    opacity: "0.75",
    marginTop: "-12px",
    marginBlock: "3px",
  },
  smallText: {
    fontSize: 11,
  },
  contactButton: {
    borderRadius: 5,
    marginTop: 10,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    paddingBlock: 10,
    border: "1px solid #BAECDF",
  },
  bookLesson: {
    backgroundColor: COLORS.DARKSECONDARY,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  lessonText: {
    color: COLORS.WHITE,
    fontSize: 13,
  },
  reviews: {
    fontWeight: "700",
    fontSize: 18,
  },
  reviewName: {
    fontSize: 12,
    color: COLORS.GREYDARK,
  },
  goBack: {
    border: "1px solid #F5A9AA",
    borderRadius: 5,
    display: "flex",
    alignitems: "center",
    justifyContent: "center",
    paddingBlock: 5,
    marginTop: 15,
  },
  labelTeacherDahboard: {
    fontSize: 28,
    fontWeight: "300",
  },
  labelSubTeacherDashboard: {
    color: COLORS.LABELGREY,
    fontWeight: "400",
    fontSize: 11,
  },
  gobackButton: {
    fontWeight: "700",
    color: COLORS.DARKPRIMARY,
    fontSize: "23px",
    cursor: "pointer",
  },
  bookLessonTitle: {
    fontWeight: "700",
    textTransform: "uppercase",
    fontSize: "23px",
    marginLeft: "15px",
  },
  iconDivider: {
    border: `2px solid ${COLORS.SECONDARY}`,
    width: "95px",
    height: "0px",
    marginTop: "40px",
  },
  iconText: {
    fontWeight: "600",
    fontSize: "12px",
    textAlign: "center",
    marginTop: "15px",
    marginInline: "auto",
  },
  iconText2: {
    fontWeight: "600",
    fontSize: "12px",
    textAlign: "center",
    marginTop: "15px",
    marginInline: "40px",
  },
  bookLessonUserProfileImage: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
  },
  bookLessonUserProfileTitle: { fontWeight: "600", fontSize: "20px" },
  bookLessonUserProfileSubtitle: {
    fontWeight: "300",
    fontSize: "14px",
    marginTop: "-18px",
  },
  bookLessonUserProfileStarsText: { fontSize: "12px", marginLeft: "7px" },
  bookLessonSubjectTitle: { fontWeight: "700", fontSize: "17px" },
  bookLessonSubjectDesc: { fontWeight: "400", fontSize: "15px" },
  subjectDropdownTitle: {
    fontWeight: "500",
    fontSize: "25px",
    width: "100%",
    color: COLORS.DARKPRIMARY,
  },
  subjectDropdownLabel: { fontWeight: "700", fontSize: "15px" },
  timeSlotDiv: {
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    padding: "10px",
    borderRadius: "8px",
    marginTop: "13px",
  },
  timeSlotText: {
    fontWeight: "400",
    fontSize: "15px",
    color: "#666666",
  },
  timeSlotTitle: {
    fontWeight: "500",
    fontSize: "16px",
    marginTop: "10px",
  },
  timeSlotMainDiv: {
    height: "370px",
    overflow: "scroll",
    marginBottom: "5px",
    padding: "5px",
    scrollBehavior: "smooth",
  },
  timeSlotEditButton: { textDecoration: "underline", cursor: "pointer" },
  packagesCardSubtitle: {
    color: "black",
    opacity: "0.5",
    textTransform: "uppercase",
    fontWeight: "400",
    fontSize: "17px",
    marginTop: "-13px",
  },
  packageCardDiv: {
    height: "350px",
    width: "90%",
    borderRadius: "16px 16px 8px 8px",
    overflow: "hidden",
    border: "1px solid #EFEFEF",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  packageCardHeadingDiv: {
    height: "30%",
    padding: "20px",
    fontWeight: "700",
    background: COLORS.PRIMARY,
    color: "#fff",
  },
  packageCardCurrencyText: { fontSize: "20px", marginTop: "-14px" },
  packageCardDescDiv: {
    height: "71%",
    padding: "10px",
  },
  packageCardDescSubDiv: {
    height: "175px",
    overflow: "hidden",
    marginTop: "10px",
  },
  packageCardDescText: {
    fontSize: "12px",
    fontWeight: "400",
    overflow: "hidden",
    marginTop: "-12px",
  },
  packageCardButtonDiv: {
    justifyContent: "center",
    display: "flex",
  },
  packageCardButton: {
    width: "80%",
    borderRadius: "8px",
    border: `1px solid ${COLORS.PRIMARY}`,
    background: "#F5A9AA",
    height: "auto",
    paddingBlock: "5px",
  },
  bookingSummaryHeading: {
    fontWeight: "700",
    textTransform: "uppercase",
    fontSize: "18px",
  },
  bookingSummarySubHeading: {
    fontWeight: "500",
    marginRight: "6px",
  },
  bookLessonSubmitButton: {
    width: "280px",
    border: `2px solid ${COLORS.DARKSECONDARY}`,
    borderRadius: "8px",
    background: "#fff",
    padding: "10px",
    marginInline: "7px",
    marginTop: "20px",
  },
  greyBold: {
    color: COLORS.BOLDGREY,
    fontWeight: "500",
    fontSize: 16,
  },
  teacherProfileDp: { width: "70px", height: "70px", borderRadius: "50%" },
  teacherProfileStars: { fontSize: "12px", marginLeft: "5px" },
  teacherProfileAverageTime: {
    color: "#9D9D9D",
    fontWeight: "500",
    fontSize: "15px",
  },
  teacherProfileAverageTimeDiv: { marginTop: "-15px", marginBottom: "30px" },
  teacherProfileSubjectListDiv: {
    width: "100%",
    height: "30px",
    borderBottom: "1px solid #C4C4C4",
    display: "flex",
    flexDirection: "row",
    overflowY: "hidden",
  },
  teacherProfileSubjectListHeader: {
    fontSize: "17px",
    height: "30px",
    cursor: "pointer",
    marginInline: "15px",
  },
  teacherProfileSubjectListItems: {
    height: "auto",
    marginBlock: "20px",
    justifyContent: "space-between",
    paddingInline: "15px",
  },
  teacherProfileSubjectListItemsHeading: {
    fontWeight: "500",
    fontSize: "18px",
    marginBlock: "auto",
  },
  teacherProfileSubjectListPrice: {
    opacity: "0.4",
    fontSize: "15px",
    marginBlock: "auto",
    marginRight: "20px",
  },
  teacherProfileSubjectListItemsButton: {
    background: COLORS.DARKSECONDARY,
    border: "none",
    height: "40px",
    paddingInline: "20px",
    borderRadius: "8px",
  },
  teacherProfileSubjectListItemsButtonTitle: {
    color: COLORS.WHITE,
    fontSize: "12px",
  },
  teacherProfileSubjectListContainer: { height: "230px", overflow: "scroll" },
  teacherProfileContactButton: {
    width: "98%",
    background: COLORS.WHITE,
    border: `1px solid ${COLORS.DARKSECONDARY}`,
  },

  aboutCardHeading: {
    fontWeight: "700",
    fontSize: "20px",
  },
  aboutCardDesc: {
    fontWeight: "400",
    fontSize: "18px",
    height: "auto",
    wordWrap: "break-word",
  },
  teacherProfileAboutCoverDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: "300px",
    borderRadius: "8px",
    width: "99%",
  },
  teacherProfileAboutCoverImage: {
    flexShrink: "0",
    minWidth: "101%",
    minHeight: "100%",
  },
  teacherProfileReviewHeading: { fontWeight: "700", fontSize: "22px" },
  teacherProfileReviewUsernameText: { fontWeight: "300", fontSize: "12px" },
  teacherProfileSubmitButton: {
    width: "280px",
    border: `2px solid ${COLORS.DARKSECONDARY}`,
    borderRadius: "8px",
    background: "#fff",
    padding: "10px",
    marginInline: "7px",
    marginTop: "20px",
  },
  paymentCard: {
    backgroundColor: COLORS.BLUEPURPLE,
    border: "1px solid #020246",
    borderRadius: 5,
  },
  paymentHeading: {
    color: COLORS.BLUEPURPLEDARK,
    fontSize: 13,
  },
  paymentMethod: {
    border: "1px solid #CED4DA",
    borderRadius: 5,
    height: "107px",
  },
  paymentDetail: {
    fontSize: 15,
    color: COLORS.DARKEST,
  },
  paymentTitle: {
    fontWeight: "500",
    color: COLORS.GRAY,
    fontSize: 15,
  },
  summary: {
    fontWeight: "700",
    fontSize: 20,
  },
  paymentLabel: {
    fontWeight: "600",
    fontSize: 16,
  },
  fontLarge: {
    fontSize: 18,
  },
  quote: {
    textAlign: "center",
    fontSize: 18,
    fontStyle: "italic",
  },
  customBorder: { border: "1px solid #CED4DA" },
  phoneInput: {
    border: "0px",
    flexWrap: "wrap",
    display: "flex",
    flexShrink: 1,
    width: "100%",
  },
  flags: { border: "0px", backgroundColor: COLORS.WHITE },
  statusHeading: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: 25,
    marginTop: 25,
  },
  statusDetail: {
    color: COLORS.LABELGREY,
    textAlign: "center",
    fontSize: 14,
  },
  statusDetails: {
    textAlign: "center",
  },
  statusDate: {
    color: COLORS.DARKPRIMARY,
    fontSize: 13,
  },
  teacherModalBook: {
    paddingTop: 25,
    paddingInline: 35,
    paddingBottom: 20,
  },
  teacherDashboardCardBook: {
    width: "50px",
    height: "50px",
    borderRadius: "50px",
  },
  cardHeadingBook: {
    fontSize: "23px",
    fontWeight: "500",
  },
  lessonHeading: {
    fontWeight: "700",
    fontSize: 28,
    marginTop: 24,
    color: COLORS.RALEBLUE,
  },
  lessonsDay: {
    fontWeight: "700",
    color: COLORS.DARKEST,
  },
  lessonsLable: {
    color: COLORS.DARKEST,
    fontSize: 14,
  },
  lessonsDesc: { color: COLORS.RALEBLUE, marginBottom: "20%" },
  lessonCard: { paddingTop: "30%", paddingBottom: "10%" },
  lessonCardHeading: {
    fontWeight: "600",
    fontSize: "25px",
    textAlign: "center",
    marginTop: "-20px",
    marginBottom: "20px",
  },
  feebackModalHeading: { fontWeight: "600", fontSize: "17px" },
  feedbackModalPic: { width: "100px", height: "100px", borderRadius: "50%" },
  feedbackModalCancelButton: {
    border: `1px solid ${COLORS.DARKSECONDARY}`,
    background: "transparent",
    width: "100%",
    height: "50px",
    borderRadius: "10px",
  },
  feedbackModalSubmitButton: {
    border: `1px solid ${COLORS.DARKSECONDARY}`,
    background: COLORS.DARKSECONDARY,
    width: "100%",
    height: "50px",
    borderRadius: "10px",
  },
  problemContact: {
    background: COLORS.WHITE,
    border: `1px solid ${COLORS.DARKSECONDARY}`,
  },
  classLoader: { width: "100%", height: "60px" },
  classPlaceholder: {
    height: "100%",
    width: "100%",
    background: COLORS.GREY,
  },
  disputeHeading: {
    color: COLORS.RALEBLUE,
    fontSize: 26,
  },
  disputeType: {
    color: COLORS.RALEBLUE,
    fontWeight: "600",
    fontSize: 18,
    marginTop: 35,
  },
  disputesAttachemnts: {
    border: `1px solid ${COLORS.DARKPRIMARY}`,
    borderRadius: 7,
    width: 150,
    paddingBlock: 10,
    cursor: "pointer",
  },
  attachmentText: {
    color: COLORS.DARKPRIMARY,
    fontWeight: "500",
  },
  disputeContact: {
    border: `1px solid ${COLORS.DARKSECONDARY}`,
    backgroundColor: COLORS.WHITE,
    borderRadius: 7,
  },
  cancelText: {
    color: COLORS.DARKSECONDARY,
    fontWeight: "500",
  },
  lightColor: {
    fontSize: 15,
    color: COLORS.DARKGRAY,
  },
  disputeChat: {
    fontWeight: "400",
    fontSize: 16,
  },
  message: {
    backgroundColor: COLORS.CHAT,
    marginTop: 8,
    paddingInline: 10,
    borderRadius: 5,
  },
  messageText: {
    fontSize: 14,
    marginBlock: 10,
  },
  messageInput: {
    border: `1px solid ${COLORS.CHATGREY}`,
    paddingInline: 15,
    paddingBlock: 5,
    borderRadius: 15,
    marginTop: 130,
  },
  chatInput: {
    borderWidth: 0,
  },
  sendDispute: {
    width: "20px",
    height: "20px",
  },
  imagesDispute: {
    width: "60px",
    height: "60px",
  },
  communityTabText: {
    cursor: "pointer",
    paddingBottom: "5px",
    marginBottom: "-2px",
    marginRight: "2%",
  },
  communitySearch: { backgroundColor: COLORS.LIGHTGREY, border: 0 },
  communityFeature: {
    backgroundColor: COLORS.DARKPRIMARY,
    color: COLORS.WHITE,
    fontWeight: "600",
    fontSize: 10,
    paddingBlock: 5,
    paddingInline: 7,
    marginRight: 5,
  },
  communityAdmin: {
    color: COLORS.DARKPRIMARY,
    fontSize: 12,
    fontWeight: "600",
  },
  communityWeek: {
    fontWeight: "200",
    color: COLORS.SOURCEGREY,
    fontSize: 10,
  },
  communityCircle: {
    color: COLORS.PRIMARY,
    fontSize: 7,
  },
  checkboxDiv: { width: "110px", cursor: "pointer" },
  seeAllBorder: {
    borderBottom: `1px solid ${COLORS.GREY}`,
    paddingBottom: 7,
  },
  countrySelectorDiv: {
    height: "44px",
    borderRadius: "5px",
  },
  teachersDashboardCalender: {
    marginBottom: "20px",
    padding: "20px",
    height: "650px",
  },
  clubDescription: {
    height: "50px",
    width: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "500",
    fontSize: "15px",
    opacity: "0.5",
  },
  subjectCheckBoxText: { fontSize: "15px", marginLeft: "10px" },
  bookLessonSubjectListDiv: {
    height: "28px",
    borderRadius: 0,
    borderBottom: `1px solid ${COLORS.GREY}`,
    overflowX: "scroll",
    overflowY: "hidden",
    width: "100%",
  },
  bookLessonSubjectLessonTitle: {
    fontWeight: "400",
    fontSize: "16px",
    width: "160px",
    height: "27px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  bookLessonSubjectLessonDiv: {
    overflow: "scroll",
    borderRadius: 0,
  },
  bookLessonSubjectTextDiv: {
    width: "auto",
    height: "25px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  timeSlotBox: {
    height: "180px",
    overflow: "scroll",
    paddingInline: "20px",
    paddingBlock: "10px",
  },
  subjectLessonText: { fontSize: "18px", fontWeight: "500" },
  faqsHeading: {
    fontSize: 35,
    color: COLORS.DARKPRIMARY,
    fontWeight: "600",
  },
  reviewText: {
    overflow: "hidden",
    width: "100%",
    wordBreak: "break-all",
  },
  shareButton: {
    marginInline: "10px",
    cursor: "pointer",
    height: "25px",
  },
  homeVideoDiv: {
    maxWidth: "700px",
    maxHeight: "auto",
    padding: "10px",
    background: COLORS.GREY,
    borderRadius: "15px",
  },
  notificationIcon: { width: "25px", height: "auto" },
  notificationDot: {
    height: "8px",
    width: "8px",
    borderRadius: "50%",
    marginTop: "7px",
  },
  notificationTimeAgo: {
    fontSize: "9px",
    opacity: "0.6",
    marginTop: "-4px",
    marginBottom: "-2px",
    padding: 0,
  },
  notificationMenuDiv: {
    marginLeft: "-100px",
    marginTop: "15px",
  },
  messageTime: {
    marginTop: "-25px",
    opacity: "0.5",
    fontSize: "11px",
    marginInline: "10px",
  },
  pendingHeading: {
    textAlign: "center",
    fontWeight: 600,
    fontSize: 20,
  },
  instantClassCreatedTime: { marginTop: "-13px", fontSize: "19px" },
  instantClassCreatedHeading: {
    textTransform: "uppercase",
    fontSize: "10px",
    fontWeight: "bold",
    marginTop: "5px",
  },
  teacherSettingTextarea: {
    height: "100px",
    fontSize: "15px",
    fontWeight: 400,
    width: "100%",
    border: "0.5px solid grey",
    borderRadius: "5px",
    paddingInline: "10px",
    padding: "5px",
  },
};
