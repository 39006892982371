import axios from "axios";

export const getCommentsApi = ({ userId, postId, page, limit, clubId }) => {
  return axios.get(
    `/api/users/${userId}/community/${clubId}/post/${postId}/comments?page=${page}&limit=${limit}`
  );
};

export const addCommentApi = ({ userId, postId, comment, clubId }) => {
  return axios.post(
    `/api/users/${userId}/community/${clubId}/post/${postId}/comments`,
    { comment }
  );
};

export const getPublicCommentsApi = ({ postId, clubId, page, limit }) => {
  return axios.get(
    `/api/publicCommunity/${clubId}/post/${postId}/comments?page=${page}&limit=${limit}`
  );
};
