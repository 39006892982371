//SVG
import Communication from "../assets/languages1.png";
import Art from "../assets/art.png";
import Bible from "../assets/religion.png";
import Geography from "../assets/socials.png";
import Maths from "../assets/math2.png";
import Microscope from "../assets/math.png";
// import Carousel1 from "../assets/backgroundImages/Carousel1.webp";
// import Carousel2 from "../assets/backgroundImages/Carousel2.webp";
import ProfessionalTeacherImage from "../assets/professionalTeacher.svg";
import CommunityTeacherImage from "../assets/communityTeacher.svg";
import moment from "moment";
export const USERS_LANGUAGES = [
  "English",
  "Français",
  "Deutsch",
  "中国人",
  "عربي",
];
export const LANGUAGES = [
  {
    lang: "English",
    code: "en",
  },
  { lang: "عربي", code: "ar" },
];
export const CURRENCIES = ["USD $", "CAD $", "Yuan ¥", "Riyal", "Euro €"];

export const passwordRegEx = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})"
);

export const NAME_REGEX = new RegExp("^[A-Za-z\\s]*$");

export const URL_REGEX = new RegExp(
  "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})"
);

export const DATE_FORMAT_ISO = "YYYY-MM-DD";

export const SUBJECTCATEGORIES = [
  { name: "Art", icon: Art, backgroundColor: "#FF686B" },
  { name: "Languages", icon: Communication, backgroundColor: "#E83858" },
  { name: "Math", icon: Maths, backgroundColor: "#4D636E" },
  { name: "Religion", icon: Bible, backgroundColor: "#5DBCA4" },
  { name: "Social Studies", icon: Geography, backgroundColor: "#8D6C9F" },
  { name: "Science", icon: Microscope, backgroundColor: "#F6C644" },
];

export const COLORS = {
  PRIMARY: "#84DCC6",
  DARKPRIMARY: "#5D9C8C",
  LIGHTPRIMARY: "#A5FFD6",
  BLACK: "#333333",
  WHITE: "#fff",
  TERTIARY: "#F6C644",
  DARKTERTIARY: "#EEB13D",
  LIGHTTERTIARY: "#FBE9C1",
  SECONDARY: "#FCCECA",
  DARKSECONDARY: "#FF686B",
  GREY: "#EDEDED",
  DARKGREY: "#D1D1D6",
  DARKTHEME: "#1DC9A0",
  RED: "#FF0000",
  GREYWHITE: "#F9F9F9",
  PINKPRIMARY: "#F5A9AA",
  LIGHTGREY: "#F6F6F6",
  PINK: "#FFA69E8C",
  BOLDGREY: "#9D9D9D",
  GREYDARK: "#BCBCBC",
  BLUEPURPLE: "#EFF0F7",
  BLUEPURPLEDARK: "#020246",
  DARKEST: "#667085",
  GRAY: "#344054",
  LABELGREY: "#858585",
  YELLOW: "#FFC403",
  LIGHTEYELLOW: "#FAE0A7B2",
  LIGHTPURPLE: "#debbe8",
  RALEBLUE: "#070C42E5",
  DARKPINK: "#FF7071",
  DARKTHEMEPRIMARY: "#41BE9E",
  PINKPURPLE: "#F2D0FE",
  CARROT: "#FFA69ECC",
  LIGHTPRIMARYGREEN: "#84DCC699",
  DARKPRIMARYGREEN: "#41BE9E",
  DARKGRAY: "#333333",
  DARKCARROT: "#FFA69E",
  CHAT: "#F5F5F5",
  CHATGREY: "#E8E8E8",
  COMMUNITYGREY: "#5A5A5A",
  SOURCEGREY: "#666666",
  SOLIDGREY: "#E0E0E0",
  LIGHTBERMUDA: "#1DBF73",
  TRANSPARENT: "transparent",
  RATINGSTARSCOLOR: "#FFC403",
};

export const CAROUSELNAVBUTTON = [
  "<i class='fa fa-chevron-left' style='color:#5D9C8C;font-size:30px;margin-left:-15rem;'></i>",
  "<i class='fa fa-chevron-right' style='color:#5D9C8C;font-size:30px;margin-right:-15rem;'></i>",
];

// export const CAROUSELDATA = [
//   {
//     date: "May 8,2020",
//     heading: "Lorem ipsum dolor sit amet",
//     bodyText:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. ",
//     carouselPic: Carousel2,
//     rating: 2.5,
//   },
//   {
//     date: "May 8,2020",
//     heading: "Lorem ipsum dolor sit amet",
//     bodyText:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. ",
//     carouselPic: Carousel1,
//     rating: 3.5,
//   },
//   {
//     date: "May 8,2020",
//     heading: "Lorem ipsum dolor sit amet",
//     bodyText:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. ",
//     carouselPic: Carousel2,
//     rating: 5,
//   },
//   {
//     date: "May 8,2020",
//     heading: "Lorem ipsum dolor sit amet",
//     bodyText:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. ",
//     carouselPic: Carousel1,
//     rating: 2,
//   },
// ];

export const becomeTeacherCardData = [
  {
    heading: "Teacher Type",
    headerText: "What type of a teacher are you?",
    bodyText:
      "On Rise you can become either a Professional teacher or a Community Tutor",
    bgColor: COLORS.SECONDARY,
  },
  {
    heading: "Subject",
    headerText: "Is your Subject Area available at Rise?",
    bodyText:
      "Before you apply check to see if your Subject Area is currently available. Click on the name of the Subject Area below to learn more about the sub-areas currently on offer. You may also put in a request for a sub-area currently not on offer.",
    bgColor: COLORS.DARKSECONDARY,
  },
  {
    heading: "Video Introduction",
    headerText: "Prepare a video introduction",
    bodyText:
      "Rise requires that you have a video introduction. This video introduction is how you can show off your teaching style and teaching personality to potential students. Example video",
    bgColor: COLORS.TERTIARY,
  },
  {
    heading: "Professional Certification",
    headerText: "Teaching certifications",
    bodyText:
      "Rise requires only Professional Teachers to upload their Teaching certificates. Your diplomas and certificates are not published.",
    bgColor: COLORS.PRIMARY,
  },
];

const fontWeight = {
  fontWeight: 500,
};

export const TEACHER_TYPE = {
  professionalTeacher: "Professional Teacher",
  communityTutor: "Community Tutor",
};

export const TEACHERTYPECARDDATA = [
  {
    heading: TEACHER_TYPE.professionalTeacher,
    backgroundText: (
      <>
        Certified with a teaching degree in the{" "}
        <strong style={fontWeight}>subject area</strong> from an accredited
        institution (private or public).Proof of teahing experience in the{" "}
        <strong style={fontWeight}>subject area</strong>.
      </>
    ),
    credentialsText:
      "Submit copies of teaching accreditation from a university or accredited institution.",
    introText: "Brief (1-3 min) video introduction",
    payText: "Set your own rate",
    logo: ProfessionalTeacherImage,
  },
  {
    heading: TEACHER_TYPE.communityTutor,
    backgroundText: (
      <>
        Proof of coursework or training in teaching the{" "}
        <strong style={fontWeight}>subject area volunteer</strong> teaching in
        the <strong style={fontWeight}>subject area</strong>.
      </>
    ),
    credentialsText: "None required",
    introText: "Brief (1-3 min) video introduction",
    payText: "Set your own rate",
    logo: CommunityTeacherImage,
  },
];

export const MONTHS = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const LANGUAGELEVEL = [
  "Beginner",
  "Intermediate",
  "Advance",
  "Native Speaker",
];

export const DAYS = () => {
  return Array(31 - 1 + 1)
    .fill()
    .map((_, idx) => 1 + idx);
};

export const YEARS = () => {
  let currentYear = moment().year();
  let startYear = currentYear - 70;
  return Array(currentYear - startYear + 1)
    .fill()
    .map((_, idx) => startYear + idx)
    .reverse();
};
export const YEARS_FOR_BIRTHDAY = () => {
  let currentYear = moment().year();
  let startYear = currentYear - 70;
  return Array(currentYear - startYear - 10)
    .fill()
    .map((_, idx) => startYear + idx)
    .reverse();
};
export const FUTURE_YEARS = () => {
  let currentYear = moment().year();
  let startYear = currentYear + 1;
  return Array(70)
    .fill()
    .map((_, idx) => startYear + idx);
};
export const TEACHINGSUBJECTS = [
  "Art",
  "Religion",
  "Languages",
  "Science",
  "Math",
  "Social Studies",
];

export const DEGREE_LEVEL = ["MS", "BS", "Others"];
export const STUDENT_LEVELS = [
  "Elementary School",
  "Middle School",
  "High School",
  "Adult",
];
export const DURATION = ["/30 min", "/60 min", "/120 min"];

export const LESSONS = ["5", "10", "15"];
export const GENDER = ["Male", "Female", "Others", "Prefer not to say"];

export const STATUS = {
  pending: "Pending",
  accepted: "Accepted",
  rejected: "Rejected",
  waiting: "Waiting",
  cancelled: "Cancelled",
};
export const LESSONSTATUS = {
  all: "all",
  upcomming: "upcomming",
  cancel: "cancelled",
  WaitingTeachersApproval: "Waiting Teacher’s Approval",
  NeedsAnAction: "Needs an Action",
  LessonHistory: "Lesson History",
  instantLessons: "Instant Lesson",
};
export const LESSONSTATUSHIDDEN = {
  completed: "Completed",
  cancelled: "cancelled",
  pending: "Pending",
  accepted: "Accepted",
  rejected: "Rejected",
  scheduled: "Scheduled",
};

export const USER_ROLES = {
  student: "Student",
  teacher: "Teacher",
};

export const TIME_FORMAT = "hh:mm A";
export const DATE_FORMAT = "YYYY/MM/DD";

export const COMMUNITIES = {
  getConnected: "Get Connected",
};

export const SUBJECT_DROPDOWN_MENU = [
  {
    subject: "Art",
    items: ["Music", "Painting"],
  },
  {
    subject: "Languages",
    items: [
      "Arabic",
      "Chinese",
      "English",
      "French",
      "German",
      "Italian",
      "Spanish",
      "Urdu",
    ],
  },
  { subject: "Math", items: ["Algebra", "Calculus", "Geometry"] },
  { subject: "Religion", items: ["Islamic Studies", "Quran", "Tajweed"] },
  { subject: "Social Studies", items: ["History", "Psychology"] },
  { subject: "Science", items: ["Biology", "Chemistry", "Physics"] },
  { subject: "Others" },
];

export const PAYMENT_STATUS = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
};

export const DATE_FORMAT_AVAILABILITY = "ddd MMM Do";

export const TEACHERS_LESSON_TABS = {
  all: "all",
  upcomming: "upcomming",
  cancelled: "cancelled",
  needsApproval: "needs your approval",
  needsaction: "needs an action",
  lessonhistory: "lesson history",
  instantLessons: "Instant Lessons",
};

export const TEACHERS_LESSON_STATUS = {
  waiting: "Waiting",
  scheduled: "Scheduled",
  cancelled: "Cancelled",
  rejected: "Rejected",
  completed: "Completed",
  pending: "Pending",
};

export const MEETINGS_SITES = {
  zoom: "Zoom",
  googlemeet: "Google Meeting",
};

export const LESSONS_STATUS = {
  pending: "Pending",
  accepted: "Accepted",
  current: "Current",
  teacherAccepted: "Teacher_Accepted",
  teacherPending: "Teacher_Pending",
  teacherSubject: "Teacher_Subject",
};

export const IMAGES_TYPES = ["image/jpg", "image/jpeg", "image/png"];

export const DOCS_TYPES = [
  "application/pdf",
  "application/doc",
  "application/docx",
  "application/odt",
  "application/msword",
];

export const IMAGES_DOCS_TYPES = ["image/jpg", "image/jpeg", "image/png"];

export const FILE_ACCEPT_TYPES = {
  images: "image/*",
  videos: "video/*",
};

export const dummy_subjects = ["USD $", "CAD $", "Yuan ¥", "Riyal", "Euro €"];

export const FILE_SIZE_LIMIT = 30000000;
export const VIDEO_TYPES = ["video/mp4"];

export const TIME_FORMAT_UNIX = "LLLL";

export const BASE_URL = "http://localhost:3000/";
export const RISE_INFO_EMAIL = "info@riseforlearning.com";

export const VIDEO_TIPS =
  "https://rise-for-learning-assets.s3.us-east-2.amazonaws.com/rise_assets/intro_video.mp4";

export const TUTORIAL_VIDEOS = [
  "https://rise-for-learning-assets.s3.us-east-2.amazonaws.com/rise_assets/create_lesson.m4v",
  "https://rise-for-learning-assets.s3.us-east-2.amazonaws.com/rise_assets/during_class.m4v",
  "https://rise-for-learning-assets.s3.us-east-2.amazonaws.com/rise_assets/after_class.m4v",
  "https://rise-for-learning-assets.s3.us-east-2.amazonaws.com/rise_assets/keep_rising.m4v",
];

export const IMAGE_COMPRESSOR_OPTIONS = {
  maxSizeMB: 1,
  maxWidthOrHeight: 512,
};

export const HOME_BACKGROUND_IMAGES = [
  "https://rise-for-learning-assets.s3.us-east-2.amazonaws.com/rise_assets/bg1.webp",
  "https://rise-for-learning-assets.s3.us-east-2.amazonaws.com/rise_assets/bg2.webp",
];

export const PAYMENT_METHODS = {
  card: "card",
  package: "package",
  wallet: "wallet",
};

export const W9_FORM_LINK = "https://www.irs.gov/pub/irs-pdf/fw9.pdf";

export const FEDERAL_TAX_CLASSIFICATION = {
  individual: "Individual/sole proprietor or single-member LLC",
  corporation: "C Corporation",
  scorporation: "S Corporation",
  partnership: "Partnership",
  trust: "Trust/estate",
  limited: "Limited liability company",
  other: "Other",
};

export const FEDERAL_TAX_CLASSIFICATION_LIMITED = {
  c: "C",
  s: "S",
  p: "P",
};

export const W9_FORM_COUNTRY = "United States";

export const HOME_PAGE_VIDEO =
  "https://rise-for-learning-assets.s3.us-east-2.amazonaws.com/rise_assets/%D9%8FTeacher+Application.mp4";

export const UPDATE_TYPES = {
  teacherUpdates: "TEACHER_UPDATES",
  studentUpdates: "STUDENT_UPDATES",
};

export const PAYPAL_FEE_PERCENTAGE = 3.98;
export const PAYPAL_FIXED_FEE_USD = 0.49;

export const PAYMENT_WITH = {
  paypal: "Paypal",
  wallet: "Wallet",
};

export const NOTIFICATION_TYPE = {
  classes: "CLASSES",
  dispute: "DISPUTES",
};

export const BECOME_TEACHER_ATTACHMENT_TYPE = {
  idCard: "Id Card",
  drivingLicence: "Driving Licence",
  passport: "Passport",
};
