import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCommentsApi,
  addCommentApi,
  getPublicCommentsApi,
} from "../../services/api/apiServices/comments";

export const getComments = createAsyncThunk(
  "GET_COMMENTS",
  async ({ userId, postId, page, limit, clubId }) => {
    try {
      const { data } = await getCommentsApi({
        userId,
        postId,
        page,
        limit,
        clubId,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const addComment = createAsyncThunk(
  "ADD_COMMENTS",
  async ({ userId, postId, comment, clubId }) => {
    try {
      const { data } = await addCommentApi({
        userId,
        postId,
        comment,
        clubId,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getPublicComments = createAsyncThunk(
  "GET_PUBLIC_COMMENTS",
  async ({ postId, clubId, page, limit }) => {
    try {
      const { data } = await getPublicCommentsApi({
        postId,
        clubId,
        page,
        limit,
      });
      return data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  comments: null,
  addComment: {
    loading: false,
    error: null,
    response: null,
  },
};

export const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    clearResponse: (state) => {
      state.addComment.response = null;
    },
  },
  extraReducers: {
    [getComments.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getComments.fulfilled]: (state, { payload }) => {
      state.comments = payload;
      state.loading = false;
      state.error = null;
    },
    [getComments.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    // get public comments
    [getPublicComments.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPublicComments.fulfilled]: (state, { payload }) => {
      state.comments = payload;
      state.loading = false;
      state.error = null;
    },
    [getPublicComments.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    //Add Comments
    [addComment.pending]: (state) => {
      state.addComment.loading = true;
      state.addComment.error = null;
    },
    [addComment.fulfilled]: (state, { payload }) => {
      state.addComment.response = payload;
      state.addComment.loading = false;
      state.addComment.error = null;
    },
    [addComment.rejected]: (state, { error }) => {
      state.addComment.loading = false;
      state.addComment.error = error.message;
    },
  },
});

export const { clearResponse } = commentsSlice.actions;

export const selectComments = (state) => state.comments;
export const selectAddComment = (state) => state.comments.addComment;

export default commentsSlice.reducer;
